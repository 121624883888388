const pathMaintenancePage = "/maintenance/index.html";

/**
 * CloudFrontでファイルがあるときに返すX-Cacheヘッダーの値
 */
const valuesMaintenanceModeXCache: string[] = [
  "Miss from cloudfront",
  "Hit from cloudfront",
  "RefreshHit from cloudfront",
];
const keyHeader = "X-Cache";
const checkIntervalMinutes = 5;

/**
 * メンテナンス中か判定するコード
 */
export async function checkMaintenanceMode() {
  try {
    const resp = await fetch(pathMaintenancePage);

    // 現状のCMSではnot foundでも200を返す様になっているのでHeaderで成否を判定
    const value = resp.headers.get(keyHeader);
    if (value != null && valuesMaintenanceModeXCache.includes(value)) {
      alert(
        "現在システムメンテナンス中のため使用できません。\n" +
          "メンテナンス終了後に改めてアクセスしてください。",
      );
      location.href = pathMaintenancePage;
    }
  } catch (e) {
    // エラーが起きたことはconsoleに出力しつつ、エラーを握りつぶす
    console.error("failed to check maintenance mode", e);
  }
}

/**
 * 一定時間ごとにメンテナンス中か判定する処理を設定する
 */
export function setAutoCheckMaintenanceMode() {
  setInterval(
    () => {
      checkMaintenanceMode();
    },
    checkIntervalMinutes * 60 * 1000,
  );
}
