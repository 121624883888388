<template>
  <v-container>
    <alert-snack-bar
      v-model="state.popSnackBar"
      :type="state.snackBarType"
      :title="state.snackBarTitle"
      :message="state.snackBarMessage"
    />
    <v-row>
      <v-col xl="12" cols="12">
        <!-- eslint-disable-next-line vuejs-accessibility/heading-has-content -->
        <h3 class="my-3">
          <date-selector
            :date="state.day"
            date-text="top.operationTasks"
            current-name="今日"
            @event-date-change="eventDateChange"
          />
        </h3>
        <v-sheet color="white" elevation="1">
          <v-data-table
            density="comfortable"
            :headers="headersDay"
            hover
            :items="state.tasksDay"
            :loading="state.loading ? 'primary' : false"
            :items-per-page="-1"
            item-value="id"
            no-data-text="作業はありません。"
            class="task-list maint-item-row"
          >
            <template #[`header.done`]="{ column }">
              {{ column.title }}
              <v-tooltip v-if="!state.isViewer" location="top">
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="completed-annotation"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                <p style="margin-bottom: 0">
                  本日以降および昨日の作業のみ完了が可能です。
                </p>
                <p style="margin-bottom: 0">
                  作業完了日の24時まではキャンセルが可能です。
                </p>
              </v-tooltip>
            </template>
            <template #[`item.done`]="{ item }">
              <template v-if="state.isViewer">
                <v-icon v-if="item.done" color="primary">mdi-check</v-icon>
              </template>
              <template v-else>
                <v-checkbox
                  v-model="item.done"
                  base-color="primary"
                  color="primary"
                  :disabled="isCompleteCheckboxDisabled(item)"
                  class="d-flex justify-center is-completed"
                  @update:model-value="changeCheckbox(item)"
                />
              </template>
            </template>
            <template #[`item.name`]="{ item }">
              <td class="maint-item-cell" @click="openDetailModal(item)">
                <span>
                  <v-icon v-if="item.is_event"> mdi-calendar </v-icon>
                  {{ item.name }}
                </span>
              </td>
            </template>
            <template #bottom></template>
          </v-data-table>
        </v-sheet>
      </v-col>
      <v-col xl="12" cols="12">
        <h3 class="my-3">
          {{
            t("top.weeklyTasks", {
              from: $dayjs()
                .startOf("week")
                .format(t("common.dateformat.date")),
              to: $dayjs().endOf("week").format(t("common.dateformat.date")),
            })
          }}
        </h3>
        <v-sheet color="white" elevation="1">
          <v-data-table
            density="comfortable"
            :headers="headersWeek"
            hover
            item-value="id"
            :items="state.tasksWeek"
            :items-per-page="-1"
            :loading="state.loading ? 'primary' : false"
            no-data-text="作業はありません。"
            class="task-list"
          >
            <template #[`item.status`]="{ item }">
              <v-chip
                :color="statusDict[item.status].color"
                variant="flat"
                :class="statusDict[item.status].textColor"
              >
                <span>{{ statusDict[item.status].text }}</span>
              </v-chip>
            </template>
            <template #[`item.taskScheduledDate`]="{ item }">
              {{ convertToDisplayDate(item.taskScheduledDate) }}
            </template>
            <template #[`item.name`]="{ item }">
              <v-icon v-if="item.is_event"> mdi-calendar </v-icon>
              {{ item.name }}
            </template>
            <template #bottom></template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <event-detail
      :event-detail-open-status="state.eventDetailOpenStatus"
      :maint-event="state.selectedMaintEvent"
      @open-status-update-request="updateEventDetailStatusHandler"
      @snack-bar="snackBarHandler"
      @need-refresh="refreshHandler"
    />
    <overlay-loading :is-loading="state.showOverlayLoading" />
    <maint-item-detail
      :maint-item-detail-open-status="state.maintItemDetailOpenStatus"
      :maint-item="state.selectedMaintItem"
      :mhi-parts-search-result="state.mhiPartsSearchResult"
      @open-status-update-request="updateMaintItemDetailStatusHandler"
      @snack-bar="snackBarHandler"
      @need-refresh="refreshHandler"
    />
  </v-container>
</template>

<script setup lang="ts">
import { reactive, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import dayjs, { Dayjs } from "dayjs";
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import MaintItemDetail from "@/components/MaintItemDetail.vue";
import EventDetail from "@/components/EventDetail.vue";
import DateSelector from "@/components/DateSelector.vue";
import OverlayLoading from "@/components/OverlayLoading.vue";
import { MaintTask, MaintTaskData } from "@/models/MaintTask";
import { connectToApi } from "@/helpers/connectToApi";
import { MaintEvent, MaintEventData } from "@/models/MaintEvent";
import { MaintItem } from "@/models/MaintItem";
import { requestGetMaintItem } from "@/helpers/api/getMaintItemDetail";
import { requestHinmoku } from "@/helpers/api/hojyuHinmoku";
import { MhiPartsSearchResult } from "@/models/mhiPartsSearchResult";
import { useAuthoritiesStore } from "@/stores/authorities";
import { AlertSnackBarModel } from "@/models/snackbar";

type State = {
  day: Dayjs;
  tasksDay: MaintTask[];
  tasksWeek: MaintTask[];
  maintItemDetailOpenStatus: boolean;
  eventDetailOpenStatus: boolean;
  loading: boolean;
  popSnackBar: boolean;
  snackBarType: string;
  snackBarTitle: string;
  snackBarMessage: string;
  isViewer: boolean;
  taskListDay: [];
  selectedMaintItem: MaintItem;
  mhiPartsSearchResult: MhiPartsSearchResult;
  selectedMaintEvent: MaintEvent;
  showOverlayLoading: boolean;
};

type ApiResponse = {
  tasksDay: MaintTaskData[];
  tasksWeek: MaintTaskData[];
  eventsDay: MaintEventData[];
};

type ApiDetailResponse = {
  maint_event: MaintEventData;
};

type ReportTaskCompletionApiResponse = {
  maint_task_id: number;
  completed_date: string;
};

type CancelTaskCompletionApiResponse = {
  maint_task_id: number;
};

type ReportEventCompletionApiResponse = {
  maint_event_id: number;
  completed_date: string;
};

type CancelEventCompletionApiResponse = {
  maint_event_id: number;
};

const convertYyyyMmDd = (val: Dayjs): string => {
  const yyyy = val.year().toString().padStart(4, "0");
  const mm = (val.month() + 1).toString().padStart(2, "0");
  const dd = val.date().toString().padStart(2, "0");
  return yyyy + mm + dd;
};

type Props = {
  yyyymmdd?: string;
};

const prop = withDefaults(defineProps<Props>(), {
  yyyymmdd: undefined,
});

const storeAuthorities = useAuthoritiesStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const requestData = async (yyyymmdd: string): Promise<ApiResponse> => {
  const response = await connectToApi<ApiResponse>({
    method: "GET",
    url: "/api/getMaintTaskList",
    params: {
      yyyymmdd: yyyymmdd,
      plant_id: storeAuthorities.selectedAuthority.plantId,
    },
  }).catch((e) => {
    state.snackBarType = "error";
    state.snackBarTitle = "データの更新に失敗しました。";
    state.snackBarMessage =
      e.response !== undefined
        ? e.response.data.message
        : "データの更新に関する不具合が発生しています。";
    state.popSnackBar = true;
    throw e;
  });
  return response.data;
};
const requestEventDetail = async (
  maintEventId: number,
): Promise<ApiDetailResponse> => {
  const resp = await connectToApi<ApiDetailResponse>({
    url: "/api/getMaintEvent",
    method: "GET",
    params: {
      maint_event_id: maintEventId,
    },
  });
  return resp.data;
};
const state = reactive<State>({
  day: dayjs(prop.yyyymmdd),
  tasksDay: [],
  tasksWeek: [],
  maintItemDetailOpenStatus: false,
  eventDetailOpenStatus: false,
  loading: true,
  popSnackBar: false,
  snackBarType: "error",
  snackBarTitle: "データの更新に失敗しました。",
  snackBarMessage: "データの更新に関する不具合が発生しています。",
  isViewer: true,
  taskListDay: [],
  selectedMaintEvent: MaintEvent.dummy(),
  mhiPartsSearchResult: MhiPartsSearchResult.empty(),
  showOverlayLoading: false,
  selectedMaintItem: MaintItem.dummy(),
});
const statusDict: { [name: string]: { [name: string]: string } } = {
  waiting: {
    text: "未着手",
    color: "grey-lighten-2",
    textColor: "text-black",
  },
  working: {
    text: "処理中",
    color: "orange-lighten-4",
    textColor: "text-orange-darken-4",
  },
  completed: {
    text: "完了",
    color: "green-lighten-4",
    textColor: "text-green-darken-4",
  },
  late: {
    text: "遅延",
    color: "red-lighten-4",
    textColor: "text-red-darken-4",
  },
};

//作業指示書のヘッダ
const headersDay = [
  {
    title: t("common.maintTask.name"),
    key: "name",
    width: "42%",
  },
  {
    title: t("common.maintTask.set"),
    key: "set",
    width: "11%",
  },
  {
    title: t("common.maintTask.subject1"),
    key: "subject1",
    width: "11%",
  },
  {
    title: t("common.maintTask.subject2"),
    key: "subject2",
    width: "11%",
  },
  {
    title: t("common.maintTask.subject3"),
    key: "subject3",
    width: "11%",
  },
  {
    title: t("common.maintTask.done"),
    key: "done",
    sortable: false,
    align: "center",
    annotation: t("common.maintTask.doneAnnotation"),
  },
] as const;

//今週の整備状況のヘッダ
const headersWeek = [
  {
    title: t("common.maintTask.status"),
    key: "status",
    align: "center",
    width: "14%",
    sort: MaintTask.compareStatus,
  },
  {
    title: t("common.maintTask.name"),
    key: "name",
    width: "28%",
  },
  {
    title: t("common.maintTask.set"),
    key: "set",
    width: "11%",
  },
  {
    title: t("common.maintTask.subject1"),
    key: "subject1",
    width: "11%",
  },
  {
    title: t("common.maintTask.subject2"),
    key: "subject2",
    width: "11%",
  },
  {
    title: t("common.maintTask.subject3"),
    key: "subject3",
    width: "11%",
  },
  {
    title: t("common.maintTask.taskScheduledDate"),
    key: "taskScheduledDate",
  },
] as const;

const eventDateChange = (val: number | undefined) => {
  if (val) {
    state.day = state.day.add(val, "day");
  } else {
    state.day = dayjs();
  }
};

/**
 * 表示する日を変更したときにURLを更新させる
 */
const updateUrlDate = (val: Dayjs) => {
  const yyyymmdd = convertYyyyMmDd(val);
  router.push({
    path: `/${yyyymmdd}`,
  });
};

/**
 * APIからデータを取得する
 */
const getMaintTaskListData = async (date: Dayjs) => {
  state.loading = true;
  state.tasksWeek = [];
  state.tasksDay = [];
  const yyyymmdd = convertYyyyMmDd(date);
  const resp = await requestData(yyyymmdd);
  state.tasksDay = resp.tasksDay.map((item) => new MaintTask(item));
  state.tasksWeek = resp.tasksWeek.map((item) => new MaintTask(item));
  state.loading = false;
};

/**
 * 各詳細モーダル（整備項目詳細・イベント詳細）を開く
 */
const openDetailModal = async (item: MaintTask) => {
  if (!item.is_event) {
    await openMaintItemDetail(item);
  } else if (item.is_event) {
    await openEventDetail(item);
  } else {
    state.maintItemDetailOpenStatus = false;
  }
};

/**
 * 整備項目詳細モーダルを開く
 */
const openMaintItemDetail = async (task: MaintTask) => {
  try {
    // イベントでなければ整備作業に紐づくmaint_item_idは必ず存在するのだが、警告を非表示にするためnullチェックする
    if (task.maint_item_id == null) {
      console.error("Unexpected: maint_item_id is null");
      return;
    }
    state.showOverlayLoading = true;
    const maintItem = await requestGetMaintItem(task.maint_item_id);
    const hinmokuNos = maintItem.hinmokusMhi.map(
      (hinmoku) => hinmoku.hinmoku_no,
    );
    state.mhiPartsSearchResult = await requestHinmoku(hinmokuNos);
    state.selectedMaintItem = maintItem;
    state.maintItemDetailOpenStatus = true;
  } catch (e: any) {
    state.snackBarMessage =
      e?.response?.data?.message || "整備項目詳細の取得に失敗しました。";
    state.snackBarType = "error";
    state.snackBarTitle = "データの取得に失敗しました。";
    state.popSnackBar = true;
  } finally {
    state.showOverlayLoading = false;
  }
};

/**
 * イベント詳細モーダルを開く
 */
const openEventDetail = async (row: MaintTask): Promise<void> => {
  if (row["is_event"]) {
    try {
      state.showOverlayLoading = true;
      const resp = await requestEventDetail(row["id"]);

      state.selectedMaintEvent = new MaintEvent(resp.maint_event);
      state.eventDetailOpenStatus = true;
    } catch (e: any) {
      state.snackBarMessage =
        e?.response?.data?.message || "イベント詳細の取得に失敗しました。";
      state.snackBarType = "error";
      state.snackBarTitle = "データの取得に失敗しました。";
      state.popSnackBar = true;
    } finally {
      state.showOverlayLoading = false;
    }
  } else {
    state.eventDetailOpenStatus = false;
  }
};

/**
 * 完了のチェックボックスのdisableの判定
 * 当日以降と前日の作業のみ完了を可能とする
 */
const isCompleteCheckboxDisabled = (item: MaintTask): boolean => {
  const today: Dayjs = dayjs();
  const yesterday: Dayjs = today.add(-1, "day");

  if (item.taskCompletedDateObj != null) {
    // 作業は完了しているので、完了日が本日のものはキャンセルが可能
    // 完了日が本日以外であれば disable (true)
    return !today.isSame(item.taskCompletedDateObj, "day");
  } else {
    // 作業は完了していないので、計画日が昨日以降のものは完了が可能
    // 計画日が昨日よりも前であれば disable (true)
    return !(
      item.taskScheduledDateObj.isSame(yesterday, "day") ||
      item.taskScheduledDateObj.isAfter(yesterday, "day")
    );
  }
};

/**
 * 作業を完了させたり、キャンセルさせたりする
 */
const taskCompletionChange = (item: MaintTask) => {
  const callReportTaskCompletionApi = (maint_task_id: number) =>
    connectToApi<ReportTaskCompletionApiResponse>({
      method: "POST",
      url: "/api/reportTaskCompletion",
      data: {
        maint_task_id: maint_task_id,
      },
    });

  const callCancelTaskCompletionApi = (maint_task_id: number) =>
    connectToApi<CancelTaskCompletionApiResponse>({
      method: "POST",
      url: "/api/cancelTaskCompletion",
      data: {
        maint_task_id: maint_task_id,
      },
    });

  // チェックボックスのv-modelにitem.doneを使用しているので
  // item.doneがtrue (false -> true)のときcompletedを
  // item.doneがfalse (true -> false)のときcancelを実行する
  if (item.done) {
    const response = callReportTaskCompletionApi(item.id);
    response
      .then((success) => {
        // APIリクエストに成功したら、レスポンスの完了日を渡して完了処理を行う
        const completedDateObj = dayjs(success.data.completed_date);
        item.completed(completedDateObj);
      })
      .catch((e) => {
        // エラーのときはチェックボックスを未完了状態に戻しておく
        item.done = false;
        state.snackBarType = "error";
        state.snackBarTitle = "データの更新に失敗しました。";
        state.snackBarMessage =
          e.response !== undefined
            ? e.response.data.message
            : "データの更新に関する不具合が発生しています。";
        state.popSnackBar = true;
      });
  } else {
    const response = callCancelTaskCompletionApi(item.id);
    response
      .then(() => {
        item.cancel();
      })
      .catch((e) => {
        // エラーのときはチェックボックスを完了状態に戻しておく
        item.done = true;
        state.snackBarType = "error";
        state.snackBarTitle = "データの更新に失敗しました。";
        state.snackBarMessage =
          e.response !== undefined
            ? e.response.data.message
            : "データの更新に関する不具合が発生しています。";
        state.popSnackBar = true;
      });
  }
};

/**
 * イベントを完了させたり、キャンセルさせたりする
 */
const eventCompletionChange = (item: MaintTask) => {
  const callReportEventCompletionApi = (maint_event_id: number) =>
    connectToApi<ReportEventCompletionApiResponse>({
      method: "POST",
      url: "/api/reportEventCompletion",
      data: {
        maint_event_id: maint_event_id,
      },
    });

  const callCancelEventCompletionApi = (maint_event_id: number) =>
    connectToApi<CancelEventCompletionApiResponse>({
      method: "POST",
      url: "/api/cancelEventCompletion",
      data: {
        maint_event_id: maint_event_id,
      },
    });

  // チェックボックスのv-modelにitem.doneを使用しているので
  // item.doneがtrue (false -> true)のときcompletedを
  // item.doneがfalse (true -> false)のときcancelを実行する
  if (item.done) {
    const response = callReportEventCompletionApi(item.id);
    response
      .then((success) => {
        // APIリクエストに成功したら、レスポンスの完了日を渡して完了処理を行う
        const completedDateObj = dayjs(success.data.completed_date);
        item.completed(completedDateObj);
      })
      .catch((e) => {
        // エラーのときはチェックボックスを未完了状態に戻しておく
        item.done = false;
        state.snackBarType = "error";
        state.snackBarTitle = "データの更新に失敗しました。";
        state.snackBarMessage =
          e.response !== undefined
            ? e.response.data.message
            : "データの更新に関する不具合が発生しています。";
        state.popSnackBar = true;
      });
  } else {
    const response = callCancelEventCompletionApi(item.id);
    response
      .then(() => {
        item.cancel();
      })
      .catch((e) => {
        // エラーのときはチェックボックスを完了状態に戻しておく
        item.done = true;
        state.snackBarType = "error";
        state.snackBarTitle = "データの更新に失敗しました。";
        state.snackBarMessage =
          e.response !== undefined
            ? e.response.data.message
            : "データの更新に関する不具合が発生しています。";
        state.popSnackBar = true;
      });
  }
};

/**
 * チェックボックスの値を変更を検知してMaintTaskを完了させたり、キャンセルさせたりする
 */
const changeCheckbox = (item: MaintTask) => {
  if (!item.is_event) {
    taskCompletionChange(item);
  } else {
    eventCompletionChange(item);
  }
};

const convertToDisplayDate = (date: string): string => {
  return dayjs(date).format("YYYY/MM/DD");
};

/**
 * 整備項目詳細表示のコンポーネント内におけるOpenStatusが変更されたときのイベントハンドラ
 */
const updateMaintItemDetailStatusHandler = (
  status: boolean | undefined,
): void => {
  if (status !== undefined) {
    state.maintItemDetailOpenStatus = status;
  }
};

/**
 * イベント詳細表示のコンポーネント内におけるOpenStatusが変更されたときのイベントハンドラ
 */
const updateEventDetailStatusHandler = (status: boolean | undefined): void => {
  if (status !== undefined) {
    state.eventDetailOpenStatus = status;
  }
};

/**
 * 子ComponentからEmitされたSnackBarを表示
 */
const snackBarHandler = (snackBar: AlertSnackBarModel | undefined) => {
  if (snackBar !== undefined) {
    state.popSnackBar = snackBar.isShow;
    state.snackBarType = snackBar.type;
    state.snackBarTitle = snackBar.title;
    state.snackBarMessage = snackBar.message;
  }
};

/**
 * 子Componentから、リフレッシュが必要というEmitを受けたらリフレッシュ
 */
const refreshHandler = (needRefresh: boolean | undefined) => {
  if (needRefresh !== undefined) {
    if (needRefresh) {
      init();
    }
  }
};

const init = async () => {
  state.isViewer = storeAuthorities.roleChecker.isViewer;
  await getMaintTaskListData(state.day);
};

/**
 * 各編集画面（整備項目・イベント）からのリダイレクト時にSnackBarを表示させる
 */
const showRedirectSnackbar = async () => {
  const query = Object.assign({}, route.query);
  const edit = query?.edit;
  const message = query?.message;
  const title = query?.title;
  const flag = edit != null || message != null || title != null;
  // リダイレクト元の判別用
  const editTarget = query?.edit_target;

  if (edit === "update" || edit === "delete") {
    state.snackBarType = "success";
    state.snackBarTitle = "";

    if (editTarget === "maintItem") {
      if (edit === "update") {
        state.snackBarMessage = "整備項目の変更を登録しました。";
      } else if (edit === "delete") {
        state.snackBarMessage = "整備項目を削除しました。";
      }
    } else if (editTarget === "event") {
      if (edit === "update") {
        state.snackBarMessage = "イベントを編集しました。";
      } else if (edit === "delete") {
        state.snackBarMessage = "イベントを削除しました。";
      }
    }
    state.popSnackBar = true;
  } else if (edit === "error") {
    state.snackBarType = "error";
    if (editTarget === "maintItem") {
      state.snackBarTitle = "整備項目の編集ができませんでした";
      state.snackBarMessage = "整備項目の編集に関する不具合が発生しました。";
    } else if (editTarget === "event") {
      state.snackBarTitle = "イベントの編集ができませんでした";
      state.snackBarMessage = "イベントの編集に関する不具合が発生しました。";
    }
    if (typeof message === "string") {
      state.snackBarMessage = message;
    }
    if (typeof title === "string") {
      state.snackBarTitle = title;
    }
    state.popSnackBar = true;
  } else if (flag) {
    state.snackBarType = "error";
    state.snackBarTitle = "";
    state.snackBarMessage = "不明なオペレーションです";

    state.popSnackBar = true;
  }

  if (flag) {
    delete query.edit;
    delete query.message;
    delete query.title;
    delete query.edit_target;

    await router.replace({ query });
  }
};

/**
 * URLのハッシュを削除する (何故かパスが "/" のときハッシュが残り続けるため)
 */
const removeHash = async () => {
  if (route.hash !== "") {
    await router.replace({ hash: "" });
  }
};

onMounted(() => {
  init();
  showRedirectSnackbar();
});

/**
 * 表示している日付の変更を検知してURLを更新しつつ、APIからデータを取得する
 */
watch(
  () => state.day,
  (val: Dayjs, valPrev: Dayjs) => {
    if (!val.isSame(valPrev, "day")) {
      updateUrlDate(val);
      getMaintTaskListData(val);
    }
  },
);

watch(
  () => storeAuthorities.useAuthoritiesIndex,
  () => {
    init();
  },
);

removeHash();
</script>

<style scoped>
:deep(.task-list.v-data-table table thead tr th) {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}
:deep(.task-list.v-data-table table thead tr th i.v-icon) {
  font-size: 24px;
}
:deep(.task-list .v-data-table-rows-loading) {
  color: rgba(0, 0, 0, 0.38);
}
:deep(.task-list .v-data-table-rows-no-data) {
  color: rgba(0, 0, 0, 0.38);
}
.maint-item-row .v-data-table__tr:hover .maint-item-cell span {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
:deep(.is-completed i.v-icon) {
  /* 完了のチェックボックスのサイズを大きくするため */
  font-size: 2rem;
}
</style>
