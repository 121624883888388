import { monotonicFactory } from "ulidx";

const factory = monotonicFactory();

/**
 * ULIDを発行する
 */
export const ulid = (): string => {
  // ULID (ソート可能なUUID互換のulid)
  //  -> https://github.com/ulid/spec
  //  -> https://qiita.com/kai_kou/items/b4ac2d316920e08ac75a
  return factory(new Date().getTime());
};
