<template>
  <v-container>
    <v-row align="center">
      <span>{{
        t(dateText, {
          date: $dayjs(date).format(t(formatI18Ncode)),
        })
      }}</span>
      <v-btn
        class="ma-2"
        size="small"
        variant="outlined"
        color="grey"
        @click="execEmitDateChange(undefined)"
      >
        {{ currentName }}
      </v-btn>
      <v-btn
        class="ma-1 date-change-btn"
        size="small"
        variant="outlined"
        color="grey"
        max-width="30"
        min-width="30"
        @click="execEmitDateChange(-1)"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        class="ma-0 date-change-btn"
        size="small"
        variant="outlined"
        color="grey"
        max-width="30"
        min-width="30"
        @click="execEmitDateChange(1)"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Dayjs } from "dayjs";

type Emits = {
  eventDateChange: [val: number | undefined];
};

type Props = {
  date: Dayjs;
  formatI18Ncode?: string;
  dateText?: string;
  currentName?: string;
};

withDefaults(defineProps<Props>(), {
  formatI18Ncode: "common.dateformat.date",
  dateText: "common.dateSelector.defaultText",
  currentName: "",
});

const emit = defineEmits<Emits>();

const { t } = useI18n();
// 親に通知する。
const execEmitDateChange = (val: number | undefined) => {
  emit("eventDateChange", val);
};
</script>

<style scoped>
.date-change-btn i {
  font-size: 24px;
}
</style>
