import { connectToApi } from "@/helpers/connectToApi";
import { PastEventCompletionResult } from "@/models/pastEventCompletionResult";
import { Dayjs } from "dayjs";

type UpdateMaintEventParam = {
  maint_event_id: number;
  plant_id: number;
  event_name: string;
  set: string;
  subject1: string;
  subject2: string;
  subject3: string;
  scheduled_date: Dayjs;
  display_colorcode: string;
  event_description: string;
  message: string;
  files: UpdateMaintEventParamFile[];
};

type UpdateMaintEventParamFile = {
  file_id: string;
  name: string;
  extension: string;
};

export async function requestUpdateMaintEvent(
  param: UpdateMaintEventParam,
): Promise<PastEventCompletionResult> {
  await connectToApi({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    url: "/api/updateMaintEvent",
    data: {
      maint_event_id: param.maint_event_id,
      plant_id: param.plant_id,
      event_name: param.event_name,
      set: param.set,
      subject1: param.subject1,
      subject2: param.subject2,
      subject3: param.subject3,
      scheduled_date: param.scheduled_date.format("YYYYMMDD"),
      display_colorcode: param.display_colorcode,
      event_description: param.event_description,
      message: param.message,
      files: param.files,
    },
  }).catch((e) => {
    throw e;
  });

  return {
    success: true,
  };
}
