import { defineStore } from "pinia";
import { ref, computed, unref } from "vue";
import { Authority } from "@/models/authority";

export const useAuthoritiesStore = defineStore("authorities", () => {
  const isNotInitialized = ref(true);
  const useAuthoritiesIndex = ref(-1);
  const authorities = ref<Authority[]>([]);

  const selectedAuthority = computed<Authority>(() => {
    const valueAuthorities = unref(authorities);
    const index = unref(useAuthoritiesIndex);
    const result = valueAuthorities[index];
    return result != null
      ? result
      : {
          plantId: "",
          roleName: undefined,
          plantName: "",
          corporateName: "",
        };
  });

  const roleChecker = {
    isUserAdmin: computed<boolean>(() => {
      return unref(selectedAuthority).roleName === "UserAdmin";
    }),
    isOperator: computed<boolean>(() => {
      return unref(selectedAuthority).roleName === "Operator";
    }),
    isViewer: computed<boolean>(() => {
      return unref(selectedAuthority).roleName === "Viewer";
    }),
  };

  function initialize(propAuthorities: Authority[]): void {
    isNotInitialized.value = false;
    authorities.value = propAuthorities;
    useAuthoritiesIndex.value = 0;
  }

  function setAuthoritiesIndex(propIndex: number) {
    useAuthoritiesIndex.value = propIndex;
  }

  return {
    isNotInitialized,
    useAuthoritiesIndex,
    authorities,
    selectedAuthority,
    roleChecker,
    initialize,
    setAuthoritiesIndex,
  };
});
