import axios, {
  AxiosHeaderValue,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { getTokenSilent } from "@/auth";

export async function connectToApi<T>(
  req: AxiosRequestConfig,
  alartObj = { flg: false, text: "error" },
): Promise<AxiosResponse<T>> {
  const { accessToken } = await getTokenSilent();
  // RawAxiosHeadersと同等の型。exportされてないため、再定義した
  const header: { [key: string]: AxiosHeaderValue } = {
    Authorization: `Bearer ${accessToken}`,
  };

  if (req.headers != null) {
    req.headers = Object.assign(header, req.headers);
  } else {
    req.headers = header;
  }

  return axios(req)
    .then((res: AxiosResponse<T>) => {
      return res;
    })
    .catch((err) => {
      alartObj.flg = true;
      throw err;
    });
}
