import { MaintItem, MaintItemData } from "@/models/MaintItem";
import { connectToApi } from "@/helpers/connectToApi";

type getMaintItemApiResponse = {
  maint_item: MaintItemData;
};

export async function requestGetMaintItem(
  maintItemId: number,
): Promise<MaintItem> {
  const response = await connectToApi<getMaintItemApiResponse>({
    method: "GET",
    url: "/api/getMaintItem",
    params: {
      maint_item_id: maintItemId,
    },
  }).catch((e) => {
    throw e;
  });
  return new MaintItem(response.data.maint_item);
}
