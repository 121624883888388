<template>
  <v-snackbar
    v-model="isShowRef"
    timeout="10000"
    location="top right"
    :color="type"
    :multi-line="title !== ''"
  >
    <v-row align="center">
      <v-col>
        <v-icon size="large">{{ prefixIcon }}</v-icon>
      </v-col>
      <v-col cols="auto">
        <div>
          <strong>{{ title }}</strong>
        </div>
        <div>{{ message }}</div>
      </v-col>
    </v-row>
    <template #actions>
      <v-btn variant="text" @click="isShowRef = false">
        <v-icon size="x-large">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

type Emits = {
  "update:modelValue": [val: boolean];
};

type Props = {
  modelValue?: boolean;
  title?: string;
  message?: string;
  type?: string;
};

const prop = withDefaults(defineProps<Props>(), {
  modelValue: false,
  title: "",
  message: "",
  type: "error",
});

const emit = defineEmits<Emits>();

const prefixIcon = computed(() => {
  if (prop.type == "error") return "mdi-alert-octagon-outline";
  else if (prop.type == "warning") return "mdi-alert-outline";
  else if (prop.type == "success") return "mdi-checkbox-marked-circle";
  else return "";
});

const isShowRef = ref<boolean>(false);

//親から主にtrueをもらったときにスナック・バーを表示させる
watch(
  () => prop.modelValue,
  (val: boolean) => {
    isShowRef.value = val;
  },
);

//スナックバーが時間経過で消えたときにv-model経由でそのフラグを返す
watch(
  () => isShowRef.value,
  (val: boolean) => {
    emit("update:modelValue", val);
  },
);
</script>
