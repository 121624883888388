<template>
  <v-container>
    <alert-snack-bar
      v-model="state.popSnackBar"
      :type="state.snackBarType"
      :title="state.snackBarTitle"
      :message="state.snackBarMessage"
    />
    <v-row>
      <v-col cols="12">
        <v-row align-content="center" class="mt-2">
          <v-col cols="12" class="d-inline-flex justify-end">
            <h3>整備項目一覧</h3>
            <v-spacer />
            <v-text-field
              v-model="search"
              color="primary"
              variant="outlined"
              density="compact"
              label="キーワードで検索（例：清掃）"
              class="mx-2 input-field-search"
            ></v-text-field>
            <v-btn
              color="primary"
              class="mx-2"
              :loading="state.isRequestingCsv"
              @click="downloadCSV"
            >
              <v-icon start> mdi-tray-arrow-down </v-icon>
              CSV出力
            </v-btn>
            <v-btn
              v-if="isUserAdmin"
              color="primary"
              class="mx-2"
              to="/edit-maint-item"
            >
              <v-icon start> mdi-plus </v-icon>
              整備項目追加
            </v-btn>
          </v-col>
        </v-row>
        <v-spacer />

        <v-dialog v-model="state.dialog.scheduled_date" width="650">
          <template v-if="isUserAdmin" #activator="dialogActivator">
            <v-btn
              v-bind="dialogActivator.props"
              class="my-2 mr-4"
              :color="!!state.selected.length ? 'primary' : undefined"
              :disabled="!!!state.selected.length"
            >
              整備計画日変更
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                  >チェックを入れた整備項目の整備計画日を一括で変更します。</span
                >
              </v-tooltip>
            </v-btn>
          </template>

          <v-card>
            <v-card-title> 整備計画日変更 </v-card-title>
            <v-card-text class="pa-1 text-body-2">
              <v-form ref="form" v-model="state.formState">
                <div class="bg-grey-lighten-2 rescheduled-plan-area">
                  <div
                    v-for="item in state.selected"
                    :key="item.id"
                    class="d-flex justify-space-between text-grey-darken-1"
                  >
                    <span>{{ getFullName(item) }}</span>
                    <span>
                      <span>{{ item.planed_date }}</span>
                      <span> → </span>
                      <span :class="calcClassRescheduledDate(item)">{{
                        calcRescheduledDate(item)
                      }}</span>
                    </span>
                  </div>
                </div>
                <div class="pa-3">
                  <v-radio-group v-model="state.radioGroup" color="primary">
                    <v-row>
                      <v-col cols="7">
                        <v-radio
                          label="日数でずらす"
                          value="1"
                          class="py-3"
                        ></v-radio>
                        <p id="annotation-days-shift">
                          全ての整備計画日を指定した日数だけスライドします
                        </p>
                      </v-col>
                      <v-col cols="5">
                        <div
                          class="d-flex justify-space-around shift-week-button-area"
                        >
                          <v-btn
                            rounded
                            variant="outlined"
                            size="small"
                            :disabled="state.radioGroup != '1'"
                            @click="setShiftDays(-7)"
                            >-1週</v-btn
                          >
                          <v-btn
                            rounded
                            variant="outlined"
                            size="small"
                            :disabled="state.radioGroup != '1'"
                            @click="setShiftDays(7)"
                            >+1週</v-btn
                          >
                        </div>
                        <v-text-field
                          v-model="state.shiftDays"
                          color="primary"
                          type="text"
                          :disabled="state.radioGroup != '1'"
                          :rules="ruleNumber"
                          density="compact"
                          variant="outlined"
                          class="py-0 input-shift"
                        >
                          <template #append-inner>
                            <v-icon
                              class="text-field-icon"
                              @click="setShiftDays(-1)"
                              >mdi-minus</v-icon
                            >
                            <v-icon
                              class="text-field-icon"
                              @click="setShiftDays(1)"
                              >mdi-plus</v-icon
                            >
                          </template>
                          <template #append>
                            <span class="text-field-suffix">日</span>
                          </template>
                        </v-text-field>
                        <p class="error-message">
                          {{ textShiftDaysErrorMessage }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="7">
                        <v-radio
                          label="指定日に変更する"
                          value="2"
                          class="py-3"
                          @click="clickRadio2"
                        ></v-radio>
                        <p id="annotation-define-day">
                          全ての整備計画日を同一の日付に変更します
                        </p>
                      </v-col>
                      <v-col cols="5" style="padding-top: 60px">
                        <v-menu
                          v-model="state.datepicker.isShow"
                          :close-on-content-click="false"
                          :offset="40"
                          :disabled="state.radioGroup != '2'"
                          location="bottom"
                          min-width="auto"
                        >
                          <template #activator="{ props }">
                            <v-text-field
                              v-bind="props"
                              v-model="state.datepicker.displayDate"
                              class="datepicker-textfield input-shift"
                              readonly
                              append-inner-icon="mdi-calendar"
                              color="primary"
                              density="compact"
                              :disabled="state.radioGroup != '2'"
                              variant="outlined"
                              width="140px"
                            />
                          </template>
                          <v-locale-provider locale="ja">
                            <v-date-picker
                              v-model="state.datepicker.inputDate"
                              color="primary"
                              header=""
                              :title="
                                $dayjs(state.datepicker.inputDate).format(
                                  'YYYY年',
                                )
                              "
                              class="elevation-1"
                              @update:model-value="
                                state.datepicker.isShow = false
                              "
                            />
                          </v-locale-provider>
                        </v-menu>
                        <p class="error-message">
                          {{ textMoveSpecificDayErrorMessage }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-form>
            </v-card-text>

            <v-card-actions class="justify-end">
              <v-btn
                variant="outlined"
                @click="state.dialog.scheduled_date = false"
                >キャンセル</v-btn
              >
              <v-btn
                :color="!isDisableApplyButton ? 'primary' : undefined"
                variant="flat"
                :disabled="isDisableApplyButton"
                :loading="state.isRequestingApi"
                @click="reschedule"
                >変更</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="state.dialog.maint_interval_day" width="650">
          <template v-if="isUserAdmin" #activator="dialogActivator">
            <v-btn
              v-bind="dialogActivator.props"
              class="my-2 mr-4"
              :color="!!state.selected.length ? 'primary' : undefined"
              :disabled="!!!state.selected.length"
            >
              作業周期変更
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                  >チェックを入れた整備項目の作業周期を一括で変更します。</span
                >
              </v-tooltip>
            </v-btn>
          </template>

          <v-card>
            <v-card-title> 作業周期変更 </v-card-title>
            <v-card-text class="pa-1 text-body-2">
              <div class="bg-grey-lighten-2 rescheduled-plan-area">
                <div
                  v-for="item in state.selected"
                  :key="item.id"
                  class="d-flex justify-space-between text-grey-darken-1"
                >
                  <span>{{ getFullName(item) }}</span>
                  <span>
                    <span>{{ item.days_of_replacement_period }}日</span>
                    <span> → </span>
                    <span :class="calcClassChangedMaintIntervalDay()"
                      >{{ calcChangedMaintIntervalDay(item) }}日</span
                    >
                  </span>
                </div>
              </div>
              <span class="ml-3 text-grey-darken-1"
                ><v-icon
                  v-if="state.differentMaintIntervalDay.isShow"
                  size="small"
                  >mdi-information-outline</v-icon
                >{{ state.differentMaintIntervalDay.message }}</span
              >
              <div class="pa-3">
                <v-row>
                  <v-col cols="5" style="padding-top: 20px">
                    <v-text-field
                      v-model="state.inputMaintIntervalDay"
                      color="primary"
                      variant="outlined"
                      density="compact"
                      type="text"
                      maxlength="4"
                      :rules="ruleInputMaintInterval"
                      hint="1~9999の整数のみ可能です"
                    />
                  </v-col>
                  <v-col cols="7" class="mt-5 ml-n3"> 日 </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="outlined"
                @click="state.dialog.maint_interval_day = false"
                >キャンセル</v-btn
              >
              <v-btn
                :color="
                  !isDisableApplyButtonChangedMaintIntervalDay
                    ? 'primary'
                    : undefined
                "
                :disabled="isDisableApplyButtonChangedMaintIntervalDay"
                :loading="state.isRequestingApi"
                variant="flat"
                @click="changeMaintIntervalDay"
                >変更</v-btn
              >
            </v-card-actions>
          </v-card> </v-dialog
        ><v-dialog disabled>
          <template v-if="isUserAdmin" #activator="dialogActivator">
            <v-btn
              v-bind="dialogActivator.props"
              class="my-2 mr-4"
              :color="!!state.selected.length ? 'primary' : undefined"
              :disabled="!!!state.selected.length"
              @click="openBulkEditMaintItemsDialog"
            >
              整備内容の一括変更
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                  >チェックを入れた整備項目の名称や整備内容を一括で変更します。</span
                >
              </v-tooltip>
            </v-btn>
          </template></v-dialog
        >
        <v-sheet color="white" elevation="1">
          <v-data-table
            v-model="state.selected"
            density="comfortable"
            :headers="headers"
            hover
            :items="viewData"
            :items-per-page="10"
            :loading="isRequestingApi ? 'primary' : false"
            :page="page"
            items-per-page-text="行/ページ:"
            :search="search"
            no-data-text="整備項目なし"
            return-object
            :show-select="isUserAdmin"
            class="elevation-0 operation-history maint-item-row"
            @update:page="updatePageQueryString"
          >
            <template #[`header.name`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterName.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="set in Array.from(
                      new Set(
                        apiResponse.maintItems.map(
                          (maintItem) => maintItem.name,
                        ),
                      ),
                    ).sort()"
                    :key="set"
                    v-model="state.filterName"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="set"
                    :value="set"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterName = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`item.name`]="{ item }">
              <td class="maint-item-cell" @click="openMaintItemDetail(item)">
                <span :class="getMaintItemNameColor(item)">
                  {{ item.name }}
                </span>
                <v-icon v-if="item.having_images || item.having_files">
                  mdi-paperclip
                </v-icon>
              </td>
            </template>
            <template #[`header.set`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterSet.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="set in Array.from(
                      new Set(
                        apiResponse.maintItems.map(
                          (maintItem) => maintItem.set,
                        ),
                      ),
                    ).sort()"
                    :key="set"
                    v-model="state.filterSet"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="set"
                    :value="set"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterSet = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`header.subject1`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterSubject1.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="subject1 in Array.from(
                      new Set(
                        apiResponse.maintItems.map(
                          (maintItem) => maintItem.subject1,
                        ),
                      ),
                    ).sort()"
                    :key="subject1"
                    v-model="state.filterSubject1"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="subject1"
                    :value="subject1"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterSubject1 = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`header.subject2`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterSubject2.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="subject2 in Array.from(
                      new Set(
                        apiResponse.maintItems.map(
                          (maintItem) => maintItem.subject2,
                        ),
                      ),
                    ).sort()"
                    :key="subject2"
                    v-model="state.filterSubject2"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="subject2"
                    :value="subject2"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterSubject2 = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`header.subject3`]="{ column, getSortIcon }">
              {{ column.title }}
              <v-menu location="bottom" :close-on-content-click="false">
                <template #activator="{ props }">
                  <v-btn v-bind="props" icon size="x-small" variant="text">
                    <v-icon v-if="state.filterSubject3.length">
                      mdi-filter-check
                    </v-icon>
                    <v-icon v-else> mdi-filter-outline </v-icon>
                  </v-btn>
                </template>
                <v-sheet>
                  <v-checkbox
                    v-for="subject3 in Array.from(
                      new Set(
                        apiResponse.maintItems.map(
                          (maintItem) => maintItem.subject3,
                        ),
                      ),
                    ).sort()"
                    :key="subject3"
                    v-model="state.filterSubject3"
                    class="ml-2 mr-4 mt-1 mb-n6 pa-0"
                    density="compact"
                    :label="subject3"
                    :value="subject3"
                  ></v-checkbox>
                  <v-btn
                    class="ma-2"
                    variant="outlined"
                    size="small"
                    @click="state.filterSubject3 = []"
                    >clear</v-btn
                  >
                </v-sheet>
              </v-menu>
              <v-icon
                :icon="getSortIcon(column)"
                class="v-data-table-header__sort-icon"
              ></v-icon>
            </template>
            <template #[`item.planed_date`]="{ item }">
              <span :class="getPlanedDateColor(item)">
                {{ item.planed_date }}
              </span>
            </template>
            <template #[`item.days_of_replacement_period`]="{ item }">
              <span :class="getMaintIntervalDayColor(item)">
                {{ item.days_of_replacement_period }}
              </span>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <maint-item-detail
      :maint-item-detail-open-status="state.maintItemDetailOpenStatus"
      :maint-item="state.selectedMaintItem"
      :mhi-parts-search-result="state.mhiPartsSearchResult"
      @open-status-update-request="updateMaintItemDetailStatusHandler"
      @snack-bar="snackBarHandler"
      @need-refresh="refreshHandler"
    />
    <bulk-edit-maint-items-dialog
      :bulk-edit-maint-item-dialog-open-status="
        state.bulkEditMaintItemDialogOpenStatus
      "
      :selected-maint-item-list="state.selectedMaintItemList"
      @open-status-update-request="updateBulkEditMaintItemsDialogStatusHandler"
      @api-result-request="apiResultHandler"
    />
    <overlay-loading :is-loading="state.showOverlayLoading" />
  </v-container>
</template>

<script setup lang="ts">
import { reactive, onMounted, ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import { connectToApi } from "@/helpers/connectToApi";
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import OverlayLoading from "@/components/OverlayLoading.vue";
import MaintItemDetail from "@/components/MaintItemDetail.vue";
import BulkEditMaintItemsDialog from "@/components/BulkEditMaintItemsDialog.vue";
import { MaintItem } from "@/models/MaintItem";
import { requestHinmoku } from "@/helpers/api/hojyuHinmoku";
import { requestGetMaintItem } from "@/helpers/api/getMaintItemDetail";
import { MhiPartsSearchResult } from "@/models/mhiPartsSearchResult";
import { useAuthoritiesStore } from "@/stores/authorities";
import { AlertSnackBarModel } from "@/models/snackbar";

type MaintItemRecord = {
  id: number;
  maint_task_id: number;
  plant_id: number;
  item_no: number;
  order: number;
  name: string;
  set: string;
  subject1: string;
  subject2: string;
  subject3: string;
  days_of_replacement_period: number;
  having_images: boolean;
  having_files: boolean;
  planed_date: string;
  before_minus_0_date: string;
  before_minus_1_date: string;
  before_minus_2_date: string;
};

type EditScheduledDateApiResponse = {
  maint_task_id: number;
  scheduled_date: string;
};

type EditMaintIntervalDayApiResponse = {
  maint_item_id: number;
  days_of_maint_interval: number;
};

type ApiResponse = {
  maintItems: MaintItemRecord[];
};
type CsvApiResponse = {
  csv: string;
};

type StateDatePicker = {
  isShow: boolean;
  inputDate: Date | undefined;
  displayDate: string;
};

type StateDifferentValuesMaintInterval = {
  isShow: boolean;
  message: string;
};

type DialogStatus = {
  scheduled_date: boolean;
  maint_interval_day: boolean;
};

type SelectedMaintItem = {
  maintItemId: number;
  displayName: string;
};

type TableFilter = {
  filterName: string[];
  filterSet: string[];
  filterSubject1: string[];
  filterSubject2: string[];
  filterSubject3: string[];
  selected: MaintItemRecord[];
  radioGroup: string;
  shiftDays: number | string;
  popSnackBar: boolean;
  snackBarType: string;
  snackBarTitle: string;
  snackBarMessage: string;
  datepicker: StateDatePicker;
  inputMaintIntervalDay: number | null;
  isRequestingApi: boolean;
  isRequestingCsv: boolean;
  maintItemDetailOpenStatus: boolean;
  bulkEditMaintItemDialogOpenStatus: boolean;
  selectedMaintItem: MaintItem; // 整備項目詳細モーダルに渡す用
  mhiPartsSearchResult: MhiPartsSearchResult; // 整備項目詳細モーダルに渡す用
  showOverlayLoading: boolean;
  differentMaintIntervalDay: StateDifferentValuesMaintInterval;
  dialog: DialogStatus;
  selectedMaintItemList: SelectedMaintItem[];
  formState: boolean;
};

const storeAuthorities = useAuthoritiesStore();
const route = useRoute();
const router = useRouter();

const getOperationHistory = async (): Promise<ApiResponse> => {
  const response = await connectToApi<ApiResponse>({
    method: "GET",
    url: "/api/getOperationHistory",
    params: {
      plant_id: storeAuthorities.selectedAuthority.plantId,
    },
  }).catch((e) => {
    state.snackBarType = "error";
    state.snackBarTitle = "データの更新に失敗しました。";
    state.snackBarMessage =
      e.response !== undefined
        ? e.response.data.message
        : "データの更新に関する不具合が発生しています。";
    state.popSnackBar = true;
    throw e;
  });

  response.data.maintItems.forEach((item) => {
    item.planed_date = dayjs(item.planed_date).format("YYYY/MM/DD");
    if (item.before_minus_0_date !== "-") {
      item.before_minus_0_date = dayjs(item.before_minus_0_date).format(
        "YYYY/MM/DD",
      );
    }
    if (item.before_minus_1_date !== "-") {
      item.before_minus_1_date = dayjs(item.before_minus_1_date).format(
        "YYYY/MM/DD",
      );
    }
    if (item.before_minus_2_date !== "-") {
      item.before_minus_2_date = dayjs(item.before_minus_2_date).format(
        "YYYY/MM/DD",
      );
    }
  });
  return response.data;
};
const apiResponse = reactive<ApiResponse>({
  maintItems: [],
});

/**
 * 整備項目詳細モーダルを開く
 */
const openMaintItemDetail = async (item: any) => {
  try {
    state.showOverlayLoading = true;
    const maintItem = await requestGetMaintItem(Number(item.id));
    const hinmokuNos = maintItem.hinmokusMhi.map(
      (hinmoku) => hinmoku.hinmoku_no,
    );
    state.mhiPartsSearchResult = await requestHinmoku(hinmokuNos);
    state.selectedMaintItem = maintItem;
    state.maintItemDetailOpenStatus = true;
  } catch (e: any) {
    state.snackBarMessage =
      e?.response?.data?.message || "整備項目詳細の取得に失敗しました。";
    state.snackBarType = "error";
    state.snackBarTitle = "データの取得に失敗しました。";
    state.popSnackBar = true;
  } finally {
    state.showOverlayLoading = false;
  }
};

const getOperationHistoryCsv = async (): Promise<CsvApiResponse> => {
  //利用側でcatch処理実装
  const response = await connectToApi<CsvApiResponse>({
    method: "GET",
    url: "/api/getOperationHistoryCsv",
    params: {
      plant_id: storeAuthorities.selectedAuthority.plantId,
    },
  });

  return response.data;
};

const viewData = computed(() => {
  let returnData = apiResponse.maintItems;

  if (state.filterName.length) {
    returnData = returnData.filter((maintItem) =>
      state.filterName.includes(maintItem.name),
    );
  }
  if (state.filterSet.length) {
    returnData = returnData.filter((maintItem) =>
      state.filterSet.includes(maintItem.set),
    );
  }
  if (state.filterSubject1.length) {
    returnData = returnData.filter((maintItem) =>
      state.filterSubject1.includes(maintItem.subject1),
    );
  }
  if (state.filterSubject2.length) {
    returnData = returnData.filter((maintItem) =>
      state.filterSubject2.includes(maintItem.subject2),
    );
  }
  if (state.filterSubject3.length) {
    returnData = returnData.filter((maintItem) =>
      state.filterSubject3.includes(maintItem.subject3),
    );
  }
  //noは行番号にする。vslot使う場合はrowしかとれず、二ページ目以降も0から始まってしまうので値を作ってセットする
  returnData.forEach((data, index) => {
    data.item_no = index + 1;
  });

  return returnData;
});

const isRequestingApi = ref(false);
const page = ref(1);
const search = ref<string>("");
const isUserAdmin = storeAuthorities.roleChecker.isUserAdmin;
const rescheduledScheduledDateIds = new Set();
const changedMaintIntervalIds = new Set();
const editedMaintItemNameIds = new Set();

const state = reactive<TableFilter>({
  filterName: [],
  filterSet: [],
  filterSubject1: [],
  filterSubject2: [],
  filterSubject3: [],
  selected: [],
  radioGroup: "1",
  shiftDays: 0,
  popSnackBar: false,
  snackBarType: "error",
  snackBarTitle: "データの更新に失敗しました。",
  snackBarMessage: "データの更新に関する不具合が発生しています。",
  datepicker: {
    isShow: false,
    inputDate: undefined,
    displayDate: "",
  },
  inputMaintIntervalDay: null,
  isRequestingApi: false,
  isRequestingCsv: false,
  maintItemDetailOpenStatus: false,
  bulkEditMaintItemDialogOpenStatus: false,
  selectedMaintItem: MaintItem.dummy(),
  mhiPartsSearchResult: MhiPartsSearchResult.empty(),
  showOverlayLoading: false,
  differentMaintIntervalDay: {
    isShow: false,
    message: "",
  },
  dialog: {
    scheduled_date: false,
    maint_interval_day: false,
  },
  selectedMaintItemList: [],
  formState: false,
});
let startPage: number | null = null;

const getPageFromQueryString = (): number | null => {
  const raw = route.query.page;
  const num = Number(raw);
  return Number.isNaN(num) ? null : num;
};

const loadOperationHistory = async () => {
  setStartPage();
  await displayOperationHistory();
};

const setStartPage = () => {
  startPage = getPageFromQueryString();
};

const displayOperationHistory = async () => {
  isRequestingApi.value = true;
  const data: ApiResponse = await getOperationHistory();
  apiResponse.maintItems = data.maintItems;
  if (startPage != null) {
    page.value = startPage;
    startPage = null;
  }
  isRequestingApi.value = false;
};

const updatePageQueryString = (currentPage: number) => {
  router.push({
    query: {
      page: currentPage.toString(),
    },
  });
  page.value = currentPage;
};

/**
 * 有効な数値であることを判定する
 */
const isValidInteger = (value: string): boolean => {
  return (
    Number.isInteger(Number(value)) &&
    !/^\s*$/.test(value) &&
    !/^[\u3000]*$/.test(value)
  );
};

/**
 * ダイアログのテキストフィールドのバリデーション判定
 */
const ruleNumber = [
  (v: string) => {
    if (v === "") return true;
    if (v !== "" && !isValidInteger(v)) return "整数値を入力して下さい";
    return true;
  },
];

const ruleInputMaintInterval = [
  (v: string | null) =>
    (Number.isInteger(Number(v)) && Number(v) > 0 && Number(v) < 10000) ||
    "1~9999の整数を入力して下さい",
];

const updateMaintItemDetailStatusHandler = (
  status: boolean | undefined,
): void => {
  if (status !== undefined) {
    state.maintItemDetailOpenStatus = status;
  }
};

const updateBulkEditMaintItemsDialogStatusHandler = (
  status: boolean | undefined,
): void => {
  if (status !== undefined) {
    state.bulkEditMaintItemDialogOpenStatus = status;
  }
};

const getFullName = (item: MaintItemRecord) => {
  let fullname = item.name + "-" + item.set;
  if (item.subject1 != "-") fullname = fullname + "-" + item.subject1;
  if (item.subject2 != "-") fullname = fullname + "-" + item.subject2;
  if (item.subject3 != "-") fullname = fullname + "-" + item.subject3;
  return fullname;
};

/**
 * 変更後の整備計画日の文字色を変えるために適用するクラスを指定する
 * @param item 整備項目と履歴
 * @return 適用するクラス (keyがクラス名、valueがtrueのときにクラスが適用される)
 */
const calcClassRescheduledDate = (
  item: MaintItemRecord,
): { [key: string]: boolean } => {
  const today = dayjs();
  let rescheduled = dayjs(item.planed_date).add(Number(state.shiftDays), "day");
  if (state.radioGroup === "2") {
    rescheduled = dayjs(state.datepicker.inputDate);
  }
  const flag = rescheduled.isBefore(today, "day");
  return {
    "invalid-rescheduled-date": flag,
    "valid-rescheduled-date": !flag,
  };
};

/**
 * 変更後の整備計画日を算出する
 * @param item 整備項目とその履歴
 */
const calcRescheduledDate = (item: MaintItemRecord): string => {
  if (state.radioGroup === "1") {
    return dayjs(item.planed_date)
      .add(Number(state.shiftDays), "day")
      .format("YYYY/MM/DD");
  } else if (state.radioGroup === "2") {
    return state.datepicker.displayDate;
  } else {
    return "ERROR";
  }
};

/**
 * 作業日の変更を適用するためのボタンが無効かどうかを判定する
 */
const isDisableApplyButton = computed((): boolean => {
  const today = dayjs();
  if (state.radioGroup === "1") {
    for (const item of state.selected) {
      const scheduled = dayjs(item.planed_date);
      const rescheduled = scheduled.add(Number(state.shiftDays), "day");
      if (rescheduled.isBefore(today, "day") || !state.formState) {
        return true;
      }
    }
    return false;
  } else if (state.radioGroup === "2") {
    return dayjs(state.datepicker.inputDate).isBefore(today, "day");
  }
  return false;
});

/**
 * 指定日でまとめるラジオボタンをおしたときに動かす関数
 */
const clickRadio2 = () => {
  if (!state.datepicker.inputDate) {
    state.datepicker.inputDate = dayjs().toDate();
  }
};

/**
 * 日数でずらす際のエラーメッセージを算出する
 */
const textShiftDaysErrorMessage = computed((): string => {
  const shiftDays = String(state.shiftDays);

  if (
    isDisableApplyButton.value &&
    (shiftDays === "" ||
      (state.radioGroup === "1" && isValidInteger(shiftDays)))
  ) {
    return "過去には変更できません";
  }

  return "";
});

/**
 * 指定日でまとめる際のエラーメッセージを算出する
 */
const textMoveSpecificDayErrorMessage = computed((): string => {
  return state.radioGroup === "2" && isDisableApplyButton.value
    ? "過去には変更できません"
    : "";
});

/**
 * ±ボタンによる日数を算出する
 */
const setShiftDays = (shiftValue: number) => {
  if (Number.isInteger(Number(state.shiftDays))) {
    state.shiftDays = Number(state.shiftDays) + shiftValue;
  }
};

/**
 * 整備計画日の変更を行う
 */
const reschedule = async () => {
  state.isRequestingApi = true;

  type apiInputData = {
    maint_task_id: number;
    scheduled_date: string;
  };
  const callApi = async (
    array: apiInputData[],
  ): Promise<EditScheduledDateApiResponse[]> => {
    const resp = await connectToApi<EditScheduledDateApiResponse[]>({
      method: "POST",
      url: "/api/editScheduledDates",
      data: array,
    });
    return resp.data;
  };

  const data: apiInputData[] = state.selected.map((item) => {
    let date = dayjs(item.planed_date).add(Number(state.shiftDays), "day");
    if (state.radioGroup === "2") {
      date = dayjs(state.datepicker.inputDate);
    }
    return {
      maint_task_id: item.maint_task_id,
      scheduled_date: date.format("YYYYMMDD"),
    };
  });

  try {
    await callApi(data);

    rescheduledScheduledDateIds.clear();
    for (const item of state.selected) {
      let date = dayjs(item.planed_date).add(Number(state.shiftDays), "day");
      if (state.radioGroup === "2") {
        date = dayjs(state.datepicker.inputDate);
      }
      item.planed_date = date.format("YYYY/MM/DD");
      rescheduledScheduledDateIds.add(item.maint_task_id);
    }
    state.selected = [];

    state.snackBarType = "success";
    state.snackBarTitle = "";
    state.snackBarMessage = "計画日の変更を登録しました。";
    state.popSnackBar = true;

    state.dialog.scheduled_date = false;
  } catch (e: any) {
    state.snackBarType = "error";
    state.snackBarTitle = "データの更新に失敗しました。";
    state.snackBarMessage =
      e.response !== undefined
        ? e.response.data.message
        : "データの更新に関する不具合が発生しています。";
    state.popSnackBar = true;
  } finally {
    state.isRequestingApi = false;
  }
};

/**
 * 変更後の作業周期の文字色を変えるために適用するクラスを指定する
 * @return 適用するクラス (keyがクラス名、valueがtrueのときにクラスが適用される)
 */
const calcClassChangedMaintIntervalDay = () => {
  // 未入力時、数値以外の入力時、入力後に入力欄を空にした場合
  if (
    state.inputMaintIntervalDay == null ||
    !String(state.inputMaintIntervalDay).match(/\S/g) ||
    !Number.isInteger(Number(state.inputMaintIntervalDay))
  ) {
    return {
      "invalid-changed-interval-day": false,
      "valid-changed-interval-day": false,
    };
  }
  // 入力範囲外の数値の入力時
  else if (
    state.inputMaintIntervalDay > 9999 ||
    state.inputMaintIntervalDay < 1
  ) {
    return {
      "invalid-changed-interval-day": true,
      "valid-changed-interval-day": false,
    };
  } else {
    return {
      "invalid-changed-interval-day": false,
      "valid-changed-interval-day": true,
    };
  }
};

/**
 * 変更後の作業周期を算出する
 * @param item 整備項目とその履歴
 */
const calcChangedMaintIntervalDay = (item: MaintItemRecord): number => {
  // ０より大きい数値の入力時
  if (
    state.inputMaintIntervalDay !== null &&
    Number(state.inputMaintIntervalDay) > 0
  ) {
    return Number(state.inputMaintIntervalDay);
  }
  // 未入力時
  else if (state.inputMaintIntervalDay == null) {
    return item.days_of_replacement_period;
  } else {
    return item.days_of_replacement_period;
  }
};

/**
 * 作業周期の変更を適用するためのボタンが無効かどうかを判定する
 */
const isDisableApplyButtonChangedMaintIntervalDay = computed((): boolean => {
  return (
    state.inputMaintIntervalDay == null ||
    Number(state.inputMaintIntervalDay) === 0 ||
    !Number.isInteger(Number(state.inputMaintIntervalDay)) ||
    state.inputMaintIntervalDay < 0 ||
    state.inputMaintIntervalDay > 9999
  );
});

/**
 * 選択した整備項目の作業周期が異なるかどうかを判定する
 */
const isDifferenceMaintIntervalDay = (): boolean => {
  if (state.selected.length > 1) {
    const firstVal = state.selected[0].days_of_replacement_period;
    for (const item of state.selected) {
      if (item.days_of_replacement_period !== firstVal) {
        return false;
      }
    }
    return true;
  } else {
    return true;
  }
};

/**
 * 選択した整備項目の作業周期が異なる場合は警告文を表示する
 */
const displayWaringDifferenceMaintIntervalDay = (): void => {
  if (!isDifferenceMaintIntervalDay()) {
    state.differentMaintIntervalDay.isShow = true;
    state.differentMaintIntervalDay.message =
      "作業周期の異なる整備項目が含まれています";
  }
};

/**
 * 作業周期の変更を行う
 */
const changeMaintIntervalDay = async () => {
  state.isRequestingApi = true;

  type apiInputData = {
    plant_id: number;
    maint_items: apiInputDataMaintItem[];
  };
  type apiInputDataMaintItem = {
    maint_item_id: number;
    days_of_maint_interval: number;
  };
  const callApi = async (
    param: apiInputData,
  ): Promise<EditMaintIntervalDayApiResponse[]> => {
    const resp = await connectToApi<EditMaintIntervalDayApiResponse[]>({
      method: "POST",
      url: "/api/editMaintIntervalDays",
      data: param,
    });
    return resp.data;
  };

  const plantId = storeAuthorities.selectedAuthority.plantId;
  const maintItems: apiInputDataMaintItem[] = state.selected.map((item) => {
    return {
      maint_item_id: item.id,
      days_of_maint_interval: Number(state.inputMaintIntervalDay),
    };
  });
  const data: apiInputData = {
    plant_id: parseInt(plantId, 10),
    maint_items: maintItems,
  };

  try {
    await callApi(data);

    changedMaintIntervalIds.clear();
    for (const item of state.selected) {
      item.days_of_replacement_period = Number(state.inputMaintIntervalDay);
      changedMaintIntervalIds.add(item.id);
    }

    state.selected = [];

    state.snackBarType = "success";
    state.snackBarTitle = "";
    state.snackBarMessage = "作業周期の変更を登録しました。";
    state.popSnackBar = true;

    state.dialog.maint_interval_day = false;
  } catch (e: any) {
    state.snackBarType = "error";
    state.snackBarTitle = "データの更新に失敗しました。";
    state.snackBarMessage =
      e.response !== undefined
        ? e.response.data.message
        : "データの更新に関する不具合が発生しています。";
    state.popSnackBar = true;
  } finally {
    state.isRequestingApi = false;
  }
};

/**
 * 選択した整備項目名が異なるかどうかを判定する
 */
const isDifferenceMaintItemName = (): boolean => {
  if (state.selected.length > 1) {
    const firstVal = state.selected[0].name;
    for (const item of state.selected) {
      if (item.name !== firstVal) {
        return false;
      }
    }
    return true;
  } else {
    return true;
  }
};

/**
 * 選択した整備項目のIDと表示の名前をセットする
 */
const setSelectedMaintItemList = async () => {
  state.selectedMaintItemList = state.selected.map((item) => {
    return {
      maintItemId: item.id,
      displayName: `${item.name}-${item.set}-${item.subject1}-${item.subject2}-${item.subject3}`,
    };
  });
};

/**
 * 整備内容の一括変更モーダルを開く
 */
const openBulkEditMaintItemsDialog = async () => {
  setSelectedMaintItemList();

  if (!isDifferenceMaintItemName()) {
    // 確認モーダルを表示
    const confirmMessage =
      "異なる整備項目名が含まれていますが、本当に一括変更しますか？";
    const confirmed = confirm(confirmMessage);
    if (!confirmed) {
      return;
    }
  }

  state.bulkEditMaintItemDialogOpenStatus = true;
};

/**
 * 整備内容の一括変更モーダルより渡された値でAPI実行後の処理を行う
 * 成功：整備項目名色変更、スナックバー表示
 * 失敗：スナックバー表示
 */
const apiResultHandler = (apiResult: any) => {
  // API実行が成功の場合は整備項目名色を変更
  if (apiResult.snackBarType === "success") {
    editedMaintItemNameIds.clear();
    for (const item of state.selected) {
      item.name = apiResult.displayMaintItemName;
      editedMaintItemNameIds.add(item.id);
    }
    // チェックボックスを外す
    state.selected = [];
  }
  state.snackBarType = apiResult.snackBarType;
  state.snackBarTitle = apiResult.snackBarTitle;
  state.snackBarMessage = apiResult.snackBarMessage;
  state.popSnackBar = apiResult.popSnackBar;
};

/**
 * 子ComponentからEmitされたSnackBarを表示
 */
const snackBarHandler = (snackBar: AlertSnackBarModel | undefined) => {
  if (snackBar !== undefined) {
    state.popSnackBar = true;
    state.snackBarType = snackBar.type;
    state.snackBarTitle = snackBar.title;
    state.snackBarMessage = snackBar.message;
  }
};

/**
 * 子Componentから、リフレッシュが必要というEmitを受けたらリフレッシュ
 */
const refreshHandler = (needRefresh: boolean | undefined) => {
  if (needRefresh !== undefined) {
    if (needRefresh) {
      loadOperationHistory();
    }
  }
};

const downloadCSV = async () => {
  state.isRequestingCsv = true;

  const filename = `整備項目一覧_${dayjs().format("YYYYMMDDHHmmss")}.csv`;

  try {
    //CSVデータ
    const data: CsvApiResponse = await getOperationHistoryCsv();

    const csv = data.csv;
    // CSV ファイルは `UTF-8 BOM有り` で出力する
    // そうすることで Excel で開いたときに文字化けせずに表示できる
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    // CSVファイルを出力するために Blob 型のインスタンスを作る
    const blob = new Blob([bom, csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    a.remove();
  } catch (e: any) {
    state.snackBarType = "error";
    state.snackBarTitle = "データの更新に失敗しました。";
    state.snackBarMessage =
      e.response !== undefined
        ? e.response.data.message
        : "データの更新に関する不具合が発生しています。";
    state.popSnackBar = true;
  } finally {
    state.isRequestingCsv = false;
  }
};

watch(
  () => state.datepicker.inputDate,
  () => {
    let result = "";
    if (state.datepicker.inputDate) {
      result = dayjs(state.datepicker.inputDate).format("YYYY/MM/DD");
    }
    state.datepicker.displayDate = result;
  },
);

// 整備計画日変更モーダルが閉じられたのを検知して、formの中身を消去している
watch(
  () => state.dialog.scheduled_date,
  () => {
    if (!state.dialog.scheduled_date) {
      state.radioGroup = "1";
      state.shiftDays = 0;
      state.datepicker.inputDate = undefined;
    }
  },
);

// 作業周期変更モーダルの開閉を監視している
watch(
  () => state.dialog.maint_interval_day,
  () => {
    if (!state.dialog.maint_interval_day) {
      // モーダルが閉じられるとformの中身を消去
      state.inputMaintIntervalDay = null;
      state.differentMaintIntervalDay.isShow = false;
      state.differentMaintIntervalDay.message = "";
    } else {
      // モーダルが開かれると作業周期をチェック
      displayWaringDifferenceMaintIntervalDay();
    }
  },
);

const getPlanedDateColor = (item: MaintItemRecord) => {
  const flg_rescheduled = rescheduledScheduledDateIds.has(item.maint_task_id);
  const flg_remaining = dayjs(item.planed_date).isBefore(dayjs(), "day");
  return {
    "text-red": flg_remaining,
    "valid-rescheduled-date": flg_rescheduled,
  };
};

const getMaintIntervalDayColor = (item: MaintItemRecord) => {
  const flg_changed = changedMaintIntervalIds.has(item.id);
  return {
    "valid-changed-interval-day": flg_changed,
  };
};

const getMaintItemNameColor = (item: MaintItemRecord) => {
  const flg_changed = editedMaintItemNameIds.has(item.id);
  return {
    "valid-edited-maint-item-name": flg_changed,
  };
};

const headers = computed(() => {
  const base = [
    { title: "No.", key: "item_no", width: "4%" },
    { title: "整備項目名", key: "name", width: "25%" },
    { title: "セット", key: "set", width: "8%" },
    { title: "対象1", key: "subject1", width: "7%" },
    { title: "対象2", key: "subject2", width: "7%" },
    { title: "対象3", key: "subject3", width: "7%" },
    {
      title: "作業周期",
      key: "days_of_replacement_period",
      width: "8%",
      align: "end",
    },
    { title: "整備計画日", key: "planed_date", width: "8%" },
    { title: "前回", key: "before_minus_0_date", width: "8%" },
    { title: "前回-1", key: "before_minus_1_date", width: "8%" },
    { title: "前回-2", key: "before_minus_2_date", width: "8%" },
  ];
  const result = isUserAdmin
    ? [{ title: "", key: "data-table-select", width: "2%" }].concat(base)
    : base;
  return result;
});

onMounted(async () => {
  //整備項目編集画面からクエリパラメータが着ていないか確認する
  if (Object.keys(route.query).length) {
    state.snackBarType = "success";
    state.snackBarTitle = "";
    if (route.query["edit"] == "create")
      state.snackBarMessage = "整備項目の追加を登録しました。";
    else if (route.query["edit"] == "update")
      state.snackBarMessage = "整備項目の変更を登録しました。";
    else if (route.query["edit"] == "delete")
      state.snackBarMessage = "整備項目を削除しました。";
    else if (route.query["edit"] == "error") {
      state.snackBarTitle = "整備項目の編集ができませんでした。";
      const msg =
        typeof route.query["message"] == "string"
          ? route.query["message"]
          : "データの更新に関する不具合が発生しています。";
      state.snackBarMessage = msg;
      state.snackBarType = "error";
    } else state.snackBarMessage = "不明なオペレーションです";
    state.popSnackBar = true;
  }

  // AlertSnackBarの表示に使用したQueryStringを削除する
  const query = Object.assign({}, route.query);
  if (query.edit != null || query.message != null) {
    delete query["edit"];
    delete query["message"];
    await router.replace({ query });
  }

  await loadOperationHistory();
});
</script>

<style scoped>
:deep(.operation-history.v-data-table table thead tr th) {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}
:deep(
    .operation-history.v-data-table table thead tr th:nth-last-of-type(-n + 3)
  ) {
  background: #bbbbbb;
}
:deep(
    .operation-history.v-data-table table thead tr th:nth-last-of-type(n + 4)
  ) {
  background: #eeeeee;
}
:deep(.operation-history .v-selection-control) {
  font-size: 1rem;
}
:deep(.operation-history .v-data-table-rows-loading) {
  color: rgba(0, 0, 0, 0.38);
}
:deep(.operation-history .v-data-table-rows-no-data) {
  color: rgba(0, 0, 0, 0.38);
}
.input-shift {
  width: 180px;
}
.maint-item-cell {
  cursor: pointer;
}
.maint-item-row .v-data-table__tr:hover .maint-item-cell span {
  text-decoration: underline;
  color: blue;
}
div.rescheduled-plan-area {
  border-radius: 5px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 16px;
}
i.text-field-icon.v-icon {
  color: #ceced1;
  font-size: 24px;
  cursor: pointer;
}
span.text-field-suffix {
  color: rgba(8, 11, 25, 0.6);
  margin-top: 14px;
  font-size: 16px;
}
div.shift-week-button-area {
  width: 155px;
  margin-bottom: 12px;
}
div.shift-week-button-area button {
  border-color: rgba(8, 11, 25, 0.2);
}
:deep(.datepicker-textfield input[disabled]) {
  cursor: default;
}
span.valid-rescheduled-date {
  color: #0d6a97;
}
span.invalid-rescheduled-date {
  color: #f04438;
}
span.invalid-changed-interval-day {
  color: #f04438;
}
span.valid-changed-interval-day {
  color: #0d6a97;
}
span.valid-edited-maint-item-name {
  color: #2e90fa;
}
p.error-message {
  color: #f04438;
  height: 24px;
  margin-bottom: 0;
}
p#annotation-days-shift {
  padding-left: 30px;
  padding-right: 40px;
  color: #6b6d75;
}
p#annotation-define-day {
  padding-left: 30px;
  color: #6b6d75;
}
:deep(.v-radio label.v-label) {
  color: black;
}
.input-field-search {
  width: 500px;
  max-width: 500px !important;
}
</style>
