import { connectToApi } from "@/helpers/connectToApi";
import { PastTaskCompletionResult } from "@/models/pastTaskCompletionResult";

type requestReportPastTaskCompletionApiResponse = {
  completed_date: string;
  next_maint_task_id: number;
  next_scheduled_date: string;
  next_past_completion_available: boolean;
};

export async function requestReportPastTaskCompletion(
  maintTaskId: number,
): Promise<PastTaskCompletionResult> {
  const response =
    await connectToApi<requestReportPastTaskCompletionApiResponse>({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: "/api/reportPastTaskCompletion",
      data: {
        maint_task_id: maintTaskId,
      },
    }).catch((e) => {
      throw e;
    });

  return {
    success: true,
    completed_date: response.data.completed_date,
    next_maint_task_id: response.data.next_maint_task_id,
    next_scheduled_date: response.data.next_scheduled_date,
    next_past_completion_available:
      response.data.next_past_completion_available,
  };
}
