import {
  msalClient,
  loginRedirect,
  logoutRedirect,
  getTokenSilent,
} from "./aad_b2c";
import { RouteLocationNormalized, RouteLocation } from "vue-router";
import { Authority, Role } from "@/models/authority";
import { useAuthoritiesStore } from "@/stores/authorities";
import { useUserinfoStore } from "@/stores/userinfo";
import axios from "axios";

type ApiResponseGetAuthorities = {
  authorities: {
    plant_id: string;
    role_name: Role;
    plant_name: string;
    corporate_name: string;
  }[];
  userinfo: {
    family_name: string | null;
    given_name: string | null;
    email: string | null;
  };
};

async function isAuthenticated(): Promise<boolean> {
  await msalClient.initialize();
  await msalClient.handleRedirectPromise();

  const accounts = msalClient.getAllAccounts();

  return accounts.length > 0;
}

async function callApiGetAuthorities(): Promise<ApiResponseGetAuthorities> {
  const { accessToken } = await getTokenSilent();

  const resp = await axios<ApiResponseGetAuthorities>({
    method: "GET",
    url: "/api/getAuthorities",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return resp.data;
}

export async function authGuard(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _to: RouteLocationNormalized,
): Promise<boolean | RouteLocation> {
  if (await isAuthenticated()) {
    const storeUserinfo = useUserinfoStore();
    const storeAuthorities = useAuthoritiesStore();

    if (storeUserinfo.isNotInitialized || storeAuthorities.isNotInitialized) {
      const resp = await callApiGetAuthorities();
      if (
        resp.userinfo.family_name == null ||
        resp.userinfo.given_name == null ||
        resp.userinfo.email == null
      ) {
        alert(
          "整備支援システムにユーザーが登録されていません。ログアウトします。",
        );
        await logoutRedirect();
        return false;
      }
      if (resp.authorities.length === 0) {
        alert(
          "整備支援システムにおいてアクセス権が設定されていません。ログアウトします。",
        );
        await logoutRedirect();
        return false;
      }

      if (storeUserinfo.isNotInitialized) {
        storeUserinfo.initialize({
          familyName: resp.userinfo.family_name,
          givenName: resp.userinfo.given_name,
          email: resp.userinfo.email,
        });
      }
      if (storeAuthorities.isNotInitialized) {
        storeAuthorities.initialize(
          resp.authorities.map((x): Authority => {
            return {
              plantId: x.plant_id,
              roleName: x.role_name,
              plantName: x.plant_name,
              corporateName: x.corporate_name,
            };
          }),
        );
      }
    }

    return true;
  }
  await loginRedirect();
  return false;
}
