/**
 * 指定した時間待機する
 * @param sec 待機秒数
 */
export const sleep = (sec: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, sec * 1000);
  });
};
