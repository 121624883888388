import { defineStore } from "pinia";
import { ref, computed, unref } from "vue";
import { MD5 } from "crypto-js";

export const useUserinfoStore = defineStore("userinfo", () => {
  const isNotInitialized = ref(true);
  const familyName = ref("");
  const givenName = ref("");
  const email = ref("");

  const userPicture = computed<string>(() => {
    const defaultImage =
      "https://ssl.gstatic.com/s2/profiles/images/silhouette80.png";
    const valueEmail = unref(email);
    if (valueEmail === "") {
      return defaultImage;
    }
    const digest = MD5(valueEmail).toString();
    return `https://secure.gravatar.com/avatar/${digest}?s=480&r=pg&d=${encodeURIComponent(
      defaultImage,
    )}`;
  });

  function initialize(props: {
    familyName: string;
    givenName: string;
    email: string;
  }) {
    isNotInitialized.value = false;
    familyName.value = props.familyName;
    givenName.value = props.givenName;
    email.value = props.email;
  }

  return {
    isNotInitialized,
    familyName,
    givenName,
    email,
    userPicture,
    initialize,
  };
});
