import { connectToApi } from "@/helpers/connectToApi";
import { PastEventCompletionResult } from "@/models/pastEventCompletionResult";

export async function requestReportPastEventCompletion(
  maintEventId: number,
): Promise<PastEventCompletionResult> {
  await connectToApi({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    url: "/api/reportPastEventCompletion",
    data: {
      maint_event_id: maintEventId,
    },
  }).catch((e) => {
    throw e;
  });

  return {
    success: true,
  };
}
