<template>
  <div class="text-center">
    <v-dialog v-model="innerOpenStatus" width="50%" scrollable>
      <v-card>
        <v-card-title class="text-h5">
          {{ t("releasenote.title") }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-list v-model:opened="openedNote">
            <v-list-group
              v-for="(version, i) in getReleaseNote()"
              :key="i"
              :value="i"
            >
              <template #activator="{ isOpen, props }">
                <v-list-item
                  v-bind="props"
                  color="primary"
                  @click="openedNote = isOpen ? [] : [i]"
                >
                  <v-list-item-title
                    >{{ version.date }} [{{
                      version.version
                    }}]</v-list-item-title
                  >
                </v-list-item>
              </template>
              <v-list-item v-for="(comment, j) in version.comments" :key="j">
                <div style="display: flex; align-items: start">
                  <v-icon color="grey-darken-1" size="large"
                    >mdi-circle-medium</v-icon
                  >
                  <span class="release-note-description">{{ comment }}</span>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="innerOpenStatus = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import ReleaseNoteJa from "@/assets/release_note/ja.json";

type Emits = {
  OpenStatusUpdateRequest: [value: boolean];
};

type Props = {
  releaseNoteOpenStatus: boolean;
};

const prop = defineProps<Props>();

const emit = defineEmits<Emits>();

const { t } = useI18n();
const openedNote = ref<number[]>([0]);

const getReleaseNote = () => {
  // 現状英語切り替え機能はないため日本語のみを返す
  return ReleaseNoteJa;
};

const innerOpenStatus = computed({
  get: () => prop.releaseNoteOpenStatus,
  set: (value: boolean) => emit("OpenStatusUpdateRequest", value),
});
</script>
<style scoped>
.release-note-description {
  font-size: 14px;
  margin-left: 5px;
  padding-top: 4px;
  white-space: pre-wrap;
}
</style>
