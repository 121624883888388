<template>
  <past-maint-event-complete-dialog
    :past-maint-event-complete-dialog-open-status="
      state.pastMaintEventCompleteOpenStatus
    "
    :maint-event="maintEvent"
    @open-status-update-request="pastMaintEventCompleteDialogStatusHandler"
    @snack-bar="snackBarHandler"
    @past-completion-result="pastCompletionResultHandler"
  />
  <div class="text-center">
    <v-dialog v-model="innerOpenStatus" width="80%" scrollable>
      <v-card>
        <v-card-item class="text-h5">
          イベント詳細
          <template #append>
            <v-btn
              icon
              size="small"
              variant="text"
              @click="innerOpenStatus = false"
            >
              <v-icon size="x-large">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-card-item>
        <v-card-text class="text-body-2">
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              イベント名
            </v-col>
            <v-col cols="9" class="text-grey-darken-1">
              {{ maintEvent.name }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              セット・対象
            </v-col>
            <v-col cols="9" class="text-grey-darken-1">
              {{
                [
                  maintEvent.set,
                  maintEvent.subject1,
                  maintEvent.subject2,
                  maintEvent.subject3,
                ].join(" ")
              }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1"> 計画日 </v-col>
            <v-col cols="6" class="text-grey-darken-1">
              {{ maintEvent.scheduledDate.format("YYYY/MM/DD") }}
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <v-btn
                v-if="state.showPastComplete && isUserAdmin"
                variant="outlined"
                size="small"
                @click="openPastCompleteTargetMaintEventDialog"
                >完了にする</v-btn
              >
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1"> 表示色 </v-col>
            <v-col cols="9" class="text-grey-darken-1">
              <div
                class="pa-3 rounded-circle d-inline-block"
                :style="{ 'background-color': maintEvent.displayColorcode }"
              ></div>
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              イベントの説明
            </v-col>
            <v-col
              cols="9"
              class="text-grey-darken-1"
              style="white-space: pre-line; word-break: break-all"
            >
              {{ maintEvent.description }}
            </v-col>
          </v-row>

          <v-divider />

          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              添付された写真
            </v-col>
            <v-col cols="9">
              <v-row v-if="maintEvent.imageUrls.length > 0">
                <v-col v-for="url in maintEvent.imageUrls" :key="url" cols="3">
                  <v-card
                    color="grey-lighten-2"
                    :href="url"
                    target="_blank"
                    class="rounded pa-1"
                    variant="outlined"
                    height="120px"
                    width="120px"
                  >
                    <img
                      :src="url"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                      alt="ユーザーによってアップロードされた画像"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-card
                    class="rounded d-flex align-center"
                    color="grey-lighten-2"
                    variant="outlined"
                    height="120px"
                    width="120px"
                  >
                    <v-card-text class="pa-5 text-center text-grey-darken-1">
                      写真はまだ添付されていません
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider />

          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              添付されたPDFファイル
            </v-col>
            <v-col cols="9">
              <v-row v-if="maintEvent.files.length > 0">
                <v-col class="text-grey-darken-1">PDFファイル</v-col>
                <v-col
                  v-for="file in maintEvent.files"
                  :key="file.url"
                  cols="12"
                  class="mt-n4"
                >
                  <v-icon size="small"> mdi-paperclip </v-icon>
                  <a :href="file.url" target="_blank" class="text-primary">
                    {{ `${file.name}.${file.extension}` }}
                  </a>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="text-grey-darken-1">PDFファイル</v-col>
                <v-col cols="12" class="mt-n4 no-item text-grey-darken-1">
                  PDFファイルはまだ添付されていません
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider />

          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1"> 申送事項 </v-col>
            <v-col
              cols="9"
              class="text-grey-darken-1"
              style="white-space: pre-line; word-break: break-all"
            >
              {{ maintEvent.message }}
            </v-col>
          </v-row>
          <v-divider />
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isViewer"
            color="primary"
            variant="outlined"
            :to="goto_update_event"
            :disabled="state.disableUpdate"
          >
            編集画面へ移動する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from "vue";
import { RouteLocationRaw, useRoute } from "vue-router";
import { MaintEvent } from "@/models/MaintEvent";
import { useAuthoritiesStore } from "@/stores/authorities";
import { AlertSnackBarModel } from "@/models/snackbar";
import PastMaintEventCompleteDialog from "@/components/PastMaintEventCompleteDialog.vue";
import { PastEventCompletionResult } from "@/models/pastEventCompletionResult";

type Emits = {
  OpenStatusUpdateRequest: [value: boolean];
  SnackBar: [snackBar: AlertSnackBarModel];
  NeedRefresh: [needRefresh: boolean];
};

type Props = {
  eventDetailOpenStatus: boolean;
  maintEvent: MaintEvent;
};

type LocationData = {
  path: string;
  query: { [key: string]: string };
};

const prop = defineProps<Props>();

const emit = defineEmits<Emits>();

type State = {
  pastMaintEventCompleteOpenStatus: boolean;
  showPastComplete: boolean;
  disableUpdate: boolean;
};
const state = reactive<State>({
  pastMaintEventCompleteOpenStatus: false,
  showPastComplete: prop.maintEvent.pastCompletionAvailable,
  disableUpdate: prop.maintEvent.isCompleted(),
});
/* 表示する整備項目が別のものになればstateが必ず更新されるよう、maintEventのidをwatch */
watch(
  () => prop.maintEvent.id,
  () => {
    state.showPastComplete = prop.maintEvent.pastCompletionAvailable;
    state.disableUpdate = prop.maintEvent.isCompleted();
  },
);

const storeAuthorities = useAuthoritiesStore();
const isUserAdmin = storeAuthorities.roleChecker.isUserAdmin;
const route = useRoute();

const innerOpenStatus = computed({
  get: () => prop.eventDetailOpenStatus,
  set: (value: boolean) => emit("OpenStatusUpdateRequest", value),
});

const isViewer = storeAuthorities.roleChecker.isViewer;

/**
 * イベント編集画面に遷移するためのRouteLocationRawオブジェクトを生成する (編集後のリダイレクト先を作成している)
 */
const goto_update_event = computed<RouteLocationRaw>(() => {
  // queryは値がないことも許容されているため生のRouteLocationRawを使うと毎回nullチェックが必要になる
  // そのため、pathとqueryを必須にした type LocationData を定義して使用している
  const location: LocationData = {
    path: `/edit-maint-event/${prop.maintEvent.id}`,
    query: {},
  };

  const pathPart = route.path.split("/");

  // 各画面へリダイレクトできるようにQueryStringを設定する
  if (
    route.name === "maint-task-list" ||
    route.name === "maint-task-list-yyyymmdd"
  ) {
    location.query.referer = "maint-task-list";
    if (pathPart.length === 2 && pathPart[1] !== "") {
      location.query.yyyymmdd = pathPart[1];
    }
  } else if (route.name === "calendar" || route.name === "calendar-yyyymm") {
    location.query.referer = "calendar";
    if (pathPart.length === 3 && pathPart[2] !== "") {
      location.query.yyyymm = pathPart[2];
    }
  } else if (route.name === "event-history") {
    location.query.referer = "event-history";
  }

  return location;
});

/**
 * 過去完了対象整備作業を選択
 */
const openPastCompleteTargetMaintEventDialog = () => {
  state.pastMaintEventCompleteOpenStatus = true;
};
/**
 * 過去の整備作業を完了させるダイアログを閉じた後の処理
 */
const pastMaintEventCompleteDialogStatusHandler = (
  status: boolean | undefined,
) => {
  if (status !== undefined) {
    state.pastMaintEventCompleteOpenStatus = status;
  }
};

/**
 * 子ComponentからEmitされたSnackBarを親ComponentにさらにEmit
 */
const snackBarHandler = (snackBar: AlertSnackBarModel | undefined) => {
  if (snackBar !== undefined) {
    emit("SnackBar", snackBar);
  }
};

/**
 * 過去の整備作業を完了させた結果を画面に反映
 */
const pastCompletionResultHandler = (
  pastCompletionResult: PastEventCompletionResult | undefined,
) => {
  if (pastCompletionResult !== undefined) {
    if (pastCompletionResult.success) {
      state.showPastComplete = false; // 過去完了ボタンを非表示
      state.disableUpdate = true; // 編集ボタンをdisable
      // 最新状況を反映させるため、親Componentの一覧表示をリフレッシュさせる
      emit("NeedRefresh", true);
    }
  }
};
</script>
