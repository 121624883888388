<template>
  <v-app>
    <v-app-bar>
      <v-app-bar-title>
        <v-img :src="logoImg" width="150px" height="64px" cover />
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-menu location="bottom">
        <template #activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon> mdi-apps </v-icon>
          </v-btn>
        </template>
        <v-list density="compact" class="text-center">
          <v-list-item>
            <a
              href="https://mhig-ppm-e.cybozu.com/k/#/portal"
              target="_blank"
              rel="noopener noreferrer"
              class="jump-link"
            >
              ポータルサイト
            </a>
          </v-list-item>
          <v-list-item>
            <a
              :href="ePartsUrl()"
              target="_blank"
              rel="noopener noreferrer"
              class="jump-link"
            >
              e-Parts
            </a>
          </v-list-item>
          <v-list-item>
            <a
              href="https://mhig-ppm-e.cybozu.com/k/55/edit"
              target="_blank"
              rel="noopener noreferrer"
              class="jump-link"
            >
              お問い合わせ
            </a>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu location="bottom">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            variant="flat"
            data-dd-action-name="ユーザーアカウント"
          >
            <v-avatar
              :image="storeUserinfo.userPicture"
              class="mx-2"
              size="36"
            />
            {{ storeUserinfo.familyName }}
            {{ storeUserinfo.givenName }}
          </v-btn>
        </template>
        <v-list density="compact" class="text-center">
          <v-list-item>
            <v-btn variant="text" @click="logout">ログアウト</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu location="bottom">
        <template #activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon> mdi-help-circle-outline </v-icon>
          </v-btn>
        </template>
        <v-list density="compact" class="text-center">
          <v-list-item>
            <v-btn variant="text" @click="downloadManual">
              <v-icon start> mdi-book-open-variant </v-icon>
              マニュアル
            </v-btn>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-btn
              variant="text"
              class="text-lowercase"
              @click="openReleaseNoteDialog"
            >
              version {{ version }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <template #extension>
        <v-tabs
          bg-color="blue-grey-darken-1"
          color="white"
          grow
          class="text-grey-lighten-1"
        >
          <v-tab
            to="/"
            text="本日の作業"
            class="flex-grow-0 flex-shrink-1"
          ></v-tab>
          <v-tab
            to="/calendar"
            text="カレンダー"
            class="flex-grow-0 flex-shrink-1"
          ></v-tab>
          <v-tab
            to="/operation-history"
            text="整備項目一覧"
            class="flex-grow-0 flex-shrink-1"
          ></v-tab>
          <v-tab
            to="/event-history"
            text="イベント一覧"
            class="flex-grow-0 flex-shrink-1"
          ></v-tab>
          <v-spacer></v-spacer>
          <v-select
            v-model="state.indexValue"
            class="text-caption text-right ma-1 select-auth"
            density="compact"
            item-title="text"
            item-value="value"
            :items="authorityItems"
            :readonly="authorityItems ? authorityItems.length <= 1 : false"
            variant="outlined"
          >
            <template #selection="{ item }">
              <v-tooltip location="top" :disabled="!isEllipsisActive()">
                <template #activator="{ props }">
                  <span
                    v-bind="props"
                    ref="authorityText"
                    class="text-truncate text-center text-white text-caption"
                  >
                    {{ item.title }}
                  </span>
                </template>
                {{ item.title }}
              </v-tooltip>
            </template>
          </v-select>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <notification
      :notification-open-status="state.notificationOpenStatus"
      @release-note-open-request="releaseNoteOpenRequestHandler"
    />
    <release-note
      :release-note-open-status="state.releaseNoteOpenStatus"
      @open-status-update-request="updateReleaseNoteStatusHandler"
    />
    <alert-snack-bar
      v-model="state.alertSnackBar.isShow"
      :type="state.alertSnackBar.type"
      :title="state.alertSnackBar.title"
      :message="state.alertSnackBar.message"
    />
  </v-app>
</template>

<script setup lang="ts">
import { reactive, computed, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { logoutRedirect } from "@/auth";
import { AlertSnackBarModel } from "./models/snackbar";
import ReleaseNote from "@/components/ReleaseNote.vue";
import Notification from "@/components/Notification.vue";
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import { connectToApi } from "@/helpers/connectToApi";
import { Role } from "@/models/authority";
import { useAuthoritiesStore } from "@/stores/authorities";
import { useUserinfoStore } from "@/stores/userinfo";

import logoImg from "@/assets/logo.svg";

type Authority = {
  corporateName: string;
  plantId: number;
  plantName: string;
  roleName: Role;
  text?: string;
  value?: number;
};

type State = {
  releaseNoteOpenStatus: boolean;
  notificationOpenStatus: boolean;
  indexValue: number;
  alertSnackBar: AlertSnackBarModel;
};

const storeAuthorities = useAuthoritiesStore();
const storeUserinfo = useUserinfoStore();
const router = useRouter();
const { t } = useI18n();

const logout = () => logoutRedirect();

const version = ref<string>(__PACKAGE_VERSION__!);
const authorityText = ref<HTMLElement>();

const state = reactive<State>({
  releaseNoteOpenStatus: false,
  notificationOpenStatus: false,
  indexValue: 0,
  alertSnackBar: {
    isShow: false,
    type: "success",
    title: "",
    message: "",
  },
});

const ePartsUrl = (): string => {
  return import.meta.env.VITE_APP_HOJYU_URL!;
};

const openReleaseNoteDialog = (): void => {
  state.releaseNoteOpenStatus = true;
};

const updateReleaseNoteStatusHandler = (status: boolean | undefined): void => {
  if (status !== undefined) {
    state.releaseNoteOpenStatus = status;
  }
};

const releaseNoteOpenRequestHandler = (): void => {
  state.releaseNoteOpenStatus = true;
};

// 権限セレクトボックスのテキストが省略されているかどうかを判定する
const isEllipsisActive = (): boolean => {
  const element: HTMLElement | undefined = authorityText.value;
  if (element) {
    return element.offsetWidth < element.scrollWidth;
  }
  return false;
};

const authorityItems = computed(() => {
  const items: Array<Authority> = storeAuthorities.authorities.map((x) => {
    return {
      plantId: Number(x.plantId),
      roleName: x.roleName,
      plantName: x.plantName,
      corporateName: x.corporateName,
    };
  });
  if (items) {
    items.forEach((item: Authority, index) => {
      item.text = [
        item.corporateName,
        item.plantName,
        t(`common.role.${item.roleName}`),
      ].join(" ");
      item.value = index;
    });
  }

  return items;
});

/**
 * マニュアルのダウンロード処理
 */
const downloadManual = async (): Promise<void> => {
  try {
    // CloudFrontのpre-signed-urlを取得
    const resp = await connectToApi<{ manual_url: string }>({
      method: "GET",
      url: "/api/generateManualUrl",
    });
    const link = resp.data.manual_url;

    // Anchorタグを作成してクリックイベントを発火させることでマニュアルをダウンロードする
    const anchor: HTMLAnchorElement = document.createElement("a");
    anchor.setAttribute("href", link);
    anchor.setAttribute("target", "_blank");
    anchor.setAttribute("rel", "noopener noreferrer");

    const mouseEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    anchor.dispatchEvent(mouseEvent);
  } catch (e: any) {
    state.alertSnackBar.type = "error";
    state.alertSnackBar.title = "マニュアルのダウンロードに失敗しました";
    state.alertSnackBar.message =
      "サーバ側の処理に失敗しました。少し待ってからもう一度試してください。";
    state.alertSnackBar.isShow = true;
  }
};

watch(
  () => state.indexValue,
  (val: number) => {
    storeAuthorities.setAuthoritiesIndex(val);
    if (router.currentRoute.value.path != "/") router.push("/");
  },
);
</script>
<style scoped>
.notification {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
.select-auth {
  width: 350px;
  max-width: 350px;
}
.jump-link {
  color: inherit;
  text-decoration: none;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
