<template>
  <v-container ref="container">
    <alert-snack-bar
      v-model="state.snackBar.isShow"
      :type="state.snackBar.type"
      :title="state.snackBar.title"
      :message="state.snackBar.message"
    />
    <overlay-loading :is-loading="state.showOverlayLoading" />
    <event-detail
      :maint-event="state.selectedMaintEvent"
      :event-detail-open-status="state.eventDetailOpenStatus"
      @open-status-update-request="updateEventDetailStatusHandler"
      @snack-bar="snackBarHandler"
      @need-refresh="refreshHandler"
    />
    <maint-item-detail
      :maint-item-detail-open-status="state.maintItemDetailOpenStatus"
      :maint-item="state.selectedMaintItem"
      :mhi-parts-search-result="state.mhiPartsSearchResult"
      @open-status-update-request="updateMaintItemDetailStatusHandler"
      @snack-bar="snackBarHandler"
      @need-refresh="refreshHandler"
    />

    <v-row class="ma-0">
      <v-col>
        <h3 class="my-3">カレンダー</h3>
      </v-col>
      <v-btn
        v-if="!state.isViewer"
        class="my-5"
        color="primary"
        :to="create_update_event_location"
      >
        <v-icon start> mdi-plus </v-icon>
        イベントの新規追加
      </v-btn>
    </v-row>

    <div class="content-global">
      <v-sheet color="white" elevation="1" class="pa-3">
        <date-selector
          class="my-3 text-center"
          :date="state.currentDate"
          format-i18-ncode="common.dateformat.month"
          current-name="今月"
          @event-date-change="eventDateChange"
        />

        <v-row dense class="ga-2 d-flex justify-start align-start">
          <v-col>
            <v-select
              v-model="state.selectedSetItems"
              color="primary"
              density="compact"
              label="セット"
              :items="selectItems.setItems"
              :loading="state.selectLoading"
              variant="outlined"
              multiple
              clearable
            />
          </v-col>
          <v-col>
            <v-select
              v-model="state.selectedSubject1Items"
              color="primary"
              density="compact"
              label="対象1"
              :items="selectItems.subject1Items"
              :loading="state.selectLoading"
              variant="outlined"
              multiple
              clearable
            />
          </v-col>
          <v-col>
            <v-select
              v-model="state.selectedSubject2Items"
              color="primary"
              density="compact"
              label="対象2"
              :items="selectItems.subject2Items"
              :loading="state.selectLoading"
              variant="outlined"
              multiple
              clearable
            />
          </v-col>
          <v-col>
            <v-select
              v-model="state.selectedSubject3Items"
              color="primary"
              density="compact"
              label="対象3"
              :items="selectItems.subject3Items"
              :loading="state.selectLoading"
              variant="outlined"
              multiple
              clearable
            />
          </v-col>
          <!-- 幅960px以上はクリアボタン・保存ボタンも含めて一列表示 -->
          <v-col v-if="display.mdAndUp" class="ga-2 d-inline-flex justify-end">
            <v-btn
              variant="outlined"
              class="clear-button"
              @click="clearFilterConditions"
            >
              クリア
            </v-btn>
            <v-btn
              variant="outlined"
              class="save-button"
              color="primary"
              :loading="state.savingFilterConditionLoading"
              @click="saveFilterCondition"
            >
              この条件を保存
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                  >次回以降、このフィルター条件でカレンダーを表示します。</span
                >
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
        <!-- 幅960px未満はクリアボタン・保存ボタンはセット・対象選択インターフェースの下段に表示 -->
        <v-row v-if="!display.mdAndUp" class="mt-0 mb-4">
          <v-col class="ga-2 d-inline-flex justify-end">
            <v-btn
              variant="outlined"
              class="clear-button"
              @click="clearFilterConditions"
            >
              クリア
            </v-btn>
            <v-btn
              variant="outlined"
              class="save-button"
              color="primary"
              :loading="state.savingFilterConditionLoading"
              @click="saveFilterCondition"
            >
              この条件を保存
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon v-bind="props" class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                  >次回以降、このフィルター条件でカレンダーを表示します。</span
                >
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>

        <v-skeleton-loader v-if="state.loading" type="table-tbody" />
        <div v-else class="calendar-size">
          <div class="calendar-weekly">
            <div
              v-for="n in 7"
              :key="n"
              class="calendar-dayOfWeek"
              :class="{
                'calendar-dayOfWeek-sun': n == 1,
                'calendar-dayOfWeek-sat': n == 7,
              }"
            >
              {{ dayOfWeek[n - 1] }}
            </div>
          </div>
          <div
            v-for="(week, index_week) in displayCalendar"
            :key="index_week"
            class="calendar-weekly"
          >
            <div
              v-for="(day, index_day) in week"
              :key="index_day"
              :ref="
                (el) => {
                  if (day.calendarDate.isSame(today, 'day') && !!el) {
                    dateToday = el as HTMLDivElement;
                  }
                }
              "
              class="calendar-daily text-center"
              :class="calcCalenderDailyBorderClass(day.calendarDate)"
              role="presentation"
              @drop="dragEnd($event, day.calendarDate)"
              @dragover.prevent="dragHover($event, day.calendarDate)"
            >
              <v-chip
                size="small"
                variant="flat"
                class="ma-1"
                :class="
                  day.calendarDate.isSame(today, 'day')
                    ? 'text-white' //当日
                    : day.calendarDate.day() == 6
                      ? 'text-blue' //土曜
                      : day.calendarDate.day() == 0
                        ? 'text-red' //日曜
                        : holiday_jp.isHoliday(day.calendarDate.toDate())
                          ? 'text-red'
                          : 'text-black'
                "
                :color="
                  day.calendarDate.isSame(today, 'day')
                    ? 'primary'
                    : 'transparent'
                "
              >
                {{ getCalendarDate(day.calendarDate) }}
              </v-chip>
              <v-sheet
                v-for="maintTask in day.maintTasksInTargetDay"
                :key="maintTask.id"
                class="ma-1 pa-1 text-left border1px"
                :class="taskClass(maintTask)"
                rounded
                :color="maintTask.color_main"
                :draggable="state.hasTaskEditAuthority && !maintTask.done"
                :style="`border-color: ${maintTask.color_sub}`"
                @dragstart="taskDragStart($event, maintTask.id)"
                @click="openMaintItemDetail(maintTask)"
              >
                {{ maintTask.fullName }}
              </v-sheet>
              <v-sheet
                v-for="maintEvent in day.maintEventsInTargetDay"
                :key="maintEvent.id"
                class="ma-1 pa-1 text-left border1px event-cell"
                :class="eventClass(maintEvent)"
                rounded
                :color="maintEvent.statusColor()"
                :draggable="
                  state.hasEventEditAuthority && !maintEvent.isCompleted()
                "
                :style="`border-color: ${maintEvent.displayColorcode}`"
                @dragstart="eventDragStart($event, maintEvent.id)"
                @click="showEventDetail(maintEvent)"
              >
                <p>
                  <v-icon size="small" :color="maintEvent.displayColorcode"
                    >mdi-calendar</v-icon
                  >
                  {{ maintEvent.name }}
                </p>
              </v-sheet>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch, onMounted } from "vue";
import { VContainer } from "vuetify/components";
import { useI18n } from "vue-i18n";
import { AlertSnackBarModel } from "@/models/snackbar";
import DateSelector from "@/components/DateSelector.vue";
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import MaintItemDetail from "@/components/MaintItemDetail.vue";
import EventDetail from "@/components/EventDetail.vue";
import OverlayLoading from "@/components/OverlayLoading.vue";
import dayjs, { Dayjs } from "dayjs";
import { MaintTask, MaintTaskData } from "@/models/MaintTask";
import { connectToApi } from "@/helpers/connectToApi";
import * as holiday_jp from "@holiday-jp/holiday_jp";
import { MaintEvent, MaintEventData } from "@/models/MaintEvent";
import { RouteLocationRaw, useRoute, useRouter } from "vue-router";
import { MaintItem } from "@/models/MaintItem";
import { requestHinmoku } from "@/helpers/api/hojyuHinmoku";
import { requestGetMaintItem } from "@/helpers/api/getMaintItemDetail";
import { MhiPartsSearchResult } from "@/models/mhiPartsSearchResult";
import { useAuthoritiesStore } from "@/stores/authorities";
import { requestGetMaintenanceClassification } from "@/helpers/api/getMaintenanceClassification";
import { requestGetFilterCondition } from "@/helpers/api/getFilterCondition";
import { useDisplay } from "vuetify";
import { requestUpdateMaintEvent } from "@/helpers/api/updateMaintEvent";

type ApiResponse = {
  maintTasks: MaintTaskData[];
  maint_events: MaintEventData[];
};

type ApiDetailResponse = {
  maint_event: MaintEventData;
};

type EditScheduledDateApiResponse = {
  maint_task_id: number;
  scheduled_date: string;
};

type Props = {
  yyyymm?: string;
};

type State = {
  currentDate: Dayjs;

  tasks: MaintTask[];
  events: MaintEvent[];

  mhiPartsSearchResult: MhiPartsSearchResult;

  maintItemDetailOpenStatus: boolean;
  eventDetailOpenStatus: boolean;

  loading: boolean;
  showOverlayLoading: boolean;

  isDragging: boolean;
  dateDragging: Dayjs | null;
  draggedMaintTaskOrMaintEvent: MaintTask | MaintEvent | undefined;

  selectedMaintItem: MaintItem;
  selectedMaintEvent: MaintEvent;

  snackBar: AlertSnackBarModel;

  isViewer: boolean;
  hasTaskEditAuthority: boolean;
  hasEventEditAuthority: boolean;

  selectLoading: boolean;
  selectedSetItems: string[];
  selectedSubject1Items: string[];
  selectedSubject2Items: string[];
  selectedSubject3Items: string[];
  savingFilterConditionLoading: boolean;
};

type StateSelectFilterConditions = {
  setItems: string[];
  subject1Items: string[];
  subject2Items: string[];
  subject3Items: string[];
};

const prop = withDefaults(defineProps<Props>(), {
  yyyymm: undefined,
});

const storeAuthorities = useAuthoritiesStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

// ブレークポイントによる表示制御に利用
// 参考: https://v2.vuetifyjs.com/en/features/breakpoints/
const display = ref(useDisplay());

const dateToday = ref<HTMLDivElement>();
const container = ref<InstanceType<typeof VContainer> | null>();

const requestData = async (yyyymm: string): Promise<ApiResponse> => {
  const response = await connectToApi<ApiResponse>({
    method: "GET",
    url: "/api/getCalendarMaintTasks",
    params: {
      yyyymm: yyyymm,
      plant_id: storeAuthorities.selectedAuthority.plantId,
    },
  }).catch((e) => {
    state.snackBar.type = "error";
    state.snackBar.title = "データの取得に失敗しました。";
    state.snackBar.message =
      e.response !== undefined
        ? e.response.data.message
        : "データの取得に関する不具合が発生しています。";
    state.snackBar.isShow = true;
    throw e;
  });
  return response.data;
};
const requestEventDetail = async (
  maintEventId: number,
): Promise<ApiDetailResponse> => {
  const resp = await connectToApi<ApiDetailResponse>({
    url: "/api/getMaintEvent",
    method: "GET",
    params: {
      maint_event_id: maintEventId,
    },
  });
  return resp.data;
};

const dayOfWeek = [
  t("common.dayOfWeek.sun"),
  t("common.dayOfWeek.mon"),
  t("common.dayOfWeek.tue"),
  t("common.dayOfWeek.wed"),
  t("common.dayOfWeek.thu"),
  t("common.dayOfWeek.fri"),
  t("common.dayOfWeek.sat"),
];

const state = reactive<State>({
  //prop.yyyymmがundefinedなら当月になる
  currentDate: dayjs(prop.yyyymm), //Dayjsクラスでカレンダーの表示月を示す
  tasks: [],
  events: [],
  mhiPartsSearchResult: MhiPartsSearchResult.empty(), // MaintItemDetailを表示する際に用いる
  maintItemDetailOpenStatus: false,
  eventDetailOpenStatus: false,
  loading: true,
  showOverlayLoading: false,
  isDragging: false,
  dateDragging: null,
  draggedMaintTaskOrMaintEvent: undefined,
  selectedMaintItem: MaintItem.dummy(), // MaintItemDetailを表示する際に用いる
  selectedMaintEvent: MaintEvent.dummy(), // EventDetailを表示する際に用いる
  snackBar: {
    isShow: false,
    type: "error",
    title: "データの更新に失敗しました。",
    message: "データの更新に関する不具合が発生しています。",
  },
  isViewer: storeAuthorities.roleChecker.isViewer,
  hasTaskEditAuthority: false,
  hasEventEditAuthority: false,
  selectLoading: false,
  selectedSetItems: [],
  selectedSubject1Items: [],
  selectedSubject2Items: [],
  selectedSubject3Items: [],
  savingFilterConditionLoading: false,
});
/** フィルター条件セレクトボックスの内容 */
const selectItems = reactive<StateSelectFilterConditions>({
  setItems: [],
  subject1Items: [],
  subject2Items: [],
  subject3Items: [],
});

const today = dayjs();

//カレンダーの最初の日を取得
const getStartDate = () => {
  const startDate = state.currentDate.startOf("month");
  const dayOfWeekNum = startDate.day();
  //7日さらに減算することで前月週を一部表示する
  return startDate.subtract(dayOfWeekNum, "day").subtract(7, "day");
};
/**
 * セット、対象1〜3セレクトの内容物を取得する
 */
const getMaintenanceClassification = async () => {
  state.selectLoading = true;
  try {
    const classification = await requestGetMaintenanceClassification(
      storeAuthorities.selectedAuthority.plantId,
    );
    selectItems.setItems = classification.set;
    selectItems.subject1Items = classification.subject1;
    selectItems.subject2Items = classification.subject2;
    selectItems.subject3Items = classification.subject3;
  } catch (e: any) {
    state.snackBar.type = "error";
    state.snackBar.title = "データの取得に失敗しました。";
    state.snackBar.message =
      e.response !== undefined
        ? e.response.data.message
        : "データの取得に関する不具合が発生しています。";
    state.snackBar.isShow = true;
  } finally {
    state.selectLoading = false;
  }
};

//カレンダーの最後の日を取得
const getEndDate = () => {
  const endDate = state.currentDate.endOf("month");
  const dayOfWeekNum = endDate.day();
  //7日さらに加算することで次月週を一部表示する
  return endDate.add(6 - dayOfWeekNum, "day").add(7, "day");
};

//入力した日付の作業およびイベントを配列で取得する
const getMaintTasksAndEventsInTargetDay = (
  date: Dayjs,
): [MaintTask[], MaintEvent[]] => {
  const maintTasksInTargetDay: MaintTask[] = state.tasks.filter(
    (task: MaintTask) =>
      matchesFilterConditions(task) && task.taskScheduledDateObj.isSame(date),
  );
  const maintEventsInTargetDay: MaintEvent[] = state.events.filter(
    (event: MaintEvent) =>
      matchesFilterConditions(event) && event.scheduledDate.isSame(date, "day"),
  );
  return [maintTasksInTargetDay, maintEventsInTargetDay];
};

//イベントを入力したカレンダーを作成する
const displayCalendar = computed(() => {
  const startDate = getStartDate();
  const endDate = getEndDate();
  //縦のセル数
  const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7);

  const calendars = [];
  let calendarDate = getStartDate();

  for (let week = 0; week < weekNumber; week++) {
    const weekRow: {
      calendarDate: any;
      maintTasksInTargetDay: any;
      maintEventsInTargetDay: any;
    }[] = [];
    for (let day = 0; day < 7; day++) {
      const [maintTasksInTargetDay, maintEventsInTargetDay] =
        getMaintTasksAndEventsInTargetDay(calendarDate);
      weekRow.push({
        calendarDate: calendarDate,
        maintTasksInTargetDay,
        maintEventsInTargetDay,
      });
      calendarDate = calendarDate.add(1, "days");
    }
    calendars.push(weekRow);
  }
  return calendars;
});

//作業のドラッグを始めたときにドラッグイベントに作業IDをセットする
const taskDragStart = (event: DragEvent, eventId: number) => {
  if (event !== null && event.dataTransfer !== null) {
    state.snackBar.isShow = false;
    state.isDragging = true;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.setData("maintTaskId", String(eventId));
  }
};

//イベントのドラッグを始めたときにドラッグイベントにmaint_event_idをセットする
const eventDragStart = (event: DragEvent, eventId: number) => {
  if (event !== null && event.dataTransfer !== null) {
    state.snackBar.isShow = false;
    state.isDragging = true;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.setData("maintEventId", String(eventId));
  }
};

//作業ドラッグ完了時、正しい場所にあればその位置に作業を移動する
//APIを呼び出す
const dragEnd = async (event: DragEvent, date: Dayjs) => {
  state.isDragging = false;
  state.dateDragging = null;
  if (date.isBefore(dayjs(), "day")) {
    state.snackBar.type = "error";
    state.snackBar.title = "";
    state.snackBar.message = "本日より過去に移動させることはできません";
    state.snackBar.isShow = true;
    return;
  }
  if (event !== null && event.dataTransfer !== null) {
    // MaintTaskがドロップされた場合の処理
    const maintTaskId: string = event.dataTransfer.getData("maintTaskId");
    const draggedMaintTask: MaintTask | undefined = state.tasks.find(
      (event) => event.id == Number(maintTaskId),
    );

    if (draggedMaintTask !== undefined) {
      //変更前の日付を覚えておく。エラー時に戻す用
      const beforeDragDate: Dayjs = draggedMaintTask.taskScheduledDateObj;
      //APIでの登録前に移動する。使用感向上のため。APIで失敗した場合は上の値でもとに戻す
      draggedMaintTask.setTaskScheduledDate(date);
      state.draggedMaintTaskOrMaintEvent = draggedMaintTask;

      const callEditTaskScheduledDates = (
        maint_task_id: number,
        scheduled_date: string,
      ) =>
        //対象のAPIは複数作業の計画日変更に対応しているため、配列でrequest送信する＆配列のレスポンスをもらう
        connectToApi<EditScheduledDateApiResponse[]>({
          method: "POST",
          url: "/api/editScheduledDates",
          data: [
            {
              maint_task_id: maint_task_id,
              scheduled_date: scheduled_date,
            },
          ],
        });

      try {
        await callEditTaskScheduledDates(
          draggedMaintTask.id,
          date.format("YYYYMMDD"),
        );
      } catch (e: any) {
        state.snackBar.type = "error";
        state.snackBar.title = "データの更新に失敗しました。";
        state.snackBar.message =
          e.response !== undefined
            ? e.response.data.message
            : "データの更新に関する不具合が発生しています。";
        state.snackBar.isShow = true;
        if (draggedMaintTask) {
          draggedMaintTask.setTaskScheduledDate(beforeDragDate);
          state.draggedMaintTaskOrMaintEvent = undefined;
        }
      }
    }

    // MaintEventがドロップされた場合の処理
    const maintEventId: string = event.dataTransfer.getData("maintEventId");
    const draggedMaintEvent: MaintEvent | undefined = state.events.find(
      (event) => event.id == Number(maintEventId),
    );

    if (draggedMaintEvent !== undefined) {
      //変更前の日付を覚えておく。エラー時に戻す用
      const beforeDragDate: Dayjs = draggedMaintEvent.scheduledDate;
      //APIでの登録前に移動する。使用感向上のため。APIで失敗した場合は上の値でもとに戻す
      draggedMaintEvent.setScheduledDate(date);
      state.draggedMaintTaskOrMaintEvent = draggedMaintEvent;

      try {
        await requestUpdateMaintEvent({
          maint_event_id: draggedMaintEvent.id,
          plant_id: parseInt(storeAuthorities.selectedAuthority.plantId, 10),
          event_name: draggedMaintEvent.name,
          set: draggedMaintEvent.set,
          subject1: draggedMaintEvent.subject1,
          subject2: draggedMaintEvent.subject2,
          subject3: draggedMaintEvent.subject3,
          scheduled_date: date,
          display_colorcode: draggedMaintEvent.displayColorcode,
          event_description: draggedMaintEvent.description,
          message: draggedMaintEvent.message,
          files: draggedMaintEvent.files.map((file) => ({
            file_id: file.file_id,
            name: file.name,
            extension: file.extension,
          })),
        });
      } catch (e: any) {
        state.snackBar.type = "error";
        state.snackBar.title = "データの更新に失敗しました。";
        state.snackBar.message =
          e.response !== undefined
            ? e.response.data.message
            : "データの更新に関する不具合が発生しています。";
        state.snackBar.isShow = true;
        if (draggedMaintEvent) {
          draggedMaintEvent.setScheduledDate(beforeDragDate);
          state.draggedMaintTaskOrMaintEvent = undefined;
        }
      }
    }
  }
};

const dragHover = (event: DragEvent, day: Dayjs) => {
  if (event !== null && event.target !== null) {
    state.dateDragging = day;
  }
};

/**
 * 子ComponentからEmitされたSnackBarを表示
 */
const snackBarHandler = (snackBar: AlertSnackBarModel | undefined) => {
  if (snackBar !== undefined) {
    state.snackBar.isShow = snackBar.isShow;
    state.snackBar.type = snackBar.type;
    state.snackBar.title = snackBar.title;
    state.snackBar.message = snackBar.message;
  }
};

/**
 * 子Componentから、リフレッシュが必要というEmitを受けたらリフレッシュ
 */
const refreshHandler = (needRefresh: boolean | undefined) => {
  if (needRefresh !== undefined) {
    if (needRefresh) {
      init(false); // 何か操作を加えた整備項目の画面からずれないようにするため、本日の日付までのスクロールはしない
    }
  }
};

//dateselectorで月をかえたときの動作
const eventDateChange = (val: number | undefined) => {
  if (val) state.currentDate = state.currentDate.add(val, "month");
  else state.currentDate = dayjs();
};

const convertToYyyyMm = (val: Dayjs): string => {
  const year = String(val.year()).padStart(4, "0");
  // Dayjsのmonth()は1月が0, 2月が1, 12月が11であるため、1を加算
  const month = String(val.month() + 1).padStart(2, "0");
  return year + month;
};

const updateUrlMonth = (val: Dayjs) => {
  // 見ている月が変更されたとき、URLの中身を更新する
  const yyyymm = convertToYyyyMm(val);
  router.push({
    path: `/calendar/${yyyymm}`,
  });
};

const getMaintTasksAndMaintEvents = async (date: Dayjs) => {
  state.loading = true;
  const yyyymm = convertToYyyyMm(date);
  const resp = await requestData(yyyymm);
  state.tasks = resp.maintTasks.map((item) => new MaintTask(item));
  state.events = resp.maint_events.map((item) => new MaintEvent(item));
  state.loading = false;
};

/**
 * 対象オブジェクトが検索条件（セット、対象1〜3）の条件と一致するかを判定する
 * @argument item MaintTaskまたはMaintEvent
 * @returns 条件に合致していればtrueを返す
 */
const matchesFilterConditions = (item: MaintTask | MaintEvent) => {
  // セットの条件チェック
  const matchesSetItems = (target: string): boolean =>
    state.selectedSetItems.length == 0 ||
    state.selectedSetItems.includes(target);
  // 対象1の条件チェック
  const matchesSubject1Items = (target: string) =>
    state.selectedSubject1Items.length == 0 ||
    state.selectedSubject1Items.includes(target);
  // 対象2の条件チェック
  const matchesSubject2Items = (target: string) =>
    state.selectedSubject2Items.length == 0 ||
    state.selectedSubject2Items.includes(target);
  // 対象3の条件チェック
  const matchesSubject3Items = (target: string) =>
    state.selectedSubject3Items.length == 0 ||
    state.selectedSubject3Items.includes(target);

  return (
    matchesSetItems(item.set) &&
    matchesSubject1Items(item.subject1) &&
    matchesSubject2Items(item.subject2) &&
    matchesSubject3Items(item.subject3)
  );
};

/**
 * クリアボタン押下時の処理
 */
const clearFilterConditions = () => {
  state.selectedSetItems = [];
  state.selectedSubject1Items = [];
  state.selectedSubject2Items = [];
  state.selectedSubject3Items = [];
};

/**
 * この条件を保存ボタン押下時の処理
 */
const saveFilterCondition = async () => {
  type UpdateFilterConditionApiResponse = {
    message: string;
  };

  state.savingFilterConditionLoading = true;
  try {
    const response = await connectToApi<UpdateFilterConditionApiResponse>({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/updateFilterCondition",
      data: {
        plant_id: storeAuthorities.selectedAuthority.plantId,
        set: state.selectedSetItems,
        subject1: state.selectedSubject1Items,
        subject2: state.selectedSubject2Items,
        subject3: state.selectedSubject3Items,
      },
    });
    if (response) {
      state.snackBar.type = "success";
      state.snackBar.title = "";
      state.snackBar.message = "フィルター条件の保存が完了しました。";
      state.snackBar.isShow = true;
    }
  } catch (e: any) {
    state.snackBar.type = "error";
    state.snackBar.title = "データの更新に失敗しました。";
    state.snackBar.message =
      e.response !== undefined
        ? e.response.data.message
        : "データの更新に関する不具合が発生しています。";
    state.snackBar.isShow = true;
  } finally {
    state.savingFilterConditionLoading = false;
  }
};

const getCalendarDate = (calendarDate: Dayjs) => {
  if (calendarDate.date() == 1) return calendarDate.format("M/D");
  else return calendarDate.format("D");
};

const taskClass = (task: MaintTask) => {
  return {
    "dragged-maint-task": state.draggedMaintTaskOrMaintEvent === task,
    "task-pointer-can-move":
      state.hasTaskEditAuthority &&
      task.taskCompletedDateObj == null &&
      !state.isDragging,
    "task-pointer-moving": state.hasTaskEditAuthority && state.isDragging,
  };
};

const eventClass = (event: MaintEvent) => {
  return {
    "dragged-maint-event": state.draggedMaintTaskOrMaintEvent === event,
    "event-pointer-can-move":
      state.hasEventEditAuthority &&
      event.completedDate == null &&
      !state.isDragging,
    "event-pointer-moving": state.hasEventEditAuthority && state.isDragging,
  };
};

/**
 * カレンダーの日付の枠線に適用するクラスを算出する
 */
const calcCalenderDailyBorderClass = (
  day: Dayjs,
): { [key: string]: boolean } => {
  return {
    outside: state.currentDate.month() !== day.month(),
    hover:
      state.isDragging &&
      state.dateDragging != null &&
      state.dateDragging.isSame(day, "day"),
  };
};

/**
 * 整備項目詳細モーダルを開く
 */
const openMaintItemDetail = async (maintTask: any) => {
  try {
    state.showOverlayLoading = true;
    const maintItem = await requestGetMaintItem(
      Number(maintTask.maint_item_id),
    );
    const hinmokuNos = maintItem.hinmokusMhi.map(
      (hinmoku) => hinmoku.hinmoku_no,
    );
    state.mhiPartsSearchResult = await requestHinmoku(hinmokuNos);
    state.selectedMaintItem = maintItem;
    state.maintItemDetailOpenStatus = true;
  } catch (e: any) {
    state.snackBar.message =
      e?.response?.data?.message || "整備項目詳細の取得に失敗しました。";
    state.snackBar.type = "error";
    state.snackBar.title = "データの取得に失敗しました。";
    state.snackBar.isShow = true;
  } finally {
    state.showOverlayLoading = false;
  }
};

/**
 * 整備項目詳細表示のコンポーネント内におけるOpenStatusが変更されたときのイベントハンドラ
 */
const updateMaintItemDetailStatusHandler = (
  status: boolean | undefined,
): void => {
  if (status !== undefined) {
    state.maintItemDetailOpenStatus = status;
  }
};

/**
 * イベント詳細表示のコンポーネント内におけるOpenStatusが変更されたときのイベントハンドラ
 */
const updateEventDetailStatusHandler = (status: boolean | undefined): void => {
  if (status !== undefined) {
    state.eventDetailOpenStatus = status;
  }
};

const init = async (todayScroll: boolean) => {
  state.hasTaskEditAuthority = storeAuthorities.roleChecker.isUserAdmin;
  state.hasEventEditAuthority =
    storeAuthorities.roleChecker.isUserAdmin ||
    storeAuthorities.roleChecker.isOperator;
  // セット、対象1〜3 セレクトボックスの選択肢をセット
  getMaintenanceClassification();
  // フィルター条件を取得する
  await getFilterCondition();

  await getMaintTasksAndMaintEvents(state.currentDate);

  // 本日の日付のカレンダー位置までスクロール
  if (!todayScroll) return;
  if (dateToday.value) {
    if (container.value) {
      const targetY = dateToday.value.getBoundingClientRect().top;
      const offsetY = container.value.$el.getBoundingClientRect().top;
      window.scrollTo({
        top: targetY - offsetY,
        behavior: "smooth",
      });
    }
  }
};

/**
 * getFilterCondition APIを実行し、セット、対象1〜3 セレクトボックスの初期値を取得する
 */
const getFilterCondition = async () => {
  try {
    const filterCondition = await requestGetFilterCondition(
      storeAuthorities.selectedAuthority.plantId,
    );
    state.selectedSetItems = filterCondition.set;
    state.selectedSubject1Items = filterCondition.subject1;
    state.selectedSubject2Items = filterCondition.subject2;
    state.selectedSubject3Items = filterCondition.subject3;
  } catch (e: any) {
    state.snackBar.type = "error";
    state.snackBar.title = "データの取得に失敗しました。";
    state.snackBar.message =
      e.response !== undefined
        ? e.response.data.message
        : "データの取得に関する不具合が発生しています。";
    state.snackBar.isShow = true;
  } finally {
    state.selectLoading = false;
  }
};

/**
 * イベント作成画面へ遷移するためのRouteLocationRawを生成する
 */
const create_update_event_location = computed<RouteLocationRaw>(() => {
  return {
    path: "/edit-maint-event",
    query: {
      referer: "calendar",
      yyyymm: state.currentDate.format("YYYYMM"),
    },
  };
});

const showEventDetail = async (maintEvent: MaintEvent) => {
  try {
    state.showOverlayLoading = true;
    const resp = await requestEventDetail(maintEvent.id);
    state.selectedMaintEvent = new MaintEvent(resp.maint_event);
    state.eventDetailOpenStatus = true;
  } catch (e: any) {
    state.snackBar.message =
      e?.response?.data?.message || "イベント詳細の取得に失敗しました。";
    state.snackBar.type = "error";
    state.snackBar.title = "データの取得に失敗しました。";
    state.snackBar.isShow = true;
  } finally {
    state.showOverlayLoading = false;
  }
};

// currentDateを監視して、見ている月が変更されたことを検知する
watch(
  () => state.currentDate,
  (val: Dayjs, valPrev: Dayjs) => {
    if (!val.isSame(valPrev, "month")) {
      updateUrlMonth(val);
      getMaintTasksAndMaintEvents(val);
    }
  },
);

onMounted(async () => {
  // 新規イベント追加画面からクエリパラメータが着ていないか確認する
  if (Object.keys(route.query).length) {
    const query = Object.assign({}, route.query);
    // リダイレクト元の判別用
    const editTarget = query?.edit_target;

    state.snackBar.type = "success";
    state.snackBar.title = "";
    if (query["edit"] == "create") {
      state.snackBar.message = "新規イベントを登録しました。";
    } else if (query["edit"] == "error") {
      state.snackBar.title = "新規イベントの登録時に不具合が発生しました。";
      // イベント編集画面でイベント情報取得にしっぱいした場合にも`edit=error`を使用したいので任意のtitleを設定できるように変更
      if (typeof query.title === "string") {
        state.snackBar.title = query.title;
      }
      const msg =
        typeof query["message"] == "string"
          ? query["message"]
          : "データの更新に関する不具合が発生しています。";
      state.snackBar.message = msg;
      state.snackBar.type = "error";
    } else if (query["edit"] === "update" || query["edit"] === "delete") {
      if (editTarget === "maintItem") {
        if (query["edit"] === "update") {
          state.snackBar.message = "整備項目を更新しました。";
        } else if (query["edit"] === "delete") {
          state.snackBar.message = "整備項目を削除しました。";
        }
      } else if (editTarget === "event") {
        if (query["edit"] === "update") {
          state.snackBar.message = "イベントを更新しました。";
        } else if (query["edit"] === "delete") {
          state.snackBar.message = "イベントを削除しました。";
        }
      }
    } else {
      state.snackBar.message = "不明なオペレーションです";
    }
    state.snackBar.isShow = true;

    // AlertSnackBarの表示に使用したQueryStringを削除する
    if (query?.edit != null || query?.message != null || query?.title != null) {
      delete query["edit"];
      delete query["message"];
      delete query["title"];
      await router.replace({
        query: query,
      });
    }
  }
});

init(true);
</script>
<style lang="scss" scoped>
.calendar-size {
  border-top: 1px solid #e0e0e0;
  font-size: 0.8em;
}
.calendar-weekly {
  display: flex;
  border-left: 1px solid #e0e0e0;
}
.calendar-daily {
  flex: 1;
  min-height: 125px;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  margin-right: -1px;
}
.calendar-dayOfWeek {
  flex: 1;
  border-right: 1px solid #e0e0e0;
  margin-right: -1px;
  text-align: center;
}
.calendar-dayOfWeek-sun {
  color: #f44336;
}
.calendar-dayOfWeek-sat {
  color: #2196f3;
}
.outside {
  background-color: #f7f7f7;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
.border1px {
  border: 1px solid;
}
.dragged-maint-task,
.dragged-maint-event {
  box-shadow: 0 0 0 4px rgba(0, 126, 168, 0.32) !important;
}

.task-pointer-can-move,
.event-pointer-can-move {
  cursor: grab;
}

.task-pointer-moving,
.event-pointer-moving {
  cursor: grabbing;
}
.hover {
  box-shadow: 0 0 0 4px rgba(0, 126, 168, 0.32) inset;
}
div.event-cell p {
  margin-bottom: 0;
}
.clear-button,
.save-button {
  height: 40px;
}
</style>
