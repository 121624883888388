import { connectToApi } from "@/helpers/connectToApi";

type FilterCondition = {
  set: string[];
  subject1: string[];
  subject2: string[];
  subject3: string[];
};

type GetFilterConditionApiResponse = {
  plant_id: number;
  set: string[];
  subject1: string[];
  subject2: string[];
  subject3: string[];
};

/**
 * MaintenanceClassificationの初期選択値を取得する
 * @param plantId プラントID
 * @returns 初期選択値として保存されているセット、対象1〜3
 */
export async function requestGetFilterCondition(
  plantId: string,
): Promise<FilterCondition> {
  const response = await connectToApi<GetFilterConditionApiResponse>({
    method: "GET",
    url: "/api/getFilterCondition",
    params: {
      plant_id: plantId,
    },
  });
  return {
    set: Array.from(response.data.set).sort(),
    subject1: Array.from(response.data.subject1).sort(),
    subject2: Array.from(response.data.subject2).sort(),
    subject3: Array.from(response.data.subject3).sort(),
  };
}
