import dayjs, { Dayjs } from "dayjs";

// maint_image_id=42, image_id=01GGFC2T5ZQA3WJ631750G9VC5 のとき、
// 4715622 byteより大きいとCloudFrontがPost Requestを受け付けてくれなかった。
// 4715622 / 1024 / 1024 = 4.50 であったため、きりのよいの4MBを上限とした。
/**
 * 画像の最大サイズ (byte)
 */
export const maxImageSize = 4194304; // 4 * 1024 * 1024

/**
 * ファイルの最大サイズ (byte)
 */
export const maxFileSize = 4194304; // 4 * 1024 * 1024

/**
 * 画像で許容されているmime-type
 */
export const acceptImageMimeTypes = ["image/png", "image/jpeg"];

/**
 * ファイルで許容されているmime-type
 */
export const acceptFileMimeTypes = ["application/pdf"];

/**
 * マニュアルリンク
 */
export type ManualLink = {
  title: string;
  url: string;
};

/**
 * 整備詳細画像
 */
export type Image = {
  image_id: string;
  name: string;
  extension: string;
  url: string;
};

/**
 * 整備詳細マニュアルPDF
 */
export type ManualFile = {
  file_id: string;
  name: string;
  extension: string;
  url: string;
};

/**
 * 部品情報
 */
export type Part = {
  hinmoku_no: string;
  name: string;
  quantity: number | null;
};

export type MaintItemData = {
  /**
   * 整備項目ID
   */
  maint_item_id: number;
  /**
   * 整備作業ID
   */
  maint_task_id: number;
  /**
   * プラントID
   */
  plant_id: number;
  /**
   * アイテムＮｏ．
   */
  item_no: number;
  /**
   * オーダー
   */
  order: number;
  /**
   * 整備項目名
   */
  maint_item_name: string;
  /**
   * セット
   */
  set: string;
  /**
   * 対象1
   */
  subject1: string;
  /**
   * 対象2
   */
  subject2: string;
  /**
   * 対象3
   */
  subject3: string;
  /**
   * 作業周期
   */
  days_of_maint_interval: number;
  /**
   * 整備計画日
   */
  scheduled_date: string;
  /**
   * 整備作業の説明
   */
  maint_item_description: string;
  /**
   * 人数
   */
  man_in_man_hours: number | null;
  /**
   * 時間
   */
  hour_in_man_hours: number | null;
  /**
   * 完了作業を含んでいるかのフラグ
   */
  has_today_completed_task: boolean;
  /**
   * 過去の作業として完了可能か
   */
  readonly past_completion_available: boolean;
  /**
   * マニュアルリンクの配列
   */
  manual_links: ManualLink[] | null;
  /**
   * 整備詳細画像の配列
   */
  images: Image[];
  /**
   * 整備詳細マニュアルPDFの配列
   */
  manual_files: ManualFile[];
  /**
   * 整備部品（MHI）の配列
   */
  hinmokus_mhi: Part[];
  /**
   * 整備部品（その他）の配列
   */
  hinmokus_others: Part[];
};

export class MaintItem {
  maintItemId: number;
  maintTaskId: number;
  plantId: number;
  itemNo: number;
  order: number;
  maintItemName: string;
  set: string;
  subject1: string;
  subject2: string;
  subject3: string;
  daysOfMaintInterval: number;
  scheduledDate: Dayjs;
  description: string;
  manInManHours: number | null;
  hourInManHours: number | null;
  hasTodayCompletedTask: boolean;
  readonly pastCompletionAvailable: boolean;
  manualLinks: ManualLink[];
  images: Image[];
  manualFiles: ManualFile[];
  hinmokusMhi: Part[];
  hinmokusOthers: Part[];

  constructor(maintItemData: MaintItemData) {
    this.maintItemId = maintItemData.maint_item_id;
    this.maintTaskId = maintItemData.maint_task_id;
    this.plantId = maintItemData.plant_id;
    this.itemNo = maintItemData.item_no;
    this.order = maintItemData.order;
    this.maintItemName = maintItemData.maint_item_name;
    this.set = maintItemData.set;
    this.subject1 = maintItemData.subject1;
    this.subject2 = maintItemData.subject2;
    this.subject3 = maintItemData.subject3;
    this.daysOfMaintInterval = maintItemData.days_of_maint_interval;
    this.scheduledDate = dayjs(maintItemData.scheduled_date);
    this.description = maintItemData.maint_item_description;
    this.manInManHours = maintItemData.man_in_man_hours;
    this.hourInManHours = maintItemData.hour_in_man_hours;
    this.hasTodayCompletedTask = maintItemData.has_today_completed_task;
    this.pastCompletionAvailable = maintItemData.past_completion_available;
    this.manualLinks = maintItemData.manual_links ?? [];
    this.images = maintItemData.images;
    this.manualFiles = maintItemData.manual_files;
    this.hinmokusMhi = maintItemData.hinmokus_mhi ?? [];
    this.hinmokusOthers = maintItemData.hinmokus_others ?? [];
  }

  /**
   * ダミーデータを生成する。コンポーネントに対して暫定的に適当なMaintItemデータを渡したいときに使用する。
   * @returns ダミーデータ
   */
  static dummy(): MaintItem {
    return new MaintItem({
      maint_item_id: -1,
      maint_task_id: -1,
      plant_id: -1,
      item_no: -1,
      order: -1,
      maint_item_name: "",
      set: "",
      subject1: "",
      subject2: "",
      subject3: "",
      days_of_maint_interval: -1,
      scheduled_date: "20200101",
      maint_item_description: "",
      man_in_man_hours: null,
      hour_in_man_hours: null,
      has_today_completed_task: false,
      past_completion_available: false,
      manual_links: [],
      images: [],
      manual_files: [],
      hinmokus_mhi: [],
      hinmokus_others: [],
    });
  }

  public setScheduledDate(scheduledDate: Dayjs) {
    this.scheduledDate = scheduledDate;
  }
}
