<template>
  <div class="notification">
    <v-alert
      :model-value="innerOpenStatus"
      type="info"
      closable
      density="compact"
      prominent
      style="
        top: 85%;
        left: 25%;
        position: fixed;
        z-index: 10;
        margin: auto;
        width: 50%;
      "
    >
      <v-row align="center">
        <v-col>
          <span class="release-note-description">{{
            t("others.notification.message")
          }}</span>
        </v-col>
        <v-col cols="auto">
          <v-btn variant="outlined" @click="releaseNoteOpenRequest"
            ><v-icon size="x-large">mdi-script-text-outline</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script lang="ts">
export default {
  name: "NotifiCation",
};
</script>
<script setup lang="ts">
import { computed, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthoritiesStore } from "@/stores/authorities";

type Emits = {
  ReleaseNoteOpenRequest: [];
};

type Props = {
  notificationOpenStatus: boolean;
};

defineProps<Props>();

const emit = defineEmits<Emits>();

const storeAuthorities = useAuthoritiesStore();
const { t } = useI18n();

// 見ることができるプラントが1つ以上ある場合はログイン済みとする (初期値では0)
const isLoggedIn = computed(() => storeAuthorities.authorities.length > 0);

const releaseNoteOpenRequest = () => {
  emit("ReleaseNoteOpenRequest");
};

const innerOpenStatus = computed(
  (): boolean =>
    localStorage.getItem("e-maint_api_version") !== __PACKAGE_VERSION__ &&
    isLoggedIn.value,
);

// 通知画面表示判断後にローカルストレージにバージョンを保存する
onUpdated(() => {
  if (isLoggedIn.value) {
    localStorage.setItem("e-maint_api_version", __PACKAGE_VERSION__!);
  }
});
</script>
<style scoped>
.notification .v-alert__content a {
  color: #ffffff;
}
@media screen and (max-width: 450px) {
  .notification .v-alert {
    left: 0 !important;
    width: 100% !important;
    font-size: 12px;
  }
}
.release-note-description {
  white-space: pre-wrap;
}
</style>
