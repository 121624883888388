<template>
  <v-row>
    <v-dialog v-model="state.manualLinkModal.isShow" width="60%">
      <v-card>
        <v-card-item class="text-h6 py-3">
          URLリンクを追加する
          <template #append>
            <v-btn
              icon
              size="small"
              variant="text"
              @click="state.manualLinkModal.isShow = false"
            >
              <v-icon size="x-large">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-card-item>
        <v-divider />
        <v-card-text class="pa-6">
          <v-form
            ref="refFormManualLinkModal"
            v-model="state.manualLinkModal.formState"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="state.manualLinkModal.title"
                  color="primary"
                  density="compact"
                  label="表示テキスト"
                  placeholder="表示されるテキストを入力"
                  variant="outlined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="state.manualLinkModal.url"
                  color="primary"
                  density="compact"
                  label="URL"
                  placeholder="https://"
                  :rules="letters1"
                  variant="outlined"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer />
          <v-btn
            variant="outlined"
            @click="state.manualLinkModal.isShow = false"
            >キャンセル</v-btn
          >
          <v-btn
            variant="flat"
            :color="state.manualLinkModal.formState ? 'primary' : undefined"
            :disabled="!state.manualLinkModal.formState"
            @click.stop="addManualLink"
            >リンクを追加</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-dialog
        v-model="innerOpenStatus"
        width="1000"
        persistent
        :scrollable="!!state.targetMaintItemId"
      >
        <v-card>
          <v-card-item class="text-h5 mb-5">
            整備内容の一括変更
            <template #append>
              <v-btn
                icon
                size="small"
                variant="text"
                @click="innerOpenStatus = false"
              >
                <v-icon size="x-large">mdi-close</v-icon>
              </v-btn>
            </template>
          </v-card-item>
          <v-card-text class="mt-4 text-body-2">
            <div class="select-area">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col xl="12" cols="12">
                      <h5
                        class="mt-4 mb-1 ml-6 select-area-title text-grey-darken-1"
                      >
                        整備項目の選択
                      </h5>
                      <v-select
                        v-model="state.targetMaintItemId"
                        color="primary"
                        density="compact"
                        :items="state.displayedMaintItemList"
                        item-title="displayName"
                        item-value="maintItemId"
                        label="編集作業のもとになる整備項目を選択"
                        variant="outlined"
                        class="ml-6 select-area-select"
                        bg-color="white"
                        @update:model-value="getTargetMaintItem"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-divider
              v-if="state.targetMaintItemId != null"
              class="mt-9 mb-7"
            />
            <v-row v-if="state.targetMaintItemId != null">
              <v-col cols="12">
                <v-form ref="form" v-model="state.formState">
                  <v-row>
                    <v-col xl="12" cols="12">
                      <h3 class="mb-5 text-grey-darken-1">整備項目名</h3>
                      <v-text-field
                        v-model="state.maintItem.maint_item_name"
                        color="primary"
                        density="compact"
                        persistent-counter
                        :rules="rule22"
                        :loading="state.loading"
                        label="整備項目名"
                        variant="outlined"
                        class="input-field-22c"
                        counter="22"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-7 mt-2" />
                  <v-row>
                    <v-col>
                      <h4 class="mb-5 text-grey-darken-1">整備部品</h4>
                      <h5 class="text-grey-darken-1">
                        MHIから購入する部品 (最大10個)<v-tooltip
                          location="bottom"
                        >
                          <template #activator="{ props }">
                            <v-icon
                              v-bind="props"
                              class="ml-1 mb-1"
                              size="small"
                              >mdi-help-circle-outline</v-icon
                            >
                          </template>
                          <span
                            >品目番号がe-Partsの品目番号と一致する場合、閲覧時に自動的にリンクとして表示され、<br />e-Partsの品目詳細ページにアクセスをできるようになります。</span
                          >
                        </v-tooltip>
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(hinmoku, index) in state.maintItem.hinmokus_mhi"
                    :key="`hinmoku-mhi-${index}`"
                    class="mb-n6"
                  >
                    <v-col cols="3">
                      <v-text-field
                        v-model="hinmoku.hinmoku_no"
                        color="primary"
                        label="品目番号"
                        variant="outlined"
                        density="compact"
                        hint="半角英数字、半角記号、半角カナのみ入力可能です"
                        persistent-counter
                        type="text"
                        counter="15"
                        maxlength="15"
                        :rules="ruleHinmokuNo"
                        :loading="state.loading"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="hinmoku.name"
                        color="primary"
                        label="名称など"
                        variant="outlined"
                        density="compact"
                        persistent-counter
                        type="text"
                        counter="100"
                        :rules="ruleHinmokuName"
                        :loading="state.loading"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="hinmoku.quantity"
                        color="primary"
                        label="個数"
                        variant="outlined"
                        density="compact"
                        hint="0~999の整数のみ入力可能です"
                        type="text"
                        maxlength="3"
                        :rules="ruleHinmokuQuantity"
                        :loading="state.loading"
                      ></v-text-field>
                    </v-col>
                    <v-btn
                      class="mt-4"
                      icon
                      size="x-small"
                      variant="tonal"
                      @click="deleteHinmoku(index)"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </v-row>
                  <v-row class="mt-3 mb-2">
                    <v-col>
                      <v-btn
                        variant="outlined"
                        :disabled="state.maintItem.hinmokus_mhi.length >= 10"
                        @click="addInputHinmokuFrom()"
                      >
                        <v-icon class="my-2" alt="search icon" size="large"
                          >mdi-plus</v-icon
                        >
                        整備部品を追加</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h5 class="text-grey-darken-1">
                        その他部品 (MHI以外・最大10個)
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(hinmoku, index) in state.maintItem.hinmokus_others"
                    :key="`hinmoku-others-${index}`"
                    class="mb-n6"
                  >
                    <v-col cols="3">
                      <v-text-field
                        v-model="hinmoku.hinmoku_no"
                        color="primary"
                        label="品目番号"
                        variant="outlined"
                        density="compact"
                        persistent-counter
                        type="text"
                        counter="50"
                        maxlength="50"
                        :rules="ruleHinmokuOtherNo"
                        :loading="state.loading"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="hinmoku.name"
                        color="primary"
                        label="名称など"
                        variant="outlined"
                        density="compact"
                        persistent-counter
                        type="text"
                        counter="100"
                        :rules="ruleHinmokuName"
                        :loading="state.loading"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="hinmoku.quantity"
                        color="primary"
                        label="個数"
                        variant="outlined"
                        density="compact"
                        hint="0~999の整数のみ入力可能です"
                        type="text"
                        maxlength="3"
                        :rules="ruleHinmokuQuantity"
                        :loading="state.loading"
                      ></v-text-field>
                    </v-col>
                    <v-btn
                      class="mt-4"
                      icon
                      size="x-small"
                      variant="tonal"
                      @click="deleteHinmokuOther(index)"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </v-row>
                  <v-row class="mt-3 mb-2">
                    <v-col>
                      <v-btn
                        variant="outlined"
                        :disabled="state.maintItem.hinmokus_others.length >= 10"
                        @click="addInputHinmokuOtherFrom()"
                      >
                        <v-icon class="my-2" alt="search icon" size="large"
                          >mdi-plus</v-icon
                        >
                        整備部品を追加</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-divider class="mb-7" />
                  <v-row>
                    <v-col xl="12" cols="12">
                      <h3 class="mb-5 text-grey-darken-1">
                        整備作業の説明 (200文字まで)
                      </h3>
                      <v-textarea
                        v-model="state.maintItem.maint_item_description"
                        color="primary"
                        persistent-counter
                        variant="outlined"
                        :rules="letters0_200"
                        :loading="state.loading"
                        label="テキストの入力"
                        counter="200"
                      /> </v-col
                  ></v-row>
                  <v-divider class="mb-7 mt-2" />
                  <v-row>
                    <v-col cols="12">
                      <h3 class="mb-5 text-grey-darken-1">
                        整備詳細画像 (最大3つ)
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="image in state.maintItem.images"
                      :key="image.image_id"
                    >
                      <img
                        :src="image.url"
                        :alt="image.name"
                        style="max-width: 300px; border: solid 1px black"
                      />
                      <v-btn
                        class="mx-2"
                        icon
                        size="x-small"
                        variant="tonal"
                        :loading="state.loading"
                        @click="delete_image(image)"
                        ><v-icon>mdi-minus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        block
                        variant="outlined"
                        class="img_area"
                        :loading="state.loading"
                        :disabled="state.maintItem.images.length >= 3"
                        @click="openInput"
                      >
                        <v-icon class="my-2" alt="search icon" size="x-large"
                          >mdi-plus</v-icon
                        >
                      </v-btn>
                      <input
                        id="img"
                        type="file"
                        accept="image/jpeg, image/png"
                        style="display: none"
                        multiple
                        @change="loadImages"
                      />
                    </v-col>
                  </v-row>
                  <v-divider class="mb-7 mt-7" />
                  <v-row>
                    <v-col cols="12">
                      <h3 class="mb-5 text-grey-darken-1">
                        整備詳細マニュアル
                      </h3>
                      <h4 class="mb-5 text-grey-darken-1">
                        PDFファイル (最大3つ)
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="manualFile in state.maintItem.manual_files"
                      :key="manualFile.file_id"
                      cols="12"
                      class="mt-n4"
                    >
                      <v-icon size="small"> mdi-paperclip </v-icon>
                      <a class="upload-file-name text-primary">
                        {{ `${manualFile.name}.${manualFile.extension}` }}
                      </a>
                      <v-btn
                        class="mx-2 delete-button"
                        icon
                        size="x-small"
                        variant="tonal"
                        @click="delete_manual_file(manualFile)"
                      >
                        <v-icon size="x-small"> mdi-minus </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        block
                        variant="outlined"
                        class="img_area"
                        :loading="state.loading"
                        :disabled="state.maintItem.manual_files.length >= 3"
                        @click="openInputManualFile"
                      >
                        <v-icon class="my-2" alt="search icon" size="x-large"
                          >mdi-plus</v-icon
                        >
                      </v-btn>
                      <input
                        id="a"
                        type="file"
                        accept="application/pdf"
                        style="display: none"
                        multiple
                        @change="loadManualFiles"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h4 class="mb-5 text-grey-darken-1">リンク (最大3つ)</h4>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(link, index) in state.maintItem.manual_links"
                    :key="`${index}-${link.url}`"
                  >
                    <v-col class="mt-n4">
                      <a
                        :href="link.url"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-primary"
                        >{{ link.title }}</a
                      >
                      <v-btn
                        class="mx-2 delete-button"
                        icon
                        size="x-small"
                        variant="tonal"
                        @click="deleteManualLink(index)"
                      >
                        <v-icon size="x-small">mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        variant="outlined"
                        :disabled="isDisabledToAddManualLink"
                        @click.stop="openManualLinkModal"
                      >
                        <v-icon class="my-2" alt="search icon">mdi-plus</v-icon>
                        URLリンクを追加</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-divider class="mb-7 mt-4"></v-divider>
                  <h3 class="mb-5 text-grey-darken-1">工数</h3>
                  <v-row>
                    <v-col cols="12" class="d-inline-flex justify-start">
                      <v-text-field
                        v-model="state.maintItem.man_in_man_hours"
                        color="primary"
                        density="compact"
                        label="人数（人）"
                        hint="空欄もしくは1~999の整数のみ入力可能です"
                        type="text"
                        :rules="ruleMan"
                        :loading="state.loading"
                        variant="outlined"
                        class="input-field-3c mr-5"
                      ></v-text-field>
                      <v-text-field
                        v-model="state.maintItem.hour_in_man_hours"
                        color="primary"
                        density="compact"
                        label="時間（h）"
                        hint="空欄もしくは0より大きく999未満の数値(小数は小数点以下2桁まで)のみ入力可能です"
                        type="text"
                        :rules="ruleHour"
                        :loading="state.loading"
                        variant="outlined"
                        class="input-field-3c mr-5"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn variant="outlined" @click="innerOpenStatus = false"
              >キャンセル</v-btn
            >
            <v-btn
              :color="
                !isDisableApplyButtonBulkEditMaintItems ? 'primary' : undefined
              "
              variant="flat"
              :disabled="isDisableApplyButtonBulkEditMaintItems"
              :loading="state.isRequestingApi"
              @click="editMaintItems"
              >変更</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog></v-col
    >
  </v-row>
</template>

<script setup lang="ts">
import { computed, watch, reactive, ref } from "vue";
import { connectToApi } from "@/helpers/connectToApi";
import { get_base64_data_url } from "@/helpers/get_base64_data_url_from_file";
import { sleep } from "@/helpers/sleep";
import { ulid } from "@/helpers/ulid";
import {
  acceptImageMimeTypes,
  acceptFileMimeTypes,
  maxImageSize,
  maxFileSize,
  ManualLink,
  Image,
  ManualFile,
  Part,
} from "@/models/MaintItem";
import { useAuthoritiesStore } from "@/stores/authorities";

type Emits = {
  ApiResultRequest: [apiResult: any];
  OpenStatusUpdateRequest: [value: boolean];
};

type Props = {
  bulkEditMaintItemDialogOpenStatus: boolean;
  selectedMaintItemList: Array<SelectedMaintItem>;
};

type MaintItemList = {
  id: number;
  maint_task_id: number;
  plant_id: number;
  item_no: number;
  order: number;
  name: string;
  set: string;
  subject1: string;
  subject2: string;
  subject3: string;
  days_of_replacement_period: number;
  planed_date: string;
  before_minus_0_date: string;
  before_minus_1_date: string;
  before_minus_2_date: string;
  maint_item_description: string;
  man_in_man_hours: number | null;
  hour_in_man_hours: number | null;
  hinmokus_mhi: Part[];
  hinmokus_others: Part[];
};

type getMaintItemApiResponse = {
  maint_item: {
    maint_item_id: number;
    maint_task_id: number;
    plant_id: number;
    item_no: number;
    order: number;
    maint_item_name: string;
    set: string;
    subject1: string;
    subject2: string;
    subject3: string;
    days_of_maint_interval: number;
    scheduled_date: string;
    maint_item_description: string;
    man_in_man_hours: number | null;
    hour_in_man_hours: number | null;
    has_today_completed_task: boolean;
    manual_links: ManualLink[] | null;
    images: Image[];
    manual_files: ManualFile[];
    hinmokus_mhi: Part[];
    hinmokus_others: Part[];
  };
};

type ImageUploadApiResponse = {
  image_id: string;
  extension: string;
  url: string;
};

type FileUploadApiResponse = {
  file_id: string;
  extension: string;
  url: string;
};

type BulkEditMaintItemsApiResponse = {
  maint_item_ids: number[];
  maint_item_name: string | null;
  maint_item_description: string;
  images: Image[];
  manual_files: ManualFile[];
  manual_links: ManualLink[] | null;
  man_in_man_hours: number | null;
  hour_in_man_hours: number | null;
  hinmokus_mhi: Part[];
  hinmokus_others: Part[];
};

/**
 * マニュアルリンク追加用モーダルで使う値
 */
type ManualLinkModal = {
  isShow: boolean;
  formState: boolean;
  title: string;
  url: string;
};

type targetMaintItemState = {
  maint_item_id: number;
  referenced_maint_item_id: number;
  maint_item_name: string | null;
  maint_item_description: string;
  images: Image[];
  manual_files: ManualFile[];
  manual_links: ManualLink[];
  man_in_man_hours: number | null;
  hour_in_man_hours: number | null;
  hinmokus_mhi: Part[];
  hinmokus_others: Part[];
};

type TableFilter = {
  selected: MaintItemList[];
  popSnackBar: boolean;
  snackBarType: string;
  snackBarTitle: string;
  snackBarMessage: string;
  isRequestingApi: boolean;
  bulkEditMaintItemDialogOpenStatus: boolean;
  targetMaintItemId: number | undefined;
  displayedMaintItemList: SelectedMaintItem[];
  maintItem: targetMaintItemState;
  formState: boolean;
  loading: boolean;
  manualLinkModal: ManualLinkModal;
};

type SelectedMaintItem = {
  maintItemId: number;
  displayName: string;
};

const prop = defineProps<Props>();

const emit = defineEmits<Emits>();

const storeAuthorities = useAuthoritiesStore();
const refFormManualLinkModal = ref<HTMLFormElement>();

const innerOpenStatus = computed({
  get: () => prop.bulkEditMaintItemDialogOpenStatus,
  set: (value: boolean) => emit("OpenStatusUpdateRequest", value),
});

const state = reactive<TableFilter>({
  selected: [],
  popSnackBar: false,
  snackBarType: "error",
  snackBarTitle: "データの更新に失敗しました。",
  snackBarMessage: "データの更新に関する不具合が発生しています。",
  isRequestingApi: false,
  bulkEditMaintItemDialogOpenStatus: false,
  targetMaintItemId: undefined,
  displayedMaintItemList: [],
  maintItem: {
    maint_item_id: -1,
    referenced_maint_item_id: -1,
    maint_item_name: null,
    maint_item_description: "説明： \n工具： \n資材： ",
    images: [],
    manual_files: [],
    manual_links: [],
    man_in_man_hours: null,
    hour_in_man_hours: null,
    hinmokus_mhi: [],
    hinmokus_others: [],
  },
  formState: false,
  loading: false,
  manualLinkModal: {
    isShow: false,
    formState: false,
    title: "",
    url: "",
  },
});

/**
 * 整備内容の一括変更モーダルでの入力値のルール
 */
const rule22 = [
  (v: string | null) =>
    (v !== null && v.trim().length >= 1 && v.trim().length <= 22) ||
    v === null ||
    "1文字以上22文字以下で入力して下さい",
];
const letters0_200 = [
  (v: string | null) =>
    (v != null && v.trim().length <= 200) || "200文字以下で入力して下さい",
];
const letters1 = [
  (v: string | null) =>
    (v != null && v.trim().length > 0) || "1文字以上で入力してください",
];
const ruleMan = [
  (v: string | null) =>
    v == null ||
    v == "" ||
    (Number.isInteger(Number(v)) && Number(v) > 0 && Number(v) < 1000) ||
    "1~999の整数を入力して下さい",
];
const ruleHour = [
  (v: string | null) => {
    if (v == null || v === "") return true;
    const num = parseFloat(v);
    if (isNaN(num))
      return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
    if (num <= 0 || num >= 999)
      return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
    if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(v))
      return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
    return true;
  },
];
const ruleHinmokuNo = [
  (v: string) =>
    /^[!-~｡-ﾟ¥]+$/.test(v) ||
    "半角英数字、半角記号、半角カナで入力してください",
  (v: string) => !!v.trim() || "品目番号を入力してください",
];
const ruleHinmokuOtherNo = [
  (v: string) => !!v.trim() || "品目番号をしてください",
];
const ruleHinmokuName = [
  (v: string | null) =>
    (v != null && v.trim().length <= 100) || "100文字以下で入力して下さい",
];
const ruleHinmokuQuantity = [
  (v: string | null) =>
    (Number.isInteger(Number(v)) && Number(v) >= 0 && Number(v) < 1000) ||
    "0~999の整数を入力して下さい",
];

/**
 * 整備内容の一括変更モーダルの変更ボタンが無効かどうかを判定する
 */
const isDisableApplyButtonBulkEditMaintItems = computed((): boolean => {
  // 各入力項目で問題が問題ない場合formStateにはTrueが入る
  return !state.formState || state.targetMaintItemId == null;
});

/**
 * 整備項目一覧コンポーネントより送られてきた選択した整備項目情報をセットする
 */
const setSelectedMaintItemList = async () => {
  state.displayedMaintItemList = prop.selectedMaintItemList.map((item) => {
    return {
      maintItemId: item.maintItemId,
      displayName: item.displayName,
    };
  });
};

/**
 * 代表値に選択した整備項目の値を展開する
 */
const getTargetMaintItem = async (maintItemId: number) => {
  const callApi = async (param: number): Promise<getMaintItemApiResponse> => {
    const resp = await connectToApi<getMaintItemApiResponse>({
      method: "GET",
      url: "/api/getMaintItem",
      params: {
        maint_item_id: param,
      },
    });
    return resp.data;
  };

  try {
    state.loading = true;
    const resp = await callApi(maintItemId);
    // 取得したデータを格納
    state.maintItem.maint_item_name = resp.maint_item.maint_item_name;
    state.maintItem.referenced_maint_item_id = resp.maint_item.maint_item_id;
    state.maintItem.maint_item_description =
      resp.maint_item.maint_item_description;
    state.maintItem.images = resp.maint_item.images;
    state.maintItem.manual_files = resp.maint_item.manual_files;
    state.maintItem.manual_links = resp.maint_item.manual_links ?? [];
    state.maintItem.hour_in_man_hours = resp.maint_item.hour_in_man_hours;
    state.maintItem.man_in_man_hours = resp.maint_item.man_in_man_hours;
    state.maintItem.hinmokus_mhi = resp.maint_item.hinmokus_mhi ?? [];
    state.maintItem.hinmokus_others = resp.maint_item.hinmokus_others ?? [];
  } catch (e: any) {
    // 親コンポーネントにスナックバー情報を渡す
    const apiResult = {
      snackBarType: "error",
      snackBarTitle: "データの取得に失敗しました。",
      snackBarMessage:
        e?.response?.data?.message || "整備項目情報の取得に失敗しました。",
      popSnackBar: true,
      displayMaintItemName: state.maintItem.maint_item_name,
    };
    emit("ApiResultRequest", apiResult);
  } finally {
    state.loading = false;
  }
};

/**
 * <input>のクリック整備項目をScriptから叩く
 */
const openInput = () => {
  const elm: HTMLElement | null = document.getElementById("img");
  if (elm == null) return;
  elm.click();
};

/**
 * API uploadMaintItemImageを叩く
 * @param image {File} アップロードする画像の情報
 * @return {Promise<ImageUploadApiResponse>} 画像アップロードAPIのレスポンス
 */
const upload_maint_item_image = async (
  image: File,
  id: string,
): Promise<ImageUploadApiResponse> => {
  const plant_id = storeAuthorities.selectedAuthority.plantId;

  let count = 3;
  let err: any = null;
  while (count !== 0) {
    try {
      const response = await connectToApi<ImageUploadApiResponse>({
        method: "POST",
        url: `/api/uploadMaintItemImage`,
        headers: {
          "Content-Type": image.type,
        },
        data: image,
        params: {
          plant_id: plant_id,
          image_id: id,
        },
      });

      return response.data;
    } catch (e) {
      err = e;
      count -= 1;
      await sleep(1);
    }
  }

  console.error("upload_maint_item_image", err);
  throw err;
};

/**
 * <input>のchange整備項目に渡して、画像を読み込みstate.imagesに追加する
 */
const loadImages = async (event: Event) => {
  const elm = event.target;
  if (!(elm instanceof HTMLInputElement)) return;
  if (elm.files == null || elm.files.length == 0) return;

  const sum = elm.files.length + state.maintItem.images.length;
  const limit = 3;
  if (sum <= limit) {
    const uploadImages: { [key: string]: File } = {};
    for (const file of elm.files) {
      // 画像登録APIで使用できるmime-type以外なら処理を行わない
      if (!acceptImageMimeTypes.includes(file.type.toLowerCase())) {
        // 親コンポーネントにスナックバー情報を渡す
        const apiResult = {
          snackBarType: "error",
          snackBarTitle: "JPEG画像およびPNG画像以外は登録できません",
          snackBarMessage: `"${file.name}": ${file.type}`,
          popSnackBar: true,
        };
        emit("ApiResultRequest", apiResult);
        continue;
      }
      if (file.size > maxImageSize) {
        // 親コンポーネントにスナックバー情報を渡す
        const apiResult = {
          snackBarType: "error",
          snackBarTitle: "4MBより大きい画像は登録できません",
          snackBarMessage: `"${file.name}"は4MBより大きいです。 (${(
            file.size /
            1024 /
            1024
          ).toFixed(1)}MB)`,
          popSnackBar: true,
        };
        emit("ApiResultRequest", apiResult);
        continue;
      }

      // ULIDを使用してIDを作成
      const id = ulid();

      // プレビューを早く表示するため、画像アップロードAPI実行前にbase64Urlをstateに格納
      const base64Url = await get_base64_data_url(file);
      state.maintItem.images.push({
        image_id: id,
        extension: file.type,
        name: file.name,
        url: base64Url,
      });

      // 今回アップロードする画像を格納
      uploadImages[id] = file;
    }

    // 画像アップロードAPIを実行
    for (const [id, file] of Object.entries(uploadImages)) {
      try {
        const res = await upload_maint_item_image(file, id);

        // APIでアップロード完了後、レスポンス結果を使ってstateを書き換え
        const index = state.maintItem.images.findIndex(
          (i) => i.image_id === id,
        );
        state.maintItem.images[index] = {
          image_id: res.image_id,
          extension: res.extension,
          name: file.name,
          url: res.url,
        };
      } catch (error) {
        // アップロードに失敗したらstateから画像を削除
        state.maintItem.images = state.maintItem.images.filter(
          (i) => i.image_id !== id,
        );
        // 親コンポーネントにスナックバー情報を渡す
        const apiResult = {
          snackBarType: "error",
          snackBarTitle:
            "画像の登録時に不具合が発生しました。再度登録してください。",
          snackBarMessage: `"${file.name}": ${file.type}`,
          popSnackBar: true,
        };
        emit("ApiResultRequest", apiResult);
        continue;
      }
    }
  } else {
    // 親コンポーネントにスナックバー情報を渡す
    const apiResult = {
      snackBarType: "error",
      snackBarTitle: "添付画像数超過",
      snackBarMessage: `添付可能な画像数は${limit}枚までです。`,
      popSnackBar: true,
    };
    emit("ApiResultRequest", apiResult);
  }
  // <input>をファイルを選択していない状態に戻す
  elm.value = "";
};

/**
 * 画像のプレビューにおいて、削除ボタンを押した時の処理
 * @param image
 */
const delete_image = (image: Image) => {
  state.maintItem.images = state.maintItem.images.filter(
    (i) => i.image_id !== image.image_id,
  );
};

/**
 * <input>のクリック整備項目をScriptから叩く
 */
const openInputManualFile = () => {
  const elm: HTMLElement | null = document.getElementById("a");
  if (elm == null) return;
  elm.click();
};

/**
 * API uploadMaintItemFileを叩く
 * @param manualFile {File} アップロードするファイルの情報
 * @return {Promise<FileUploadApiResponse>} ファイルアップロードAPIのレスポンス
 */
const upload_maint_item_manual_file = async (
  manualFile: File,
  id: string,
): Promise<FileUploadApiResponse> => {
  const plant_id = storeAuthorities.selectedAuthority.plantId;

  let count = 3;
  let err: any = null;
  while (count !== 0) {
    try {
      const response = await connectToApi<FileUploadApiResponse>({
        method: "POST",
        url: `/api/uploadMaintItemFile`,
        headers: {
          "Content-Type": manualFile.type,
        },
        data: manualFile,
        params: {
          plant_id: plant_id,
          file_id: id,
        },
      });

      return response.data;
    } catch (e) {
      err = e;
      count -= 1;
      await sleep(1);
    }
  }

  console.error("upload_maint_item_manual_file", err);
  throw err;
};

/**
 * <input>のchange整備項目に渡して、画像を読み込みstate.manualFilesに追加する
 */
const loadManualFiles = async (event: Event) => {
  const elm = event.target;
  if (!(elm instanceof HTMLInputElement)) return;
  if (elm.files == null || elm.files.length == 0) return;
  // 選択したファイルが4個以上 or ファイル選択後に添付ファイル数との合計が4個以上になる場合はエラーを表示
  const sum = elm.files.length + state.maintItem.manual_files.length;
  const limit = 3;
  if (sum <= limit) {
    const uploadManualFiles: { [key: string]: File } = {};
    for (const file of elm.files) {
      // ファイル登録APIで使用できるmime-type以外なら処理を行わない
      if (!acceptFileMimeTypes.includes(file.type.toLowerCase())) {
        // 親コンポーネントにスナックバー情報を渡す
        const apiResult = {
          snackBarType: "error",
          snackBarTitle: "PDFファイル以外は登録できません",
          snackBarMessage: `"${file.name}": ${file.type}`,
          popSnackBar: true,
        };
        emit("ApiResultRequest", apiResult);
        continue;
      }
      if (file.size > maxFileSize) {
        // 親コンポーネントにスナックバー情報を渡す
        const apiResult = {
          snackBarType: "error",
          snackBarTitle: "4MBより大きいファイルは登録できません",
          snackBarMessage: `"${file.name}"は4MBより大きいです。 (${(
            file.size /
            1024 /
            1024
          ).toFixed(1)}MB)`,
          popSnackBar: true,
        };
        emit("ApiResultRequest", apiResult);
        continue;
      }

      // ULIDを使用してIDを作成
      const id = ulid();

      // ファイルを早く表示するため、ファイルアップロードAPI実行前にbase64Urlをstateに格納
      const base64Url = await get_base64_data_url(file);
      state.maintItem.manual_files.push({
        file_id: id,
        extension: file.type.split("/").pop() ?? "",
        name: file.name.replace(/\.[A-Za-z]+$/, ""), // 拡張子を除く
        url: base64Url,
      });

      // 今回アップロードするファイルを格納
      uploadManualFiles[id] = file;
    }

    // ファイルアップロードAPIを実行
    for (const [id, file] of Object.entries(uploadManualFiles)) {
      try {
        const res = await upload_maint_item_manual_file(file, id);

        // APIでアップロード完了後、レスポンス結果を使ってstateを書き換え
        const index = state.maintItem.manual_files.findIndex(
          (i) => i.file_id === id,
        );
        state.maintItem.manual_files[index] = {
          file_id: res.file_id,
          extension: res.extension,
          name: file.name.replace(/\.[A-Za-z]+$/, ""), // 拡張子を除く
          url: res.url,
        };
      } catch (error) {
        // アップロードに失敗したらstateからファイルを削除
        state.maintItem.manual_files = state.maintItem.manual_files.filter(
          (i) => i.file_id !== id,
        );

        // 親コンポーネントにスナックバー情報を渡す
        const apiResult = {
          snackBarType: "error",
          snackBarTitle:
            "ファイルの登録時に不具合が発生しました。再度登録してください。",
          snackBarMessage: `"${file.name}": ${file.type}`,
          popSnackBar: true,
        };
        emit("ApiResultRequest", apiResult);
      }
    }
  } else {
    // 親コンポーネントにスナックバー情報を渡す
    const apiResult = {
      snackBarType: "error",
      snackBarTitle: "添付ファイル数超過",
      snackBarMessage: `添付可能なファイル数は${limit}個までです。`,
      popSnackBar: true,
    };
    emit("ApiResultRequest", apiResult);
  }
  // <input>をファイルを選択していない状態に戻す
  elm.value = "";
};

/**
 * ファイルのプレビューにおいて、削除ボタンを押した時の処理
 * @param manualFile
 */
const delete_manual_file = (file: ManualFile) => {
  state.maintItem.manual_files = state.maintItem.manual_files.filter(
    (f) => f.file_id !== file.file_id,
  );
};

/**
 * マニュアルリンクの追加が可能かどうか判定する
 */
const isDisabledToAddManualLink = computed<boolean>(() => {
  const manualLinks = state.maintItem.manual_links;
  const isEmptyTargetMaintItemName = state.targetMaintItemId == null;

  return (
    (manualLinks ? manualLinks.length >= 3 : false) ||
    isEmptyTargetMaintItemName
  );
});

/**
 * マニュアルリンク追加用モーダルを表示する
 */
const openManualLinkModal = () => {
  state.manualLinkModal.title = "";
  state.manualLinkModal.url = "";
  refFormManualLinkModal.value?.reset();
  state.manualLinkModal.isShow = true;
};

/**
 * マニュアルリンクを追加
 */
const addManualLink = () => {
  const title = state.manualLinkModal.title?.trim();
  const url = state.manualLinkModal.url.trim();
  const link: ManualLink = {
    title: title || url,
    url: url,
  };
  state.maintItem.manual_links.push(link);
  state.manualLinkModal.isShow = false;
};

/**
 * 指定したindexのマニュアルリンクを消す
 */
const deleteManualLink = (index: number) => {
  state.maintItem.manual_links.splice(index, 1);
};

/**
 * 工数（人）が空欄の場合はnullに変換する
 * 入力がある場合は数値型に変換する
 */
const convertManInput = (inputData: number | null) => {
  return Number(inputData) == 0 ? null : Number(inputData);
};

/**
 * 工数（時間）が空欄の場合はnullに変換する
 * 入力データは文字列型に変換する（元々入力済みの場合は数値型のため）
 */
const convertHoursInput = (inputData: number | null) => {
  return Number(inputData) == 0 ? null : String(inputData);
};

/**
 * 整備部品の個数が空欄の場合は0に変換する
 * 入力がある場合は数値型に変換する
 */
const convertHinmokusQuantityInput = (inputData: number | null) => {
  return Number(inputData) == 0 ? 0 : Number(inputData);
};

/**
 * 整備部品（MHI）の入力フォームを追加する
 */
const addInputHinmokuFrom = () => {
  state.maintItem.hinmokus_mhi.push({
    hinmoku_no: "",
    name: "",
    quantity: null,
  });
};

/**
 * 指定したindexの整備部品（MHI）を消す
 */
const deleteHinmoku = (index: number) => {
  state.maintItem.hinmokus_mhi.splice(index, 1);
};

/**
 * 整備部品（MHI以外）の入力フォームを追加する
 */
const addInputHinmokuOtherFrom = () => {
  state.maintItem.hinmokus_others.push({
    hinmoku_no: "",
    name: "",
    quantity: null,
  });
};

/**
 * 指定したindexの整備部品（MHI以外）を消す
 *
 */
const deleteHinmokuOther = (index: number) => {
  state.maintItem.hinmokus_others.splice(index, 1);
};

/**
 * 整備内容の一括変更を行う
 */
const editMaintItems = async () => {
  // 確認モーダルを表示
  const confirmMessage =
    "選択されたすべての整備項目の内容を一括で変更します。\nこの操作は取り消せません。";
  const confirmed = confirm(confirmMessage);
  if (!confirmed) {
    return;
  }

  state.isRequestingApi = true;

  type apiInputData = {
    maint_item_ids: number[];
    plant_id: number;
    referenced_maint_item_id: number;
    maint_item_name: string | null;
    maint_item_description: string;
    images: Image[];
    manual_files: ManualFile[];
    manual_links: ManualLink[];
    man_in_man_hours: number | null;
    hour_in_man_hours: string | null;
    hinmokus_mhi: Part[];
    hinmokus_others: Part[];
  };

  const callApi = async (
    param: apiInputData,
  ): Promise<BulkEditMaintItemsApiResponse> => {
    const resp = await connectToApi<BulkEditMaintItemsApiResponse>({
      method: "POST",
      url: "/api/bulkEditMaintItems",
      data: param,
    });
    return resp.data;
  };

  // 対象の整備項目IDセット
  const maint_item_id_list = prop.selectedMaintItemList.map(function (element) {
    return element.maintItemId;
  });

  const data: apiInputData = {
    maint_item_ids: maint_item_id_list,
    plant_id: parseInt(storeAuthorities.selectedAuthority.plantId, 10),
    referenced_maint_item_id: state.maintItem.referenced_maint_item_id,
    maint_item_name: state.maintItem.maint_item_name,
    maint_item_description: state.maintItem.maint_item_description,
    images: state.maintItem.images,
    manual_files: state.maintItem.manual_files,
    manual_links: state.maintItem.manual_links,
    man_in_man_hours: convertManInput(state.maintItem.man_in_man_hours),
    hour_in_man_hours: convertHoursInput(state.maintItem.hour_in_man_hours),
    // 整備部品の個数の値を変換
    hinmokus_mhi: state.maintItem.hinmokus_mhi.map((item) => {
      return {
        ...item,
        quantity: convertHinmokusQuantityInput(item.quantity),
      };
    }),
    hinmokus_others: state.maintItem.hinmokus_others.map((item) => {
      return {
        ...item,
        quantity: convertHinmokusQuantityInput(item.quantity),
      };
    }),
  };

  try {
    await callApi(data);

    innerOpenStatus.value = false;

    // 親コンポーネントにスナックバー情報、更新後の整備項目名を渡す
    const apiResult = {
      snackBarType: "success",
      snackBarTitle: "",
      snackBarMessage: "整備内容の一括変更を登録しました。",
      popSnackBar: true,
      displayMaintItemName: state.maintItem.maint_item_name,
    };
    emit("ApiResultRequest", apiResult);
  } catch (e: any) {
    // 親コンポーネントにスナックバー情報を渡す
    const apiResult = {
      snackBarType: "error",
      snackBarTitle: "",
      snackBarMessage:
        e.response !== undefined
          ? e.response.data.message
          : "データの更新に関する不具合が発生しています。",
      popSnackBar: true,
    };
    emit("ApiResultRequest", apiResult);
  } finally {
    state.isRequestingApi = false;
  }
};

// 整備内容の一括変更モーダルの開閉を監視している
watch(
  () => prop.bulkEditMaintItemDialogOpenStatus,
  () => {
    if (!prop.bulkEditMaintItemDialogOpenStatus) {
      // モーダルが閉じられるとformの中身を消去
      state.displayedMaintItemList = [];
      state.maintItem.maint_item_name = null;
      state.maintItem.maint_item_description = "説明： \n工具： \n資材： ";
      state.maintItem.images = [];
      state.maintItem.manual_files = [];
      state.maintItem.manual_links = [];
      state.maintItem.hour_in_man_hours = null;
      state.maintItem.man_in_man_hours = null;
      state.maintItem.hinmokus_mhi = [];
      state.maintItem.hinmokus_others = [];
    } else {
      setSelectedMaintItemList();
      state.targetMaintItemId = undefined;
    }
  },
);
</script>

<style scoped>
.no-item {
  opacity: 0.6;
}
.input-field-22c {
  width: 400px;
}
.input-field-3c {
  max-width: 150px;
}
.select-area {
  background-color: #fafafa;
}
.select-area-select {
  width: 500px;
}
.select-area-title {
  font-weight: normal;
}
.v-dialog .v-icon.notranslate.mdi.mdi-close.theme--light {
  display: none;
}
.img_area {
  border: 1px dashed #a19a9a;
  height: 100px;
}
.upload-file-name {
  cursor: pointer;
}
</style>
