<template>
  <div class="text-center">
    <v-dialog v-model="innerOpenStatus" width="480">
      <v-card>
        <v-card-title> 過去日で完了にする </v-card-title>
        <v-card-text class="pt-1 pb-1 text-body-2"
          >計画日を過ぎている作業を完了させますか？</v-card-text
        >
        <v-card-actions class="justify-end mt-1">
          <v-btn variant="outlined" @click="innerOpenStatus = false"
            >キャンセル</v-btn
          >
          <v-btn
            color="primary"
            :loading="state.loading"
            variant="flat"
            @click="completePastMaintEvent"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { AlertSnackBarModel } from "@/models/snackbar";
import { MaintEvent } from "@/models/MaintEvent";
import { PastEventCompletionResult } from "@/models/pastEventCompletionResult";
import { requestReportPastEventCompletion } from "@/helpers/api/reportPastEventCompletion";

type Emits = {
  OpenStatusUpdateRequest: [value: boolean];
  SnackBar: [snackBar: AlertSnackBarModel];
  PastCompletionResult: [pastCompletionResult: PastEventCompletionResult];
};

type Props = {
  pastMaintEventCompleteDialogOpenStatus: boolean;
  maintEvent: MaintEvent;
};

const prop = defineProps<Props>();

const emit = defineEmits<Emits>();

const innerOpenStatus = computed({
  get: () => prop.pastMaintEventCompleteDialogOpenStatus,
  set: (value: boolean) => emit("OpenStatusUpdateRequest", value),
});

const state = reactive({
  loading: false,
});

const completePastMaintEvent = async () => {
  state.loading = true;

  try {
    const result = await requestReportPastEventCompletion(prop.maintEvent.id);

    innerOpenStatus.value = false;
    emit("PastCompletionResult", result);

    const snackBar: AlertSnackBarModel = {
      isShow: true,
      type: "success",
      title: "",
      message: "過去日でイベントを完了しました。",
    };
    emit("SnackBar", snackBar);
  } catch (e) {
    const snackBar: AlertSnackBarModel = {
      isShow: true,
      type: "error",
      title: "",
      message: "過去日でのイベント完了に関する不具合が発生しています。",
    };
    emit("SnackBar", snackBar);
  } finally {
    state.loading = false;
  }
};
</script>

<style scoped>
div.maint-event-date-area {
  border-radius: 5px;
  margin: 0 12px;
  padding: 16px;
}
</style>
