import { HinmokuApiResponse } from "@/helpers/api/hojyuHinmoku";

type MhiPartsData = {
  [key: string]: {
    url: string;
    hinmokuName: string;
  };
};

// MHIの取り扱いがある部品情報
export class MhiPartsSearchResult {
  mhiParts: MhiPartsData;

  constructor(hinmokuResponse: HinmokuApiResponse) {
    this.mhiParts = hinmokuResponse.hinmokus;
  }

  public static empty() {
    return new MhiPartsSearchResult({
      hinmokus: {},
    });
  }

  // MHI（HOJYU）での取り扱いがある部品かどうか
  public isExist(hinmokuNo: string) {
    return hinmokuNo in this.mhiParts;
  }

  // MHI（HOJYU）での取り扱いがある場合に、そのURLを返す
  public getUrl(hinmokuNo: string) {
    return this.mhiParts[hinmokuNo]?.url;
  }
}
