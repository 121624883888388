<template>
  <v-container>
    <alert-snack-bar
      v-model="state.alertSnackBar.pop"
      :type="state.alertSnackBar.type"
      :title="state.alertSnackBar.title"
      :message="state.alertSnackBar.message"
    />
    <v-dialog v-model="state.manualLinkModal.isShow" width="80%">
      <v-card>
        <v-card-item class="text-h6 py-3">
          URLリンクを追加する
          <template #append>
            <v-btn
              icon
              size="small"
              variant="text"
              @click="state.manualLinkModal.isShow = false"
            >
              <v-icon size="x-large">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-card-item>
        <v-divider />
        <v-card-text class="pa-6">
          <v-form
            ref="refFormManualLinkModal"
            v-model="state.manualLinkModal.formState"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="state.manualLinkModal.title"
                  color="primary"
                  variant="outlined"
                  density="compact"
                  label="表示テキスト"
                  placeholder="表示されるテキストを入力"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="state.manualLinkModal.url"
                  color="primary"
                  variant="outlined"
                  density="compact"
                  label="URL"
                  placeholder="https://"
                  :rules="funcs.rules.letters1"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer />
          <v-btn
            variant="outlined"
            @click="state.manualLinkModal.isShow = false"
            >キャンセル</v-btn
          >
          <v-btn
            variant="flat"
            :color="state.manualLinkModal.formState ? 'primary' : undefined"
            :disabled="!state.manualLinkModal.formState"
            @click.stop="funcs.addManualLink"
            >リンクを追加</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col xl="12" cols="12" class="d-inline-flex">
        <h3 class="my-3">整備項目編集</h3>
        <v-spacer />
        <v-btn
          color="grey-darken-2"
          variant="outlined"
          class="my-3"
          :loading="state.registerLoading"
          @click="funcs.delete_maint_item"
        >
          <v-icon size="x-large"> mdi-delete </v-icon>
          整備項目を削除
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet color="white" elevation="1" class="pa-2">
          <v-form ref="form" v-model="state.formState">
            <v-row>
              <v-col xl="12" cols="12">
                <v-text-field
                  v-model="state.maintItem.maint_item_name"
                  color="primary"
                  counter="22"
                  density="compact"
                  persistent-counter
                  label="整備項目名"
                  variant="outlined"
                  class="input-field-22c"
                  :rules="funcs.rules.letters1_22"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-inline-flex justify-start">
                <v-combobox
                  v-model="state.maintItem.set"
                  color="primary"
                  counter="3"
                  density="compact"
                  persistent-counter
                  label="セット"
                  variant="outlined"
                  class="mr-5 input-field-3c"
                  :items="comboBoxItems.setItems"
                  :rules="funcs.rules.letters1_3"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
                <v-combobox
                  v-model="state.maintItem.subject1"
                  color="primary"
                  counter="3"
                  density="compact"
                  persistent-counter
                  label="対象1"
                  variant="outlined"
                  class="mr-5 input-field-3c"
                  :items="comboBoxItems.subject1Items"
                  :rules="funcs.rules.letters1_3"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
                <v-combobox
                  v-model="state.maintItem.subject2"
                  color="primary"
                  counter="3"
                  density="compact"
                  persistent-counter
                  label="対象2"
                  variant="outlined"
                  class="mr-5 input-field-3c"
                  :items="comboBoxItems.subject2Items"
                  :rules="funcs.rules.letters1_3"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
                <v-combobox
                  v-model="state.maintItem.subject3"
                  color="primary"
                  counter="3"
                  density="compact"
                  persistent-counter
                  label="対象3"
                  variant="outlined"
                  class="mr-5 input-field-3c"
                  :items="comboBoxItems.subject3Items"
                  :rules="funcs.rules.letters1_3"
                  :loading="state.loading"
                  :disabled="state.disabled"
                />
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2" />
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">整備計画日</h4>
                <v-menu
                  v-model="state.datepicker.isShow"
                  location="bottom"
                  min-width="auto"
                  offset="40"
                  :close-on-content-click="false"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      v-bind="props"
                      v-model="state.datepicker.displayDate"
                      readonly
                      append-inner-icon="mdi-calendar"
                      color="primary"
                      density="compact"
                      variant="outlined"
                      class="datepicker-textfield input-shift"
                      :rules="funcs.rules.scheduleDate"
                      :loading="state.loading"
                      :disabled="state.disabled"
                    />
                  </template>
                  <v-locale-provider locale="ja">
                    <v-date-picker
                      v-model="state.datepicker.inputDate"
                      color="primary"
                      header=""
                      :title="
                        $dayjs(state.datepicker.inputDate).format('YYYY年')
                      "
                      class="elevation-1"
                      @update:model-value="state.datepicker.isShow = false"
                    />
                  </v-locale-provider>
                </v-menu>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2" />
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">作業周期</h4>
                <v-text-field
                  v-model="state.maintItem.days_of_maint_interval"
                  color="primary"
                  density="compact"
                  :rules="funcs.rules.ruleInputMaintInterval"
                  type="text"
                  maxlength="4"
                  hint="1~9999の整数のみ可能です"
                  label="日数"
                  variant="outlined"
                  class="input-field-3c"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2" />
            <v-row>
              <v-col>
                <h4 class="mb-5">整備部品</h4>
                <h5>
                  MHIから購入する部品 (最大10個)<v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <v-icon v-bind="props" class="ml-1 mb-1" size="small"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <span
                      >品目番号がe-Partsの品目番号と一致する場合、閲覧時に自動的にリンクとして表示され、<br />e-Partsの品目詳細ページにアクセスをできるようになります。</span
                    >
                  </v-tooltip>
                </h5>
              </v-col>
            </v-row>
            <v-row
              v-for="(hinmoku, index) in state.maintItem.hinmokus_mhi"
              :key="`hinmoku-mhi-${index}`"
              class="mb-n6"
            >
              <v-col cols="3">
                <v-text-field
                  v-model="hinmoku.hinmoku_no"
                  color="primary"
                  label="品目番号"
                  variant="outlined"
                  density="compact"
                  hint="半角英数字、半角記号、半角カナのみ入力可能です"
                  persistent-counter
                  type="text"
                  counter="15"
                  maxlength="15"
                  :rules="funcs.rules.ruleHinmokuNo"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="hinmoku.name"
                  color="primary"
                  label="名称など"
                  variant="outlined"
                  density="compact"
                  persistent-counter
                  type="text"
                  counter="100"
                  :rules="funcs.rules.ruleHinmokuName"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="hinmoku.quantity"
                  color="primary"
                  label="個数"
                  variant="outlined"
                  density="compact"
                  hint="0~999の整数のみ入力可能です"
                  type="text"
                  maxlength="3"
                  :rules="funcs.rules.ruleHinmokuQuantity"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-btn
                class="mt-4"
                icon
                size="x-small"
                variant="tonal"
                @click="funcs.deleteHinmoku(index)"
              >
                <v-icon> mdi-minus </v-icon>
              </v-btn>
            </v-row>
            <v-row class="mt-3 mb-2">
              <v-col>
                <v-btn
                  variant="outlined"
                  :disabled="state.maintItem.hinmokus_mhi.length >= 10"
                  @click="funcs.addInputHinmokuFrom()"
                >
                  <v-icon class="my-2" alt="search icon" size="large"
                    >mdi-plus</v-icon
                  >
                  整備部品を追加</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5>その他部品 (MHI以外・最大10個)</h5>
              </v-col>
            </v-row>
            <v-row
              v-for="(hinmoku, index) in state.maintItem.hinmokus_others"
              :key="`hinmoku-others-${index}`"
              class="mb-n6"
            >
              <v-col cols="3">
                <v-text-field
                  v-model="hinmoku.hinmoku_no"
                  color="primary"
                  label="品目番号"
                  variant="outlined"
                  density="compact"
                  persistent-counter
                  type="text"
                  counter="50"
                  maxlength="50"
                  :rules="funcs.rules.ruleHinmokuOtherNo"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="hinmoku.name"
                  color="primary"
                  label="名称など"
                  variant="outlined"
                  density="compact"
                  persistent-counter
                  type="text"
                  counter="100"
                  :rules="funcs.rules.ruleHinmokuName"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="hinmoku.quantity"
                  color="primary"
                  label="個数"
                  variant="outlined"
                  density="compact"
                  hint="0~999の整数のみ入力可能です"
                  type="text"
                  maxlength="3"
                  :rules="funcs.rules.ruleHinmokuQuantity"
                  :loading="state.loading"
                ></v-text-field>
              </v-col>
              <v-btn
                class="mt-4"
                icon
                size="x-small"
                variant="tonal"
                @click="funcs.deleteHinmokuOther(index)"
              >
                <v-icon> mdi-minus </v-icon>
              </v-btn>
            </v-row>
            <v-row class="mt-3 mb-2">
              <v-col>
                <v-btn
                  variant="outlined"
                  :disabled="state.maintItem.hinmokus_others.length >= 10"
                  @click="funcs.addInputHinmokuOtherFrom()"
                >
                  <v-icon class="my-2" alt="search icon" size="large"
                    >mdi-plus</v-icon
                  >
                  整備部品を追加</v-btn
                >
              </v-col>
            </v-row>
            <v-divider class="mb-7" />
            <v-row>
              <v-col xl="12" cols="12">
                <h4 class="mb-5">整備作業の説明 (200文字まで)</h4>
                <v-textarea
                  v-model="state.maintItem.maint_item_description"
                  color="primary"
                  label="テキストの入力"
                  counter="200"
                  persistent-counter
                  :rules="funcs.rules.letters0_200"
                  :loading="state.loading"
                  variant="outlined"
                />
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-2" />
            <v-row>
              <v-col cols="12">
                <h4 class="mb-5">整備詳細画像 (最大3つ)</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="image in state.maintItem.images"
                :key="image.image_id"
              >
                <img
                  :src="image.url"
                  :alt="image.name"
                  style="max-width: 300px; border: solid 1px black"
                />
                <v-btn
                  class="mx-2"
                  icon
                  size="x-small"
                  variant="tonal"
                  :loading="state.loading"
                  :disabled="state.disabled"
                  @click="funcs.delete_image(image)"
                  ><v-icon>mdi-minus</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  block
                  variant="outlined"
                  class="img_area"
                  :loading="state.imageUploading"
                  :disabled="
                    state.disabled || state.maintItem.images.length >= 3
                  "
                  @click="funcs.openInput"
                >
                  <v-icon class="my-2" alt="search icon" size="x-large"
                    >mdi-plus</v-icon
                  >
                </v-btn>
                <input
                  id="img"
                  type="file"
                  accept="image/jpeg, image/png"
                  style="display: none"
                  multiple
                  @change="funcs.loadImages"
                />
              </v-col>
            </v-row>
            <v-divider class="mb-7" />
            <v-row>
              <v-col cols="12">
                <h4 class="mb-5">整備詳細マニュアル</h4>
                <h5 class="mb-5">PDFファイル (最大3つ)</h5>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="manualFile in state.maintItem.manual_files"
                :key="manualFile.file_id"
                cols="12"
                class="mt-n4"
              >
                <v-icon size="small"> mdi-paperclip </v-icon>
                <a class="upload-file-name text-primary">
                  {{ `${manualFile.name}.${manualFile.extension}` }}
                </a>
                <v-btn
                  class="mx-2 delete-button"
                  icon
                  size="x-small"
                  variant="tonal"
                  @click="funcs.delete_manual_file(manualFile)"
                >
                  <v-icon size="x-small"> mdi-minus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  block
                  variant="outlined"
                  class="img_area"
                  :loading="state.fileUploading"
                  :disabled="
                    state.disabled || state.maintItem.manual_files.length >= 3
                  "
                  @click="funcs.openInputManualFile"
                >
                  <v-icon class="my-2" alt="search icon" size="x-large"
                    >mdi-plus</v-icon
                  >
                </v-btn>
                <input
                  id="a"
                  type="file"
                  accept="application/pdf"
                  style="display: none"
                  multiple
                  @change="funcs.loadManualFiles"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5 class="mb-5">リンク (最大3つ)</h5>
              </v-col>
            </v-row>
            <v-row
              v-for="(link, index) in state.maintItem.manual_links"
              :key="`${index}-${link.url}`"
            >
              <v-col class="mt-n4">
                <a
                  :href="link.url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-primary"
                  >{{ link.title }}</a
                >
                <v-btn
                  class="mx-2 delete-button"
                  icon
                  size="x-small"
                  variant="tonal"
                  @click="funcs.deleteManualLink(index)"
                >
                  <v-icon size="x-small">mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  variant="outlined"
                  :disabled="calculated.isDisabledToAddManualLink.value"
                  @click.stop="funcs.openManualLinkModal"
                >
                  <v-icon class="my-2" alt="search icon">mdi-plus</v-icon>
                  URLリンクを追加</v-btn
                >
              </v-col>
            </v-row>
            <v-divider class="mb-7 mt-4"></v-divider>
            <h4 class="mb-5">工数</h4>
            <v-row>
              <v-col cols="12" class="d-inline-flex justify-start">
                <v-text-field
                  v-model="state.maintItem.man_in_man_hours"
                  color="primary"
                  density="compact"
                  :rules="funcs.rules.ruleMan"
                  label="人数（人）"
                  hint="空欄もしくは1~999の整数のみ入力可能です"
                  type="text"
                  :loading="state.loading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-text-field>
                <v-text-field
                  v-model="state.maintItem.hour_in_man_hours"
                  color="primary"
                  density="compact"
                  :rules="funcs.rules.ruleHour"
                  label="時間（h）"
                  hint="空欄もしくは0より大きく999未満の数値(小数は小数点以下2桁まで)のみ入力可能です"
                  type="text"
                  :loading="state.loading"
                  variant="outlined"
                  class="input-field-3c mr-5"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="12" cols="12" class="d-flex justify-end">
                <v-btn
                  variant="outlined"
                  class="mr-4 cancel-btn"
                  :to="funcs.create_redirect_location()"
                  :loading="state.loading"
                  :disabled="state.disabled"
                  >キャンセル</v-btn
                >
                <v-btn
                  variant="flat"
                  :color="
                    !calculated.disableUpdateButton.value
                      ? 'primary'
                      : undefined
                  "
                  class="mr-4"
                  :loading="state.registerLoading"
                  :disabled="calculated.disableUpdateButton.value"
                  @click="funcs.update_maint_item"
                  >更新</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import AlertSnackBar from "@/components/AlertSnackBar.vue";
import { computed, reactive, ref, watch } from "vue";
import { RouteLocationNamedRaw, useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { get_base64_data_url } from "@/helpers/get_base64_data_url_from_file";
import { ulid } from "@/helpers/ulid";
import { acceptImageMimeTypes, acceptFileMimeTypes } from "@/models/MaintItem";
import {
  MaintItemData,
  ManualLink,
  maxImageSize,
  maxFileSize,
  Image,
  ManualFile,
  Part,
} from "@/models/MaintItem";
import { connectToApi } from "@/helpers/connectToApi";
import { sleep } from "@/helpers/sleep";
import { useAuthoritiesStore } from "@/stores/authorities";
import { requestGetMaintenanceClassification } from "@/helpers/api/getMaintenanceClassification";

dayjs.extend(isSameOrAfter);

/**
 * AlertSnackBar用のState
 */
type AlertSnackBarState = {
  pop: boolean;
  type: string;
  title: string;
  message: string;
};

/**
 * maint_item用のState
 */
type MaintItemState = {
  maint_item_id: number;
  maint_task_id: number;
  plant_id: number;
  item_no: number;
  order: number;
  maint_item_name: string;
  set: string | undefined;
  subject1: string | undefined;
  subject2: string | undefined;
  subject3: string | undefined;
  days_of_maint_interval: number | null;
  scheduled_date: string;
  maint_item_description: string;
  man_in_man_hours: number | null;
  hour_in_man_hours: string | number | null;
  has_today_completed_task: boolean;
  manual_links: ManualLink[];
  images: Image[];
  manual_files: ManualFile[];
  hinmokus_mhi: Part[];
  hinmokus_others: Part[];
};

/**
 * DatePicker用のState
 */
type DatePickerState = {
  isShow: boolean;
  inputDate: Date | undefined;
  displayDate: string;
};

type StateComboBoxItems = {
  setItems: string[];
  subject1Items: string[];
  subject2Items: string[];
  subject3Items: string[];
};

/**
 * マニュアルリンク追加用モーダルで使う値
 */
type ManualLinkModal = {
  isShow: boolean;
  formState: boolean;
  title: string;
  url: string;
};

/**
 * reactive()に渡すためのState。基本的にtemplateなどで使用する変数はここに置く。
 */
type State = {
  alertSnackBar: AlertSnackBarState;
  disabled: boolean;
  loading: boolean;
  registerLoading: boolean;
  imageUploading: boolean;
  fileUploading: boolean;
  formState: boolean;
  maintItem: MaintItemState;
  datepicker: DatePickerState;
  comboboxLoading: boolean;
  manualLinkModal: ManualLinkModal;
};

/**
 * Apiから画面に必要な値を取得する際に使用するType
 */
type ApiResponse = {
  maint_item: MaintItemData;
};

/**
 * このViewコンポーネントえ使用するProps
 */
type Props = {
  maintItemId: string;
};

type ImageUploadApiResponse = {
  image_id: string;
  extension: string;
  url: string;
};

type FileUploadApiResponse = {
  file_id: string;
  extension: string;
  url: string;
};

/**
 * API deleteMaintItemを叩いた際にエラーが起きたことを記録するためのクラス
 */
class DeleteMaintItemError {
  message: string;
  isCompletedOrDeleted: boolean;
  constructor(message: string, isCompletedOrDeleted: boolean) {
    this.message = message;
    this.isCompletedOrDeleted = isCompletedOrDeleted;
  }
}

const prop = defineProps<Props>();

const storeAuthorities = useAuthoritiesStore();
const route = useRoute();
const router = useRouter();
const refFormManualLinkModal = ref<HTMLFormElement>();

const state = reactive<State>({
  alertSnackBar: {
    pop: false,
    type: "error",
    title: "データの更新に失敗しました",
    message: "データの更新に関する不具合が発生しています。",
  },
  disabled: true,
  loading: true,
  registerLoading: true,
  imageUploading: false,
  fileUploading: false,
  formState: false,
  maintItem: {
    maint_item_id: -1,
    maint_task_id: -1,
    plant_id: -1,
    item_no: -1,
    order: -1,
    maint_item_name: "",
    set: undefined,
    subject1: undefined,
    subject2: undefined,
    subject3: undefined,
    days_of_maint_interval: null,
    scheduled_date: "",
    maint_item_description: "説明： \n工具： \n資材： ",
    man_in_man_hours: null,
    hour_in_man_hours: null,
    has_today_completed_task: false,
    manual_links: [],
    images: [],
    manual_files: [],
    hinmokus_mhi: [],
    hinmokus_others: [],
  },
  datepicker: {
    isShow: false,
    inputDate: undefined,
    displayDate: "",
  },
  comboboxLoading: false,
  manualLinkModal: {
    isShow: false,
    formState: false,
    title: "",
    url: "",
  },
});

const comboBoxItems = reactive<StateComboBoxItems>({
  setItems: [],
  subject1Items: [],
  subject2Items: [],
  subject3Items: [],
});

/**
 * computedをひとまとめにする箱
 * (<template>内で使用する場合には calculated.xxxx.valueと書く必要がある)
 */
const calculated = {
  /**
   * 更新ボタンのDisabledに渡す値を算出する
   */
  disableUpdateButton: computed<boolean>(() => {
    // 各入力項目で問題が問題ない場合formStateにはTrueが入る
    return !state.formState || state.disabled;
  }),
  /**
   * マニュアルリンクの追加が可能かどうか判定する
   */
  isDisabledToAddManualLink: computed<boolean>(() => {
    return state.maintItem.manual_links.length >= 3;
  }),
};

/**
 * 関数を入れておくための箱
 */
const funcs = {
  /**
   * QueryStringを解析してリダイレクト用のRouteLocationNamedRawを作成する
   */
  create_redirect_location: (): RouteLocationNamedRaw => {
    const query = route.query;

    const referer = query.referer;

    if (referer === "maint-task-list") {
      if (typeof query.yyyymmdd === "string") {
        return {
          name: "maint-task-list-yyyymmdd",
          params: {
            yyyymmdd: query.yyyymmdd,
          },
        };
      } else {
        return {
          name: "maint-task-list",
        };
      }
    } else if (referer === "calendar") {
      if (typeof query.yyyymm === "string") {
        return {
          name: "calendar-yyyymm",
          params: {
            yyyymm: query.yyyymm,
          },
        };
      } else {
        return {
          name: "calendar",
        };
      }
    } else {
      return {
        name: "operation-history",
      };
    }
  },
  /**
   * <v-form>配下の入力項目に対するValidation Rule
   */
  rules: {
    letters1_22: [
      (v: string | null) =>
        (v != null && 1 <= v.trim().length && v.trim().length <= 22) ||
        "1文字以上22文字以下で入力して下さい",
    ],
    letters1_3: [
      (v: string | null) =>
        (v != null && 1 <= v.trim().length && v.trim().length <= 3) ||
        "1文字以上3文字以下で入力して下さい",
    ],
    letters0_200: [
      (v: string | null) =>
        (v != null && v.trim().length <= 200) || "200文字以下で入力して下さい",
    ],
    letters0_300: [
      (v: string | null) =>
        (v != null && v.trim().length <= 300) || "300文字以下で入力して下さい",
    ],
    letters1: [
      (v: string | null) =>
        (v != null && v.trim().length > 0) || "1文字以上で入力してください",
    ],
    scheduleDate: [
      (v: string | null) =>
        (v != null && dayjs(v).isSameOrAfter(dayjs(), "day")) ||
        "今日以降の日付を入力して下さい",
    ],
    ruleInputMaintInterval: [
      (v: string | null) =>
        (Number.isInteger(Number(v)) && Number(v) > 0 && Number(v) < 10000) ||
        "1~9999の整数を入力して下さい",
    ],
    ruleMan: [
      (v: string | null) =>
        v == null ||
        v == "" ||
        (Number.isInteger(Number(v)) && Number(v) > 0 && Number(v) < 1000) ||
        "1~999の整数を入力して下さい",
    ],
    ruleHour: [
      (v: string | null) => {
        if (v == null || v === "") return true;
        const num = parseFloat(v);
        if (isNaN(num))
          return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
        if (num <= 0 || num >= 999)
          return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
        if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(v))
          return "0より大きく999未満の数値を入力してください、小数の場合は小数点以下は2桁まで有効です";
        return true;
      },
    ],
    ruleHinmokuNo: [
      (v: string) =>
        /^[!-~｡-ﾟ¥]+$/.test(v) ||
        "半角英数字、半角記号、半角カナで入力してください",
      (v: string) => !!v.trim() || "品目番号をしてください",
    ],
    ruleHinmokuOtherNo: [(v: string) => !!v.trim() || "品目番号をしてください"],
    ruleHinmokuName: [
      (v: string | null) =>
        (v != null && v.trim().length <= 100) || "100文字以下で入力して下さい",
    ],
    ruleHinmokuQuantity: [
      (v: string | null) =>
        (Number.isInteger(Number(v)) && Number(v) >= 0 && Number(v) < 1000) ||
        "0~999の整数を入力して下さい",
    ],
  },
  /**
   * <input>のクリック整備項目をScriptから叩く
   */
  openInput: () => {
    const elm: HTMLElement | null = document.getElementById("img");
    if (elm == null) return;
    elm.click();
  },
  /**
   * <input>のchange整備項目に渡して、画像を読み込みstate.imagesに追加する
   */
  loadImages: async (event: Event) => {
    const elm = event.target;
    if (!(elm instanceof HTMLInputElement)) return;
    if (elm.files == null || elm.files.length == 0) return;

    const sum = elm.files.length + state.maintItem.images.length;
    const limit = 3;
    if (sum <= limit) {
      const uploadImages: { [key: string]: File } = {};
      for (const file of elm.files) {
        // 画像登録APIで使用できるmime-type以外なら処理を行わない
        if (!acceptImageMimeTypes.includes(file.type.toLowerCase())) {
          state.alertSnackBar.title =
            "JPEG画像およびPNG画像以外は登録できません";
          state.alertSnackBar.message = `"${file.name}": ${file.type}`;
          state.alertSnackBar.type = "error";
          state.alertSnackBar.pop = true;
          continue;
        }
        if (file.size > maxImageSize) {
          state.alertSnackBar.title = "4MBより大きい画像は登録できません";
          state.alertSnackBar.message = `"${
            file.name
          }"は4MBより大きいです。 (${(file.size / 1024 / 1024).toFixed(1)}MB)`;
          state.alertSnackBar.type = "error";
          state.alertSnackBar.pop = true;
          continue;
        }

        // ULIDを使用してIDを作成
        const id = ulid();

        // プレビューを早く表示するため、画像アップロードAPI実行前にbase64Urlをstateに格納
        const base64Url = await get_base64_data_url(file);
        state.maintItem.images.push({
          image_id: id,
          extension: file.type,
          name: file.name,
          url: base64Url,
        });

        // 今回アップロードする画像を格納
        uploadImages[id] = file;
      }

      // 画像アップロードAPIを実行
      state.imageUploading = true;
      state.disabled = true;
      try {
        for (const [id, file] of Object.entries(uploadImages)) {
          try {
            const res = await funcs.api.upload_maint_item_image(file, id);

            // APIでアップロード完了後、レスポンス結果を使ってstateを書き換え
            const index = state.maintItem.images.findIndex(
              (i) => i.image_id === id,
            );
            state.maintItem.images[index] = {
              image_id: res.image_id,
              extension: res.extension,
              name: file.name,
              url: res.url,
            };
          } catch (error) {
            // アップロードに失敗したらstateから画像を削除
            state.maintItem.images = state.maintItem.images.filter(
              (i) => i.image_id !== id,
            );

            state.alertSnackBar.title =
              "画像の登録時に不具合が発生しました。再度登録してください。";
            state.alertSnackBar.message = `"${file.name}": ${file.type}`;
            state.alertSnackBar.type = "error";
            state.alertSnackBar.pop = true;
          }
        }
      } finally {
        state.imageUploading = false;
        state.disabled = false;
      }
    } else {
      state.alertSnackBar.title = `添付画像数超過`;
      state.alertSnackBar.message = `添付可能な画像数は${limit}枚までです。`;
      state.alertSnackBar.type = "error";
      state.alertSnackBar.pop = true;
    }
    // <input>をファイルを選択していない状態に戻す
    elm.value = "";
  },
  /**
   * <input>のクリック整備項目をScriptから叩く
   */
  openInputManualFile: () => {
    const elm: HTMLElement | null = document.getElementById("a");
    if (elm == null) return;
    elm.click();
  },
  /**
   * <input>のchange整備項目に渡して、画像を読み込みstate.manualFilesに追加する
   */
  loadManualFiles: async (event: Event) => {
    const elm = event.target;
    if (!(elm instanceof HTMLInputElement)) return;
    if (elm.files == null || elm.files.length == 0) return;
    // 選択したファイルが4個以上 or ファイル選択後に添付ファイル数との合計が4個以上になる場合はエラーを表示
    const sum = elm.files.length + state.maintItem.manual_files.length;
    const limit = 3;
    if (sum <= limit) {
      const uploadManualFiles: { [key: string]: File } = {};
      for (const file of elm.files) {
        // ファイル登録APIで使用できるmime-type以外なら処理を行わない
        if (!acceptFileMimeTypes.includes(file.type.toLowerCase())) {
          state.alertSnackBar.title = "PDFファイル以外は登録できません";
          state.alertSnackBar.message = `"${file.name}": ${file.type}`;
          state.alertSnackBar.type = "error";
          state.alertSnackBar.pop = true;
          continue;
        }
        if (file.size > maxFileSize) {
          state.alertSnackBar.title = "4MBより大きいファイルは登録できません";
          state.alertSnackBar.message = `"${
            file.name
          }"は4MBより大きいです。 (${(file.size / 1024 / 1024).toFixed(1)}MB)`;
          state.alertSnackBar.type = "error";
          state.alertSnackBar.pop = true;
          continue;
        }

        // ULIDを使用してIDを作成
        const id = ulid();

        // ファイルを早く表示するため、ファイルアップロードAPI実行前にbase64Urlをstateに格納
        const base64Url = await get_base64_data_url(file);
        state.maintItem.manual_files.push({
          file_id: id,
          extension: file.type.split("/").pop() ?? "",
          name: file.name.replace(/\.[A-Za-z]+$/, ""), // 拡張子を除く
          url: base64Url,
        });

        // 今回アップロードするファイルを格納
        uploadManualFiles[id] = file;
      }

      // ファイルアップロードAPIを実行
      state.fileUploading = true;
      state.disabled = true;
      try {
        for (const [id, file] of Object.entries(uploadManualFiles)) {
          try {
            const res = await funcs.api.upload_maint_item_manual_file(file, id);

            // APIでアップロード完了後、レスポンス結果を使ってstateを書き換え
            const index = state.maintItem.manual_files.findIndex(
              (i) => i.file_id === id,
            );
            state.maintItem.manual_files[index] = {
              file_id: res.file_id,
              extension: res.extension,
              name: file.name.replace(/\.[A-Za-z]+$/, ""), // 拡張子を除く
              url: res.url,
            };
          } catch (error) {
            // アップロードに失敗したらstateからファイルを削除
            state.maintItem.manual_files = state.maintItem.manual_files.filter(
              (i) => i.file_id !== id,
            );

            state.alertSnackBar.title =
              "ファイルの登録時に不具合が発生しました。再度登録してください。";
            state.alertSnackBar.message = `"${file.name}": ${file.type}`;
            state.alertSnackBar.type = "error";
            state.alertSnackBar.pop = true;
          }
        }
      } finally {
        state.fileUploading = false;
        state.disabled = false;
      }
    } else {
      state.alertSnackBar.title = "添付ファイル数超過";
      state.alertSnackBar.message = `添付可能なファイル数は${limit}個までです。`;
      state.alertSnackBar.type = "error";
      state.alertSnackBar.pop = true;
    }
    // <input>をファイルを選択していない状態に戻す
    elm.value = "";
  },
  /**
   * 工数（人）が空欄の場合はnullに変換する
   * 入力がある場合は数値型に変換する
   */
  convertManInput: (inputData: string | number | null) => {
    return Number(inputData) == 0 ? null : Number(inputData);
  },
  /**
   * 工数（時間）が空欄の場合はnullに変換する
   * 入力データは文字列型に変換する（元々入力済みの場合は数値型のため）
   */
  convertHoursInput: (inputData: string | number | null) => {
    return Number(inputData) == 0 ? null : String(inputData);
  },
  /**
   * 整備部品の個数が空欄の場合は0に変換する
   * 入力がある場合は数値型に変換する
   */
  convertHinmokusQuantityInput: (inputData: number | null) => {
    return Number(inputData) == 0 ? Number(0) : Number(inputData);
  },
  /**
   * APIとの通信を行うための関数群
   */
  api: {
    /**
     * API getMaintItemを叩く
     * @return {Promise<ApiResponse>} getMaintItemの結果
     */
    get_maint_item: async (): Promise<ApiResponse> => {
      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          const resp = await connectToApi<ApiResponse>({
            url: "/api/getMaintItem",
            method: "GET",
            params: {
              maint_item_id: prop.maintItemId,
            },
          });
          return resp.data;
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }
      throw err;
    },
    /**
     * API uploadMaintItemImageを叩く
     * @param image {File} アップロードする画像の情報
     * @return {Promise<ImageUploadApiResponse>} 画像アップロードAPIのレスポンス
     */
    upload_maint_item_image: async (
      image: File,
      id: string,
    ): Promise<ImageUploadApiResponse> => {
      const plant_id = storeAuthorities.selectedAuthority.plantId;

      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          const response = await connectToApi<ImageUploadApiResponse>({
            method: "POST",
            url: `/api/uploadMaintItemImage`,
            headers: {
              "Content-Type": image.type,
            },
            data: image,
            params: {
              plant_id: plant_id,
              image_id: id,
            },
          });

          return response.data;
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }

      console.error("api.upload_maint_item_image", err);
      throw err;
    },
    /**
     * API uploadMaintItemFileを叩く
     * @param manualFile {File} アップロードするファイルの情報
     * @return {Promise<FileUploadApiResponse>} ファイルアップロードAPIのレスポンス
     */
    upload_maint_item_manual_file: async (
      manualFile: File,
      id: string,
    ): Promise<FileUploadApiResponse> => {
      const plant_id = storeAuthorities.selectedAuthority.plantId;

      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          const response = await connectToApi<FileUploadApiResponse>({
            method: "POST",
            url: `/api/uploadMaintItemFile`,
            headers: {
              "Content-Type": manualFile.type,
            },
            data: manualFile,
            params: {
              plant_id: plant_id,
              file_id: id,
            },
          });

          return response.data;
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }

      console.error("api.upload_maint_item_manual_file", err);
      throw err;
    },

    /**
     * API updateMaintItemを叩く
     * @param maintItem {MaintItemState} 更新させたいmaint_itemの内容
     */
    update_maint_item: async (maintItem: MaintItemState): Promise<null> => {
      let count = 3;
      let err: any = null;
      // 工数の値を変換
      state.maintItem.man_in_man_hours = funcs.convertManInput(
        state.maintItem.man_in_man_hours,
      );
      state.maintItem.hour_in_man_hours = funcs.convertHoursInput(
        state.maintItem.hour_in_man_hours,
      );
      // 整備部品の個数の値を変換
      state.maintItem.hinmokus_mhi = state.maintItem.hinmokus_mhi.map(
        (item) => {
          return {
            ...item,
            quantity: funcs.convertHinmokusQuantityInput(item.quantity),
          };
        },
      );
      state.maintItem.hinmokus_others = state.maintItem.hinmokus_others.map(
        (item) => {
          return {
            ...item,
            quantity: funcs.convertHinmokusQuantityInput(item.quantity),
          };
        },
      );
      while (count !== 0) {
        try {
          const days_of_maint_interval = Number(
            state.maintItem.days_of_maint_interval,
          );
          if (Number.isNaN(days_of_maint_interval)) {
            console.log({
              msg: "作業周期の値が不正です",
              value: state.maintItem.days_of_maint_interval,
            });
            throw new Error("failed to convert number");
          }
          state.maintItem.days_of_maint_interval = days_of_maint_interval;
          await connectToApi({
            method: "POST",
            url: "/api/updateMaintItem",
            headers: {
              "Content-Type": "application/json",
            },
            data: maintItem,
          });
          return null;
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }

      console.error("update_maint_item", err);
      throw err;
    },
    /**
     * API deleteMaintItemを叩く
     * @param maint_item_id {number} 削除対象のmaint_itemのID
     */
    delete_maint_item: async (maint_item_id: number) => {
      let count = 3;
      let err: any = null;
      while (count !== 0) {
        try {
          await connectToApi({
            method: "POST",
            url: "/api/deleteMaintItem",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              maint_item_id: maint_item_id,
            },
          });
          return;
        } catch (e) {
          err = e;
          count -= 1;
          await sleep(1);
        }
      }

      console.error("delete_maint_item", err);
      // APIからエラーメッセージが返っていれば、エラーインスタンスに渡す
      let message = err?.response?.data?.message;
      if (typeof message !== "string") {
        message = "整備項目の削除に失敗しました";
      }

      let isCompletedOrDeleted = err?.response?.data?.isCompletedOrDeleted;
      if (typeof isCompletedOrDeleted !== "boolean") {
        isCompletedOrDeleted = false;
      }

      throw new DeleteMaintItemError(message, isCompletedOrDeleted);
    },
  },
  /**
   * 編集対象の整備項目の情報を取得する
   */
  get_maint_item: async () => {
    state.loading = true;
    state.registerLoading = true;
    state.disabled = true;

    try {
      const resp = await funcs.api.get_maint_item();
      state.maintItem = {
        maint_item_id: resp.maint_item.maint_item_id,
        maint_task_id: resp.maint_item.maint_task_id,
        plant_id: resp.maint_item.plant_id,
        item_no: resp.maint_item.item_no,
        order: resp.maint_item.order,
        maint_item_name: resp.maint_item.maint_item_name,
        set: resp.maint_item.set,
        subject1: resp.maint_item.subject1,
        subject2: resp.maint_item.subject2,
        subject3: resp.maint_item.subject3,
        days_of_maint_interval: resp.maint_item.days_of_maint_interval,
        scheduled_date: resp.maint_item.scheduled_date,
        maint_item_description: resp.maint_item.maint_item_description,
        man_in_man_hours: resp.maint_item.man_in_man_hours,
        hour_in_man_hours: resp.maint_item.hour_in_man_hours,
        has_today_completed_task: resp.maint_item.has_today_completed_task,
        manual_links: resp.maint_item.manual_links ?? [],
        // ファイルのIdとしてULIDを採用しているので、先に登録したものが前に来るようにソートする
        images: resp.maint_item.images.sort((x, y) =>
          x.image_id > y.image_id ? 1 : -1,
        ),
        manual_files: resp.maint_item.manual_files.sort((x, y) =>
          x.file_id > y.file_id ? 1 : -1,
        ),
        hinmokus_mhi: resp.maint_item.hinmokus_mhi ?? [],
        hinmokus_others: resp.maint_item.hinmokus_others ?? [],
      };
      state.datepicker.inputDate = dayjs(
        resp.maint_item.scheduled_date,
      ).toDate();
    } catch (e: any) {
      let message = e?.response?.data?.message;
      if (typeof message !== "string") {
        message = "整備項目編集の初期データ取得時に不具合が発生しました。";
      }

      let isCompletedOrDeleted = e?.response?.data?.isCompletedOrDeleted;
      if (typeof isCompletedOrDeleted !== "boolean") {
        isCompletedOrDeleted = false;
      }
      // 整備項目情報が得られなければ、強制的にリダイレクトして編集画面から出る
      const location = funcs.create_redirect_location();
      location.query = {
        edit: "error",
        edit_target: "maintItem",
        message: message,
        title: isCompletedOrDeleted
          ? "完了もしくは削除された整備項目は編集できません"
          : "整備項目の取得に失敗しました",
      };

      await router.push(location);
    } finally {
      state.loading = false;
      state.registerLoading = false;
      state.disabled = false;
    }
  },
  /**
   * 整備項目を更新する
   */
  update_maint_item: async () => {
    state.disabled = true;
    state.registerLoading = true;
    funcs.api
      .update_maint_item(state.maintItem)
      .then(() => {
        const location = funcs.create_redirect_location();
        location.query = {
          edit: "update",
          edit_target: "maintItem",
        };

        router.push(location);
      })
      .catch((e) => {
        console.error(e);
        state.alertSnackBar.message =
          e.response !== undefined
            ? e.response.data.message
            : "データの更新に関する不具合が発生しています。";
        state.alertSnackBar.title = "整備項目の更新に失敗しました";
        state.alertSnackBar.type = "error";
        state.alertSnackBar.pop = true;
      })
      .finally(() => {
        state.disabled = false;
        state.registerLoading = false;
      });
  },
  /**
   * 整備項目の削除を行う
   */
  delete_maint_item: async () => {
    const confirmMessage = "整備項目を削除してもよろしいでしょうか？";
    const confirmed = confirm(confirmMessage);
    if (!confirmed) {
      return;
    }

    state.disabled = true;
    state.registerLoading = true;
    try {
      await funcs.api.delete_maint_item(state.maintItem.maint_item_id);

      const location = funcs.create_redirect_location();
      location.query = {
        edit: "delete",
        edit_target: "maintItem",
      };
      await router.push(location);
    } catch (e: any) {
      state.alertSnackBar.title = "整備項目の削除に失敗しました";
      state.alertSnackBar.message = "";
      if (e instanceof DeleteMaintItemError) {
        state.alertSnackBar.message = e.message;

        if (e.isCompletedOrDeleted) {
          const location = funcs.create_redirect_location();
          location.query = {
            edit: "error",
            edit_target: "maintItem",
            message: "完了または削除された整備項目は削除することはできません。",
            title: "整備項目の削除に失敗しました",
          };
          await router.push(location);
          return;
        }
      } else if (e instanceof Error) {
        state.alertSnackBar.message = e.message;
      } else if (typeof e?.message === "string") {
        state.alertSnackBar.message = e.message;
      }

      state.alertSnackBar.pop = true;
    } finally {
      state.disabled = false;
      state.registerLoading = false;
    }
  },
  /**
   * 画像のプレビューにおいて、削除ボタンを押した時の処理
   * @param image
   */
  delete_image: (image: Image) => {
    state.maintItem.images = state.maintItem.images.filter(
      (i) => i.image_id !== image.image_id,
    );
  },
  /**
   * ファイルのプレビューにおいて、削除ボタンを押した時の処理
   * @param manualFile
   */
  delete_manual_file: (file: ManualFile) => {
    state.maintItem.manual_files = state.maintItem.manual_files.filter(
      (f) => f.file_id !== file.file_id,
    );
  },
  // オートコンプリートのネタ用
  getMaintenanceClassification: async () => {
    state.comboboxLoading = true;
    try {
      const classification = await requestGetMaintenanceClassification(
        storeAuthorities.selectedAuthority.plantId,
      );
      comboBoxItems.setItems = classification.set;
      comboBoxItems.subject1Items = classification.subject1;
      comboBoxItems.subject2Items = classification.subject2;
      comboBoxItems.subject3Items = classification.subject3;
    } catch (e: any) {
      state.alertSnackBar.title = "データの取得に失敗しました。";
      state.alertSnackBar.message =
        e.response !== undefined
          ? e.response.data.message
          : "データの取得に関する不具合が発生しています。";
      state.alertSnackBar.type = "error";
      state.alertSnackBar.pop = true;
    } finally {
      state.comboboxLoading = false;
    }
  },
  /**
   * 完了作業を含んでいるか確認する
   * 含んでいる場合はエラーを返す
   */
  checkHasCompletedTask: async () => {
    if (state.maintItem.has_today_completed_task) {
      const message =
        "本日完了した作業に関する整備項目は変更できません。明日以降行うか、当該作業を未完了に戻してから行って下さい。";
      const location = funcs.create_redirect_location();
      location.query = {
        edit: "error",
        message: message,
        edit_target: "maintItem",
      };
      await router.push(location);
    }
  },
  /**
   * マニュアルリンク追加用モーダルを表示する
   */
  openManualLinkModal: () => {
    state.manualLinkModal.title = "";
    state.manualLinkModal.url = "";
    refFormManualLinkModal.value?.reset();
    state.manualLinkModal.isShow = true;
  },
  /**
   * マニュアルリンクを追加
   */
  addManualLink: () => {
    const title = state.manualLinkModal.title?.trim();
    const url = state.manualLinkModal.url.trim();
    const link: ManualLink = {
      title: title || url,
      url: url,
    };
    state.maintItem.manual_links.push(link);
    state.manualLinkModal.isShow = false;
  },
  /**
   * 指定したindexのマニュアルリンクを消す
   */
  deleteManualLink: (index: number) => {
    state.maintItem.manual_links.splice(index, 1);
  },
  /**
   * 整備部品（MHI）の入力フォームを追加する
   */
  addInputHinmokuFrom: () => {
    state.maintItem.hinmokus_mhi.push({
      hinmoku_no: "",
      name: "",
      quantity: null,
    });
  },
  /**
   * 指定したindexの整備部品（MHI）を消す
   */
  deleteHinmoku: (index: number) => {
    state.maintItem.hinmokus_mhi.splice(index, 1);
  },
  /**
   * 整備部品（MHI以外）の入力フォームを追加する
   */
  addInputHinmokuOtherFrom: () => {
    state.maintItem.hinmokus_others.push({
      hinmoku_no: "",
      name: "",
      quantity: null,
    });
  },
  /**
   * 指定したindexの整備部品（MHI以外）を消す
   */
  deleteHinmokuOther: (index: number) => {
    state.maintItem.hinmokus_others.splice(index, 1);
  },
};

// v-datepickerにおいて値が更新されたら、表示用の値とstate.maintItem.scheduled_dateを更新する
watch(
  () => state.datepicker.inputDate,
  () => {
    if (!state.datepicker.inputDate) {
      state.datepicker.displayDate = "";
      state.maintItem.scheduled_date = "";
      return;
    }
    const d = dayjs(state.datepicker.inputDate);
    state.datepicker.displayDate = d.format("YYYY/MM/DD");
    state.maintItem.scheduled_date = d.format("YYYYMMDD");
  },
);

// 添付ファイル数を監視し、4つ以上添付された場合はバーを表示
watch(
  () => state.maintItem.manual_files.length,
  () => {
    if (state.maintItem.manual_files.length > 3) {
      state.alertSnackBar.title = "添付ファイル数超過";
      state.alertSnackBar.message = "添付可能なファイル数は3つまでです。";
      state.alertSnackBar.type = "error";
      state.alertSnackBar.pop = true;
    }
  },
);

const init = async () => {
  if (!storeAuthorities.roleChecker.isUserAdmin) {
    // 管理者権限でない場合はTOP画面に戻る
    // 通常ありえないので遷移先でのスナックバーメッセージはなし
    router.push({
      path: "/",
    });
  }
  // 整備項目データを取得する
  await funcs.get_maint_item();
  await funcs.checkHasCompletedTask();
  await funcs.getMaintenanceClassification();

  if (state.maintItem.scheduled_date) {
    state.datepicker.inputDate = dayjs(state.maintItem.scheduled_date).toDate();
    state.datepicker.displayDate = dayjs(state.maintItem.scheduled_date).format(
      "YYYY/MM/DD",
    );
  }
};

init();
</script>

<style scoped>
.input-field-22c {
  width: 400px;
}
.input-field-3c {
  max-width: 150px;
}
.input-field-color {
  max-width: 155px;
}
.datepicker-textfield {
  width: 180px;
}
.img_area {
  border: 1px dashed #a19a9a;
  height: 100px;
}
.upload-file-name {
  cursor: default;
}
.delete-button {
  width: 20px;
  height: 20px;
}
.complement_font {
  color: #6b6d75;
}
a.cancel-btn::before {
  opacity: 0;
}
</style>
