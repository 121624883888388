import dayjs, { Dayjs } from "dayjs";

export type MaintTaskData = {
  /**
   * 整備項目ID
   */
  readonly maint_item_id: number | null;
  /**
   * 整備作業ID
   */
  readonly id: number;
  /**
   * 作業名
   */
  readonly name: string;
  /**
   * セット
   */
  readonly set: string;
  /**
   * 対象1
   */
  readonly subject1: string;
  /**
   * 対象2
   */
  readonly subject2: string;
  /**
   * 対象3
   */
  readonly subject3: string;
  /**
   * 整備計画日
   */
  taskScheduledDate: string;
  /**
   * 完了日
   */
  taskCompletedDate: string | undefined | null;
  /**
   * イベントデータフラグ
   */
  readonly is_event: boolean;
  /**
   * 未読フラグ
   */
  readonly unread?: boolean;
};

export class MaintTask implements MaintTaskData {
  //MaintTaskData定義パラメータ=dbから取得するパラメータ
  readonly maint_item_id: number | null;
  readonly id: number;
  readonly name: string;
  readonly set: string;
  readonly subject1: string;
  readonly subject2: string;
  readonly subject3: string;
  readonly is_event: boolean;
  readonly unread: boolean;
  /**
   * db取得時を除き直接書き換えない
   */
  taskScheduledDate: string;
  /**
   * db取得時を除き直接書き換えない
   */
  taskCompletedDate: string | undefined | null;

  //データ操作用
  taskScheduledDateObj: Dayjs;
  taskCompletedDateObj: Dayjs | undefined | null;

  //UI用追加パラメータ
  readonly fullName: string;
  done: boolean;
  status: "waiting" | "late" | "completed";
  color_sub: string;
  color_main: string;

  static readonly CALENDAR_MAIN_COLOR_COMPLETED = "rgba(33,33,33,0.06)";
  static readonly CALENDAR_SUB_COLOR_COMPLETED = "#B5B6BA";
  static readonly CALENDAR_MAIN_COLOR_WAITING = "#E2F6F9";
  static readonly CALENDAR_SUB_COLOR_WAITING = "#007EA8";
  static readonly CALENDAR_MAIN_COLOR_LATE = "#FEF3F2";
  static readonly CALENDAR_SUB_COLOR_LATE = "#B42318";

  static readonly CALENDAR_MAIN_COLOR = {
    waiting: MaintTask.CALENDAR_MAIN_COLOR_WAITING,
    late: MaintTask.CALENDAR_MAIN_COLOR_LATE,
    completed: MaintTask.CALENDAR_MAIN_COLOR_COMPLETED,
  };
  static readonly CALENDAR_SUB_COLOR = {
    waiting: MaintTask.CALENDAR_SUB_COLOR_WAITING,
    late: MaintTask.CALENDAR_SUB_COLOR_LATE,
    completed: MaintTask.CALENDAR_SUB_COLOR_COMPLETED,
  };

  constructor(maintTaskData: MaintTaskData) {
    this.maint_item_id = maintTaskData.maint_item_id;
    this.id = maintTaskData.id;
    this.name = maintTaskData.name;
    this.set = maintTaskData.set;
    this.subject1 = maintTaskData.subject1;
    this.subject2 = maintTaskData.subject2;
    this.subject3 = maintTaskData.subject3;
    this.taskScheduledDate = maintTaskData.taskScheduledDate;
    this.taskCompletedDate = maintTaskData.taskCompletedDate;
    this.is_event = maintTaskData.is_event;
    this.unread = maintTaskData.unread ?? false;

    //TODO 以下はprivate methodに切り出したいが、privateメソッド作ると
    //const maintTaskList: MaintTask[] = reactive([
    //  new MaintTask({})の型判定でエラーを吐いてしまう。
    this.taskScheduledDateObj = dayjs(this.taskScheduledDate);
    this.taskCompletedDateObj =
      this.taskCompletedDate == undefined
        ? undefined
        : dayjs(this.taskCompletedDate);

    //カレンダー表示用
    this.fullName = [
      this.name,
      this.set,
      this.subject1,
      this.subject2,
      this.subject3,
    ]
      .filter((value) => value != "-")
      .join("-");

    //整備計画日と完了日からステータス
    if (this.taskCompletedDateObj != undefined) this.status = "completed";
    else if (
      this.taskScheduledDateObj.endOf("day").isAfter(dayjs().startOf("day"))
    )
      this.status = "waiting";
    else this.status = "late";

    //ステータスから完了フラグ
    this.done = this.status == "completed";

    //ステータスからカレンダー色
    this.color_main = MaintTask.CALENDAR_MAIN_COLOR[this.status];
    this.color_sub = MaintTask.CALENDAR_SUB_COLOR[this.status];
  }

  /**
   * ダミーデータを生成。コンポーネントに対して暫定的に適当なMaintTaskデータを渡したいときに使用。
   * @returns ダミーデータ
   */
  static dummy(): MaintTask {
    return new MaintTask({
      maint_item_id: -1,
      id: -1,
      name: "",
      set: "",
      subject1: "",
      subject2: "",
      subject3: "",
      taskCompletedDate: "",
      taskScheduledDate: "",
      is_event: false,
      unread: false,
    });
  }

  /**
   * 作業完了を設定する
   */
  public completed(completedDate: Dayjs) {
    this.taskCompletedDate = completedDate.format("YYYY-MM-DD");
    this.status = "completed";
    this.done = true;
  }

  /**
   * 完了した作業をキャンセルする
   */
  public cancel() {
    this.taskCompletedDateObj = null;
    this.taskCompletedDate = null;
    this.done = false;

    const now = dayjs();
    if (now < this.taskScheduledDateObj) {
      this.status = "waiting";
    } else if (this.taskScheduledDateObj < now) {
      this.status = "late";
    }
  }

  /**
   * 整備計画日変更を設定する
   *
   * @param editedTaskScheduledDateObj ：変更後整備計画日のDayjsオブジェクト
   */
  public setTaskScheduledDate(editedTaskScheduledDateObj: Dayjs) {
    this.taskScheduledDateObj = editedTaskScheduledDateObj;
    this.taskScheduledDate = this.taskScheduledDateObj.format("YYYY-MM-DD");
    if (this.taskScheduledDateObj.endOf("day").isAfter(dayjs().startOf("day")))
      this.status = "waiting";
    else this.status = "late";

    this.color_main = MaintTask.CALENDAR_MAIN_COLOR[this.status];
    this.color_sub = MaintTask.CALENDAR_SUB_COLOR[this.status];
  }

  /**
   * ステータスの大小関係を定義する、比較用の関数。
   * 主にソートする際に用いる。
   *
   * @param status1 比較したいstatus
   * @param status2 比較したいもう一つのstatus
   * @returns status1のほうが大きければ正の数、status2のほうが大きければ負の数、status1とstatus2が等価であれば0
   */
  public static compareStatus(status1: string, status2: string): number {
    const STATUS_ORDER: { [key: string]: number } = {
      late: 1, // ソートしたときに遅延は最上部に表示できるようにするため、最も小さい値を割り当てておく
      waiting: 2,
      completed: 3,
    };
    return STATUS_ORDER[status1] - STATUS_ORDER[status2];
  }
}
