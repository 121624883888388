// HOJYUの品目参照API： https://github.com/mhided/hojyu-ppm-lambda/blob/main/return-hinmoku-detail/swagger.json

import { MhiPartsSearchResult } from "@/models/mhiPartsSearchResult";
import { getTokenSilent } from "@/auth";

export type HinmokuApiResponse = {
  hinmokus: {
    [key: string]: {
      url: string;
      hinmokuName: string;
    };
  };
};

export async function requestHinmoku(
  hinmokuNos: string[],
): Promise<MhiPartsSearchResult> {
  try {
    // local環境の場合のみ、APIのURLを変更する
    const url =
      window.location.host === "localhost:8080"
        ? "http://localhost:8080/api/hinmokus"
        : import.meta.env.VITE_APP_HOJYU_HINMOKU_API || "";
    const urlObject = new URL(url);
    const params = hinmokuNos;
    params.forEach((value) =>
      urlObject.searchParams.append("hinmokuNos", value),
    );

    const { accessToken } = await getTokenSilent();
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${accessToken}`);

    const options = {
      method: "GET",
      headers: headers,
    };

    const response = await fetch(urlObject, options);

    return new MhiPartsSearchResult(await response.json());
  } catch (e) {
    // 呼び出しに失敗した場合は空の検索結果を返す。例外は投げない
    return MhiPartsSearchResult.empty();
  }
}
