import { connectToApi } from "@/helpers/connectToApi";

type MaintenanceClassification = {
  set: string[];
  subject1: string[];
  subject2: string[];
  subject3: string[];
};

type GetMaintenanceClassificationApiResponse = {
  set: string[];
  subject1: string[];
  subject2: string[];
  subject3: string[];
};

/**
 * plant_idで取得できるMaintItem、MaintEventに登録されているセット、対象1〜3をもとに、ユニークなセット、対象1〜3の配列を作る
 * @param plantId プラントID
 * @returns セット、対象1〜3
 */
export async function requestGetMaintenanceClassification(
  plantId: string,
): Promise<MaintenanceClassification> {
  const response = await connectToApi<GetMaintenanceClassificationApiResponse>({
    method: "GET",
    url: "/api/getMaintenanceClassification",
    params: {
      plant_id: plantId,
    },
  });
  return {
    set: Array.from(response.data.set).sort(),
    subject1: Array.from(response.data.subject1).sort(),
    subject2: Array.from(response.data.subject2).sort(),
    subject3: Array.from(response.data.subject3).sort(),
  };
}
