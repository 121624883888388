<template>
  <div class="text-center">
    <v-dialog v-model="innerOpenStatus" width="480">
      <v-card>
        <v-card-title> 過去日で完了にする </v-card-title>
        <v-card-text class="pt-1 pb-1 text-body-2"
          >整備計画日を過ぎている作業を完了させますか？<br />
          遅延作業を完了させると、次回の作業が生成されます。</v-card-text
        >
        <v-card-actions class="justify-end mt-1">
          <v-btn variant="outlined" @click="innerOpenStatus = false"
            >キャンセル</v-btn
          >
          <v-btn
            color="primary"
            :loading="state.loading"
            variant="flat"
            @click="completePastMaintTask"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { AlertSnackBarModel } from "@/models/snackbar";
import { requestReportPastTaskCompletion } from "@/helpers/api/reportPastTaskCompletion";
import { PastTaskCompletionResult } from "@/models/pastTaskCompletionResult";

type Emits = {
  OpenStatusUpdateRequest: [value: boolean];
  SnackBar: [snackBar: AlertSnackBarModel];
  PastCompletionResult: [pastCompletionResult: PastTaskCompletionResult];
};

type Props = {
  pastMaintTaskCompleteDialogOpenStatus: boolean;
  maintTaskId: number;
};

const prop = defineProps<Props>();

const emit = defineEmits<Emits>();

const innerOpenStatus = computed({
  get: () => prop.pastMaintTaskCompleteDialogOpenStatus,
  set: (value: boolean) => emit("OpenStatusUpdateRequest", value),
});

const state = reactive({
  loading: false,
});

const completePastMaintTask = async () => {
  state.loading = true;

  try {
    const result = await requestReportPastTaskCompletion(prop.maintTaskId);

    innerOpenStatus.value = false;
    emit("PastCompletionResult", result);

    const snackBar: AlertSnackBarModel = {
      isShow: true,
      type: "success",
      title: "",
      message: "過去日で整備作業を完了しました。",
    };
    emit("SnackBar", snackBar);
  } catch (e) {
    const snackBar: AlertSnackBarModel = {
      isShow: true,
      type: "error",
      title: "",
      message: "過去日での整備作業完了に関する不具合が発生しています。",
    };
    emit("SnackBar", snackBar);
  } finally {
    state.loading = false;
  }
};
</script>

<style scoped>
div.maint-task-date-area {
  border-radius: 5px;
  margin: 0 12px;
  padding: 16px;
}
</style>
