import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import type { ThemeDefinition } from "vuetify";
import { createVuetify } from "vuetify";
import "vuetify/styles";

import DateFnsAdapter from "@date-io/date-fns";
import { ja } from "date-fns/locale";

const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    // secondary, accent の2つは、未使用のため未定義
    primary: "#007EA8",
    error: "#F04438",
    info: "#2E90FA",
    success: "#12B76A",
    warning: "#F79009",
  },
};
const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi", // default - only for display purposes
  },
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
  date: {
    adapter: new DateFnsAdapter({
      formats: {
        year: "yyyy年",
        monthAndYear: "yyyy年M月",
        normalDateWithWeekday: "M月d日(E)",
      },
      locale: ja,
    }),
  },
});

export default vuetify;
