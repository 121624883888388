<template>
  <past-maint-task-complete-dialog
    v-if="state.showPastComplete"
    :past-maint-task-complete-dialog-open-status="
      state.pastMaintTaskCompleteOpenStatus
    "
    :maint-task-id="state.currentMaintTaskId"
    @open-status-update-request="pastMaintTaskCompleteDialogStatusHandler"
    @snack-bar="snackBarHandler"
    @past-completion-result="pastCompletionResultHandler"
  />
  <div class="text-center">
    <v-dialog v-model="innerOpenStatus" width="80%" scrollable>
      <v-card>
        <v-card-item class="text-h5">
          整備項目詳細
          <template #append>
            <v-btn
              icon
              size="small"
              variant="text"
              @click="innerOpenStatus = false"
            >
              <v-icon size="x-large">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-card-item>
        <v-card-text class="text-body-2">
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              整備項目名
            </v-col>
            <v-col cols="9" class="text-grey-darken-1">
              {{ maintItem.maintItemName }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              セット・対象
            </v-col>
            <v-col cols="9" class="text-grey-darken-1">
              {{
                [
                  maintItem.set,
                  maintItem.subject1,
                  maintItem.subject2,
                  maintItem.subject3,
                ].join(" ")
              }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              整備計画日
            </v-col>
            <v-col cols="6" class="text-grey-darken-1">
              <span :class="calcClassScheduledDate()">{{
                state.scheduledDate.format("YYYY/MM/DD")
              }}</span>
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <v-btn
                v-if="state.showPastComplete && isUserAdmin"
                variant="outlined"
                size="small"
                @click="openPastCompleteTargetMaintTaskDialog"
                >完了にする</v-btn
              >
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1"> 作業周期 </v-col>
            <v-col cols="9" class="text-grey-darken-1">
              {{ maintItem.daysOfMaintInterval }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1"> 整備部品 </v-col>
            <v-col cols="9">
              <span class="text-grey-darken-1"> MHIから購入する部品 </span>
              <v-data-table
                density="compact"
                :headers="partsHeaders"
                :items="maintItem.hinmokusMhi"
                :items-per-page="-1"
                no-data-text="整備部品データなし"
                class="parts-list"
              >
                <template #[`item.hinmoku_no`]="{ item }">
                  <td
                    v-if="mhiPartsSearchResult.isExist(item.hinmoku_no)"
                    class="text-truncate"
                    style="max-width: 9vw"
                  >
                    <a
                      :href="mhiPartsSearchResult.getUrl(item.hinmoku_no)"
                      target="_blank"
                      rel="noopener"
                      class="text-primary"
                      >{{ item.hinmoku_no }}</a
                    >
                  </td>
                  <td v-else class="text-truncate" style="max-width: 9vw">
                    {{ item.hinmoku_no }}
                  </td>
                </template>
                <template #[`item.name`]="{ item }">
                  <td class="text-truncate" style="max-width: 35vw">
                    {{ item.name }}
                  </td>
                </template>
                <template #bottom></template>
              </v-data-table>
              <span class="text-grey-darken-1"> その他部品（MHI以外） </span>
              <v-data-table
                density="compact"
                :headers="partsHeaders"
                :items="maintItem.hinmokusOthers"
                :items-per-page="-1"
                no-data-text="整備部品データなし"
                class="parts-list"
              >
                <template #[`item.hinmoku_no`]="{ item }">
                  <td class="text-truncate" style="max-width: 9vw">
                    {{ item.hinmoku_no }}
                  </td>
                </template>
                <template #[`item.name`]="{ item }">
                  <td class="text-truncate" style="max-width: 35vw">
                    {{ item.name }}
                  </td>
                </template>
                <template #bottom></template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              整備作業の説明
            </v-col>
            <v-col
              cols="9"
              class="text-grey-darken-1"
              style="white-space: pre-line; word-break: break-all"
            >
              {{ maintItem.description }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              整備詳細画像
            </v-col>
            <v-col cols="9">
              <v-row v-if="maintItem.images.length > 0">
                <v-col
                  v-for="image in maintItem.images"
                  :key="image.url"
                  cols="3"
                >
                  <v-card
                    color="grey-lighten-2"
                    :href="image.url"
                    target="_blank"
                    class="rounded pa-1"
                    variant="outlined"
                    height="120px"
                    width="120px"
                  >
                    <img
                      alt="ユーザーによってアップロードされた画像"
                      :src="image.url"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-card
                    color="grey-lighten-2"
                    class="rounded d-flex align-center"
                    variant="outlined"
                    height="120px"
                    width="120px"
                  >
                    <v-card-text class="pa-5 text-center text-grey-darken-1">
                      写真はまだ添付されていません
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1">
              整備詳細マニュアル
            </v-col>
            <v-col cols="9">
              <v-row v-if="maintItem.manualFiles.length > 0">
                <v-col class="text-grey-darken-1">PDFファイル</v-col>
                <v-col
                  v-for="manualFile in maintItem.manualFiles"
                  :key="manualFile.url"
                  cols="12"
                  class="mt-n4"
                >
                  <v-icon size="small"> mdi-paperclip </v-icon>
                  <a
                    :href="manualFile.url"
                    target="_blank"
                    class="text-primary"
                  >
                    {{ `${manualFile.name}.${manualFile.extension}` }}
                  </a>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="text-grey-darken-1">PDFファイル</v-col>
                <v-col cols="12" class="mt-n4 no-item text-grey-darken-1">
                  ファイルはまだ添付されていません
                </v-col>
              </v-row>
              <v-row v-if="maintItem.manualLinks.length > 0">
                <v-col class="text-grey-darken-1">リンク</v-col>
                <v-col
                  v-for="link in maintItem.manualLinks"
                  :key="link.url"
                  cols="12"
                  class="mt-n4"
                >
                  <a
                    :href="link.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-primary"
                    >{{ link.title }}</a
                  >
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="text-grey-darken-1">リンク</v-col>
                <v-col cols="12" class="mt-n4 no-item text-grey-darken-1">
                  リンクはありません
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="my-0">
            <v-col cols="3" class="px-10 text-grey-darken-1"> 工数 </v-col>
            <v-col cols="9" class="text-grey-darken-1">{{
              createManHoursText(maintItem)
            }}</v-col>
          </v-row>
          <v-divider />
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn
            v-if="isUserAdmin"
            color="primary"
            variant="outlined"
            :to="goto_update_event"
          >
            編集画面へ移動する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from "vue";
import { MaintItem } from "@/models/MaintItem";
import { RouteLocationRaw, useRoute } from "vue-router";
import { MhiPartsSearchResult } from "@/models/mhiPartsSearchResult";
import { useAuthoritiesStore } from "@/stores/authorities";
import { AlertSnackBarModel } from "@/models/snackbar";
import PastMaintTaskCompleteDialog from "@/components/PastMaintTaskCompleteDialog.vue";
import dayjs from "dayjs";
import { PastTaskCompletionResult } from "@/models/pastTaskCompletionResult";

type Emits = {
  OpenStatusUpdateRequest: [value: boolean];
  SnackBar: [snackBar: AlertSnackBarModel];
  NeedRefresh: [needRefresh: boolean];
};

type Props = {
  maintItemDetailOpenStatus: boolean;
  maintItem: MaintItem;
  mhiPartsSearchResult: MhiPartsSearchResult;
};

type LocationData = {
  path: string;
  query: { [key: string]: string };
};

const prop = defineProps<Props>();

const emit = defineEmits<Emits>();

const storeAuthorities = useAuthoritiesStore();
const isUserAdmin = storeAuthorities.roleChecker.isUserAdmin;

type State = {
  pastMaintTaskCompleteOpenStatus: boolean;
  scheduledDate: dayjs.Dayjs;
  showPastComplete: boolean;
  pastCompleted: boolean; // 遅延作業の完了操作が画面上で行われたか
  currentMaintTaskId: number; // 最新のMaintTaskId 遅延作業過去完了により更新される
};

const state = reactive<State>({
  pastMaintTaskCompleteOpenStatus: false,
  scheduledDate: prop.maintItem.scheduledDate,
  showPastComplete: prop.maintItem.pastCompletionAvailable,
  pastCompleted: false,
  currentMaintTaskId: prop.maintItem.maintItemId,
});

/* 表示する整備項目が別のものになればstateが必ず更新されるよう、maintItemのidをwatch */
watch(
  () => prop.maintItem.maintItemId,
  () => {
    state.scheduledDate = prop.maintItem.scheduledDate;
    state.showPastComplete = prop.maintItem.pastCompletionAvailable;
    state.pastCompleted = false;
    state.currentMaintTaskId = prop.maintItem.maintTaskId;
  },
);

const route = useRoute();

const partsHeaders = [
  { title: "品目番号", value: "hinmoku_no", sortable: false, width: "20%" },
  { title: "名称など", value: "name", sortable: false, width: "70%" },
  { title: "数量", value: "quantity", sortable: false, align: "center" },
] as const;

const innerOpenStatus = computed({
  get: () => prop.maintItemDetailOpenStatus,
  set: (value: boolean) => emit("OpenStatusUpdateRequest", value),
});

/**
 * 整備計画日の表示を更新するためのクラスを計算
 */
const calcClassScheduledDate = () => {
  return {
    "updated-text": state.pastCompleted,
  };
};

/**
 * 工数欄に表示するデータを生成する
 * データが存在する場合のみ単位付きで表示(両方存在する場合はカンマを間に入れる)
 */
const createManHoursText = (maintItem: MaintItem): string => {
  const manText =
    maintItem.manInManHours !== null ? `${maintItem.manInManHours}人` : "";
  const hourText =
    maintItem.hourInManHours !== null ? `${maintItem.hourInManHours}時間` : "";

  return manText + (manText && hourText ? ", " : "") + hourText;
};

/**
 * 整備項目編集画面に遷移するためのRouteLocationRawオブジェクトを生成する (編集後のリダイレクト先を作成している)
 */
const goto_update_event = computed<RouteLocationRaw>(() => {
  // queryは値がないことも許容されているため生のRouteLocationRawを使うと毎回nullチェックが必要になる
  // そのため、pathとqueryを必須にした type LocationData を定義して使用している
  const location: LocationData = {
    path: `/edit-maint-item/${prop.maintItem.maintItemId}`,
    query: {},
  };

  const pathPart = route.path.split("/");

  // 各画面へリダイレクトできるようにQueryStringを設定する
  if (
    route.name === "maint-task-list" ||
    route.name === "maint-task-list-yyyymmdd"
  ) {
    location.query.referer = "maint-task-list";
    if (pathPart.length === 2 && pathPart[1] !== "") {
      location.query.yyyymmdd = pathPart[1];
    }
  } else if (route.name === "calendar" || route.name === "calendar-yyyymm") {
    location.query.referer = "calendar";
    if (pathPart.length === 3 && pathPart[2] !== "") {
      location.query.yyyymm = pathPart[2];
    }
  } else if (route.name === "operation-history") {
    location.query.referer = "operation-history";
  }

  return location;
});

/**
 * 過去完了対象整備作業を選択
 */
const openPastCompleteTargetMaintTaskDialog = () => {
  state.pastMaintTaskCompleteOpenStatus = true;
};
/**
 * 過去の整備作業を完了させるダイアログを閉じた後の処理
 */
const pastMaintTaskCompleteDialogStatusHandler = (
  status: boolean | undefined,
) => {
  if (status !== undefined) {
    state.pastMaintTaskCompleteOpenStatus = status;
  }
};

/**
 * 子ComponentからEmitされたSnackBarを親ComponentにさらにEmit
 */
const snackBarHandler = async (snackBar: AlertSnackBarModel | undefined) => {
  if (snackBar !== undefined) {
    emit("SnackBar", snackBar);
  }
};

/**
 * 過去の整備作業を完了させた結果を画面に反映
 */
const pastCompletionResultHandler = (
  pastCompletionResult: PastTaskCompletionResult | undefined,
) => {
  if (pastCompletionResult !== undefined) {
    if (pastCompletionResult.success) {
      state.scheduledDate = dayjs(pastCompletionResult.next_scheduled_date);
      state.showPastComplete =
        pastCompletionResult.next_past_completion_available;
      state.pastCompleted = true;
      state.currentMaintTaskId = pastCompletionResult.next_maint_task_id;

      // 最新状況を反映させるため、親Componentの一覧表示をリフレッシュさせる
      emit("NeedRefresh", true);
    }
  }
};
</script>

<style scoped>
.no-item {
  opacity: 0.6;
}

.updated-text {
  color: #007ea8; /* Vuetifyのprimaryの色 */
}

:deep(.parts-list.v-data-table table thead tr th) {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}

:deep(.parts-list.v-data-table table tbody tr td) {
  border-bottom: none;
}

:deep(.parts-list .v-data-table-rows-no-data) {
  color: rgba(0, 0, 0, 0.38);
}
</style>
