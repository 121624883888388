import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "@/auth";
import OperationHistory from "@/views/OperationHistory.vue";
import MaintTaskList from "@/views/MaintTaskList.vue";
import CalendarView from "@/views/CalendarView.vue";
import MaintItemView from "@/views/MaintItemView.vue";
import UpdateMaintItem from "@/views/UpdateMaintItem.vue";
import MaintEventView from "@/views/MaintEventView.vue";
import EventHistory from "@/views/EventHistory.vue";
import UpdateMaintEvent from "@/views/UpdateMaintEvent.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "maint-task-list",
    component: MaintTaskList,
  },
  {
    path: "/operation-history",
    name: "operation-history",
    component: OperationHistory,
  },
  {
    path: "/calendar",
    name: "calendar",
    component: CalendarView,
  },
  {
    path: "/calendar/:yyyymm",
    name: "calendar-yyyymm",
    component: CalendarView,
    props: true,
  },
  {
    path: "/edit-maint-item/",
    name: "maint-item",
    component: MaintItemView,
    props: true,
  },
  {
    path: "/edit-maint-item/:maintItemId",
    name: "maint-item-edit",
    component: UpdateMaintItem,
    props: true,
  },
  {
    path: "/edit-maint-event/",
    name: "maint-event",
    component: MaintEventView,
    props: true,
  },
  {
    path: "/edit-maint-event/:maintEventId",
    name: "maint-event-edit",
    component: UpdateMaintEvent,
    props: true,
  },
  {
    path: "/event-history",
    name: "event-history",
    component: EventHistory,
  },
  {
    path: "/:yyyymmdd",
    name: "maint-task-list-yyyymmdd",
    component: MaintTaskList,
    props: true,
  },
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
router.beforeEach(authGuard);

export default router;
